import Navbar from 'screens/Navbar';
import SessionExpired from 'shared/components/SessionExpired';
import Login from 'screens/Auth/Login';
import Register from 'screens/Auth/Register';
import Workers from 'screens/Admin/screens/Workers';
import WorkerTimes from 'screens/Admin/screens/WorkerTimes';
import WorkerTimesCreate from 'screens/Admin/screens/WorkerTimes/WorkerTimesCreate';
import WorkerTimesEdit from 'screens/Admin/screens/WorkerTimes/WorkerTimesEdit';
import WorkTimes from 'screens/User/screens/WorkTimes';
import Report from 'screens/Admin/screens/Report';
import SendMail from 'screens/Auth/SendMail';
import ChangePassword from 'screens/Auth/ChangePassword';
import Settings from 'screens/Admin/screens/Settings';
import WorkerProfile from 'screens/Admin/screens/Workers/Profile';
import CreateWorker from 'screens/Admin/screens/Workers/CreateWorker';
import EditWorker from 'screens/Admin/screens/Workers/EditWorker';
import UserChangePassword from 'screens/Settings/ChangePassword';
import Pdf from 'screens/Admin/screens/Pdf';

const routes = {
  navbar: {
    path: '/',
    component: Navbar,
    child_route: null
  },
  sessionExpired: {
    path: '/session-expired',
    component: SessionExpired,
    child_route: null
  },
  login: {
    path: '/login',
    component: Login,
    child_route: null
  },
  register: {
    path: '/register',
    component: Register,
    child_route: null
  },
  sendMail: {
    path: '/send-mail',
    component: SendMail,
    child_route: null
  },
  changePassword: {
    path: '/change-password',
    component: ChangePassword,
    child_route: null
  },
  workers: {
    path: '/workers',
    component: Workers,
    child_route: {
      report: {
        path: '/workers/report',
        component: Report
      },
      pdf: {
        path: '/workers/report/pdf',
        component: Pdf
      }
    }
  },
  workerTimes: {
    path: '/worker-times/:id',
    component: WorkerTimes,
    child_route: {
      add: {
        path: '/worker-times/:id/add',
        component: WorkerTimesCreate
      },
      edit: {
        path: '/worker-times/:id/edit/:workTimeId',
        component: WorkerTimesEdit
      }
    }
  },
  worker: {
    path: null,
    component: null,
    child_route: {
      add: {
        path: '/worker/add',
        component: CreateWorker
      },
      edit: {
        path: '/worker/:id/edit',
        component: EditWorker
      },
      profile: {
        path: '/worker/:id/profile',
        component: WorkerProfile
      }
    }
  },
  workTimes: {
    path: '/work-times',
    component: WorkTimes,
    child_route: null
  },
  settings: {
    path: '/settings',
    component: Settings,
    child_route: {
      changePassword: {
        path: '/settings/change-password',
        component: UserChangePassword
      }
    }
  }
};

export default routes;
