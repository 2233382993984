import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSendMailInfo } from 'redux/modules/account/selectors';
import {
  sendPasswordMailAction,
  sendPasswordMailRequestAction
} from 'redux/modules/account/actions';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';
import { tType, actionType, errorMessageType, historyType } from 'shared/types';

import Loading from 'shared/components/Loading';
import SendMailInput from './components/SendMailInput';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#5ca1b2',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2c5d68'
  },
  '&:active': {
    color: '#b22929'
  }
});

class SendMail extends PureComponent {
  componentDidMount() {
    this.props.sendPasswordMailRequestAction();
  }

  handleSubmit = async values => {
    await this.props.sendPasswordMail(values);
  };

  render() {
    const { t, info, isFetching, error, history } = this.props;
    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }
    if (info) {
      return (
        <div>
          <h4>{t(`general:${info}`)}</h4>
          <h4>
            <StyledLink to="/login">{t('mails:BackToLogin')}</StyledLink>
          </h4>
        </div>
      );
    }

    return (
      <SendMailInput
        onSubmit={this.handleSubmit}
        serverErrorMessage={error && error.message}
        history={history}
      />
    );
  }
}

SendMail.propTypes = {
  t: tType.isRequired,
  sendPasswordMail: actionType.isRequired,
  sendPasswordMailRequestAction: actionType.isRequired,
  info: PropTypes.string,
  isFetching: PropTypes.bool,
  error: errorMessageType,
  history: historyType.isRequired
};

SendMail.defaultProps = {
  info: null,
  isFetching: false,
  error: null
};

const loadingSelector = createLoadingSelector(['worktime/account/SEND_MAIL']);

const errorSelector = createErrorMessageSelector([
  'worktime/account/SEND_MAIL'
]);

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    info: getSendMailInfo(state)
  };
}

export default connect(
  mapStateToProps,
  {
    sendPasswordMail: sendPasswordMailAction,
    sendPasswordMailRequestAction
  }
)(withNamespaces()(SendMail));
