import React from 'react';

const Delete = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0 2a7 7 0 1 1 0-14 7 7 0 0 1 0 14zm1.414-7l2.29 2.29-1.414 1.415-2.292-2.293-2.293 2.293-1.415-1.414 2.294-2.293-2.291-2.29 1.414-1.415L12 10.586l2.293-2.293 1.414 1.414L13.414 12z"
      fill="#434345"
      fillRule="evenodd"
    />
  </svg>
);

export default Delete;
