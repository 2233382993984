import React from 'react';

const User = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M12 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm-6 9H4a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0z"
      fill="#434345"
      fillRule="evenodd"
    />
  </svg>
);

export default User;
