import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import { withNamespaces } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { currency, calcTypes } from 'utils/dictionaries';
import { disableField } from 'redux/modules/worktime/selectors';

import Loading from 'shared/components/Loading';
import {
  MainButton,
  FormTitle,
  SecondaryButton,
  ButtonGroup
} from 'shared/styles';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import {
  requiredField,
  positiveNumber,
  maxLength150,
  maxValue999
} from 'utils/fields/validation';
import { tType } from 'shared/types';
import Select from 'shared/Fields/Select';
import Input from 'shared/Fields/Input';
import Checkbox from 'shared/Fields/Checkbox';
import NumberInput from 'shared/Fields/NumberInput';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '-47px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '9px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '-138px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  bottom: '0',
  left: 'calc(50% - 300px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  width: '400px',
  margin: '75px auto 0 auto',
  alignItems: 'center',
  padding: '5px 10px',
  boxSizing: 'border-box',
  position: 'relative'
});

const FormContent = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: '2'
});

const WorkerInput = ({
  handleSubmit,
  years,
  months,
  serverErrorMessage,
  t,
  submitting,
  redirect,
  title,
  disabledField,
  salary,
  calcType,
  timeValue,
  salaryPerHour,
  change,
  blur
}) => {
  const calculate = () => {
    switch (calcType) {
      case 1:
        if (timeValue * salaryPerHour) {
          change('salary', timeValue * salaryPerHour);
          blur('salary', parseFloat(timeValue * salaryPerHour).toFixed(2));
        } else {
          change('salary', null);
        }
        break;
      case 2:
        if (salary / timeValue) {
          change('salaryPerHour', salary / timeValue);
          blur('salaryPerHour', parseFloat(salary / timeValue).toFixed(2));
        } else {
          change('salaryPerHour', null);
        }
        break;
      case 3:
        if (salary / salaryPerHour) {
          change('timeValue', salary / salaryPerHour);
          blur('timeValue', parseFloat(salary / salaryPerHour).toFixed(2));
        } else {
          change('timeValue', null);
        }
        break;
      default:
    }
  };

  if (submitting) {
    return <Loading text={t('general:PleaseWait')} />;
  }
  return (
    <StyledForm onSubmit={handleSubmit} noValidate>
      <FormContent style={{ zIndex: '3' }}>
        <FormTitle>{title}</FormTitle>
        <Field
          id="year"
          name="year"
          component={Select}
          validate={[requiredField]}
          type="text"
          placeholder={t('workTimes:Year')}
          label={t('workTimes:Year')}
          options={years}
        />
        <Field
          id="month"
          name="month"
          component={Select}
          validate={[requiredField]}
          type="text"
          placeholder={t('workTimes:Month')}
          label={t('workTimes:Month')}
          options={months}
          style={{ marginTop: '15px' }}
        />
        <Field
          id="projectName"
          name="projectName"
          component={Input}
          label={t('workTimes:ProjectName')}
          type="text"
          validate={[maxLength150]}
          style={{ marginTop: '15px' }}
        />
        <Field
          id="isPpk"
          name="isPpk"
          component={Checkbox}
          label={t('workTimes:Ppk')}
          style={{ marginTop: '15px' }}
        />

        <Field
          id="calcType"
          name="calcType"
          component={Select}
          validate={[requiredField]}
          onChange={calculate}
          placeholder={t('workTimes:CalcType')}
          label={t('workTimes:CalcType')}
          options={calcTypes}
          style={{ marginTop: '15px' }}
        />

        <Field
          name="timeValue"
          onChange={calculate}
          component={NumberInput}
          disabled={disabledField === 'timeValue'}
          precision={2}
          step={0.1}
          label={t('workTimes:Hours')}
          validate={[requiredField, positiveNumber, maxValue999]}
          style={{ marginTop: '15px' }}
        />

        <Field
          id="salaryPerHour"
          name="salaryPerHour"
          onChange={calculate}
          component={NumberInput}
          disabled={disabledField === 'salaryPerHour'}
          precision={2}
          step={0.1}
          validate={[requiredField, positiveNumber]}
          label={t('workTimes:HourlyRate')}
          type="number"
          style={{ marginTop: '15px' }}
        />
        <Field
          id="salary"
          name="salary"
          onChange={calculate}
          disabled={disabledField === 'salary'}
          component={NumberInput}
          precision={2}
          step={0.1}
          validate={[requiredField, positiveNumber]}
          label={t('workTimes:Salary')}
          type="number"
          style={{ marginTop: '15px' }}
        />
        <Field
          id="idCurrency"
          name="idCurrency"
          component={Select}
          validate={[requiredField]}
          type="text"
          placeholder={t('workTimes:Currency')}
          label={t('workTimes:Currency')}
          options={currency}
          style={{ marginTop: '15px' }}
        />
        <ErrorMessageForm error={serverErrorMessage} />
      </FormContent>
      <FormContent style={{ marginTop: '10px' }}>
        <React.Fragment>
          <LineHorizontalTop>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalTop>
          <LineVerticalTop>
            <LineDotVertical count={20} />
          </LineVerticalTop>
          <LineHorizontalBottom>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalBottom>
          <ButtonGroup>
            <SecondaryButton
              type="button"
              style={{ marginRight: '14px' }}
              onClick={redirect}
            >
              {t('general:Cancel')}
            </SecondaryButton>
            <MainButton type="submit">{t('general:Save')}</MainButton>
          </ButtonGroup>
        </React.Fragment>
      </FormContent>
      <BackgroundSquare />
    </StyledForm>
  );
};

WorkerInput.propTypes = {
  workTime: PropTypes.shape({}),
  serverErrorMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  t: tType.isRequired,
  submitting: PropTypes.bool.isRequired,
  years: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  months: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabledField: PropTypes.string.isRequired,
  salary: PropTypes.number,
  calcType: PropTypes.number,
  timeValue: PropTypes.number,
  salaryPerHour: PropTypes.number,
  change: PropTypes.func.isRequired,
  blur: PropTypes.func.isRequired
};

WorkerInput.defaultProps = {
  serverErrorMessage: null,
  workTime: {},
  salary: null,
  calcType: 1,
  timeValue: null,
  salaryPerHour: null
};

const selector = formValueSelector('workTimeForm');

function mapStateToProps(state) {
  return {
    disabledField: disableField(selector(state, 'calcType')),
    salary: selector(state, 'salary'),
    salaryPerHour: selector(state, 'salaryPerHour'),
    timeValue: selector(state, 'timeValue'),
    calcType: selector(state, 'calcType')
  };
}

export default withNamespaces()(
  connect(mapStateToProps)(reduxForm({ form: 'workTimeForm' })(WorkerInput))
);
