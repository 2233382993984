import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from 'shared/components/TableShort';
import {
  MainButton,
  H1,
  SecondaryButton,
  ButtonGroup,
  DeleteButton,
  InlineDataLabel,
  InlineDataValue
} from 'shared/styles';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';
import Loading from 'shared/components/Loading';
import Error from 'shared/components/Error';
import ConfirmPopup from 'shared/components/ConfirmPopup';
import { toLocalDateTime } from 'utils/helpers';

import {
  emailHistoryWorkerAction,
  emailHistoryWorkerRequestAction,
  getWorkerRequestAction,
  getWorkerAction,
  blockWorkerAction,
  deleteWorkerAction,
  unblockWorkerAction,
  resendEmailAction
} from 'redux/modules/worker/actions';
import { addToastAction } from 'redux/modules/toast/actions';
import {
  getWorker,
  getWorkerEmailHistory
} from 'redux/modules/worker/selectors';
import {
  actionType,
  errorMessageType,
  workerType,
  matchType,
  historyType
} from 'shared/types';
import { currency } from 'utils/dictionaries';
import Lock from 'assets/Icon/icons/Lock';
import Unlock from 'assets/Icon/icons/Unlock';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal } from 'shared/components/LineDots';

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  top: '400px',
  left: 'calc(50% - 200px)',
  zIndex: '-1'
});

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '250px',
  left: '35px',
  '& svg circle': {
    fill: ({ theme }) => theme.paleSkyBlue
  }
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const IconLabel = styled('span')({
  display: 'block',
  fontSize: '16px',
  fontWeight: '300',
  color: ({ theme }) => theme.charcoalGrey
});

const LockIcon = styled(Lock)({
  margin: '0 12px',
  cursor: 'pointer',
  height: '28px',
  width: '28px',
  boxSizing: 'border-box',
  border: '2px solid',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  background: ({ theme }) => theme.white,
  '&:hover:not(:active)': {
    background: ({ theme }) => theme.paleSkyBlue
  },
  '&:active': {
    borderColor: ({ theme }) => theme.textDefault,
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  },
  '&:focus': {
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  }
});

const UnlockIcon = styled(Unlock)({
  marginLeft: '12px',
  cursor: 'pointer',
  height: '28px',
  width: '28px',
  boxSizing: 'border-box',
  border: '2px solid',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  background: ({ theme }) => theme.white,
  '&:hover:not(:active)': {
    background: ({ theme }) => theme.paleSkyBlue
  },
  '&:active': {
    borderColor: ({ theme }) => theme.textDefault,
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  },
  '&:focus': {
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  }
});

const Container = styled('div')({
  padding: '0 30px',
  width: '1420px',
  boxSizing: 'border-box',
  margin: '0 auto'
});

const StyledH1 = styled(H1)({
  margin: '0'
});

const EditButton = styled(MainButton)({
  position: 'absolute',
  right: '150px',
  top: '-60px',
  height: '30px',
  fontSize: '16px',
  padding: '0 10px'
});

const HeaderContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '34px 0 65px 0'
});

const Board = styled('div')({
  justifyContent: 'space-between',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '20px',
  position: 'relative',
  zIndex: '1',
  backgroundColor: 'white'
});

const BoardHeader = styled('span')({
  display: 'block',
  textAlign: 'left',
  fontSize: '22px',
  fontWeight: '500',
  margin: '27px 0 30px 0',
  paddingLeft: '95px'
});

const EmployeeInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: ({ theme }) => theme.white,
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)'
});

const EmployeeInfoInnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',
  borderRadius: '5px',
  height: '397px',
  marginBottom: '30px'
});

const EmployeeData = styled('div')({
  margin: '23px 0 15px 30px',
  display: 'grid',
  gridTemplateColumns: '145px 1fr',
  gridAutoRows: '22px',
  gridColumnGap: '22px',
  gridRowGap: '16px'
});

const SummaryContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '60% 40%',
  margin: '0 5px',
  padding: '35px 0 0 0',
  boxSizing: 'border-box'
});

const Summary = styled('div')({
  display: 'grid',
  gridTemplateColumns: '145px 120px',
  boxSizing: 'border-box',
  marginLeft: '30px',
  gridColumnGap: '22px',
  gridRowGap: '10px'
});

const SummaryLabel = styled('span')({
  display: 'inline-block',
  fontSize: '16px',
  fontWeight: '300',
  textAlign: 'right'
});

const SummaryValue = styled('span')({
  fontSize: '16px',
  gridColumn: '2 / 2'
});

const AccountStatusContainer = styled('div')({
  display: 'flex',
  fontWeight: '300',
  justifyContent: 'flex-end',
  marginRight: '15px'
});

const AccountStatusLabel = styled('label')({
  fontSize: '16px',
  marginRight: '7px'
});

const AccountStatusActive = styled('span')({
  fontSize: '16px',
  color: ({ theme }) => theme.greenyBlue
});

const AccountStatusInActive = styled('span')({
  fontSize: '16px',
  color: ({ theme }) => theme.pastelRed
});

const TopRow = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
});

const MailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: ({ theme }) => theme.white,
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)'
});

const ReactivationLink = styled('button')({
  border: '0',
  width: '100%',
  background: 'transparent',
  color: ({ theme }) => theme.greenyBlue,
  textAlign: 'left',
  fontSize: '14px',
  padding: '0',
  textDecoration: 'underline',
  marginTop: '6px'
});

export class WorkerProfile extends Component {
  state = {
    deletePopupIsOpen: false,
    blockPopupIsOpen: false,
    unblockPopupIsOpen: false
  };

  async componentDidMount() {
    await this.props.getWorkerRequestAction();
    await this.props.emailHistoryWorkerRequestAction();
    await this.props.getWorkerAct(this.props.match.params.id);
    await this.props.emailHistoryWorkerAction(this.props.match.params.id);
  }

  onClickOpenDeletePopup = () => {
    this.setState({ deletePopupIsOpen: true });
  };

  onClickOpenBlockPopup = () => {
    this.setState({ blockPopupIsOpen: true });
  };

  onClickOpenUnblockPopup = () => {
    this.setState({ unblockPopupIsOpen: true });
  };

  closePopups = () => {
    this.setState({
      deletePopupIsOpen: false,
      blockPopupIsOpen: false,
      unblockPopupIsOpen: false
    });
  };

  closePopupAndRefresh = async () => {
    this.closePopups();
    await this.props.getWorkerAct(this.props.match.params.id);
  };

  deletePerson = async () => {
    await this.props.deleteWorker(this.props.worker.idPerson);
    if (!this.props.errorPopup) {
      this.props.history.push('/workers');
    }
  };

  blockPerson = async () => {
    await this.props.blockWorker(this.props.worker.idPerson);
    if (!this.props.errorPopup) {
      await this.closePopupAndRefresh();
    }
  };

  resendActLink = async () => {
    await this.props.resendEmail(this.props.worker.idPerson);
    await this.props.emailHistoryWorkerAction(this.props.match.params.id);
    if (!this.props.error) {
      this.props.addToastAction('ActivationLinkSent');
    }
  };

  unblockPerson = async () => {
    await this.props.unblockWorker(this.props.worker.idPerson);
    if (!this.props.errorPopup) {
      await this.closePopupAndRefresh();
    }
  };

  render() {
    const { emailHistory, t, isFetching, error, worker } = this.props;
    const {
      deletePopupIsOpen,
      blockPopupIsOpen,
      unblockPopupIsOpen
    } = this.state;

    const currencyObj = currency.find(
      ({ value }) => value === worker.idCurrency
    );

    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error error={error} />;
    }

    const columns = [
      {
        Header: this.props.t('worker:SentBy'),
        Cell: ({
          original: {
            sender: { firstName, lastName }
          }
        }) => `${firstName} ${lastName}`
      },
      {
        Header: this.props.t('worker:SentAt'),
        accessor: 'sendDate',
        Cell: ({ value }) => toLocalDateTime(value)
      },
      {
        Header: this.props.t('worker:EmailType'),
        accessor: 'emailType',
        Cell: ({ value }) => `${t(`worker:${value}`)}`
      },
      {
        Header: this.props.t('worker:EmailStatus'),
        accessor: 'emailStatus',
        Cell: ({ value }) => value && this.props.t(`worker:${value}`)
      }
    ];
    return (
      <Container>
        <HeaderContainer>
          <SecondaryButton
            style={{ marginLeft: '15px' }}
            onClick={() => this.props.history.push('/workers')}
          >
            {t('general:Back')}
          </SecondaryButton>
          <StyledH1>{`${t('worker:Employee')} ${worker.firstName} ${
            this.props.worker.lastName
          }`}</StyledH1>
          <ButtonGroup style={{ margin: '0' }}>
            {worker.personStatus === 'Aktywny' && (
              <IconContainer>
                <IconLabel>{this.props.t('workerActions:Block')}:</IconLabel>
                <LockIcon
                  onClick={this.onClickOpenBlockPopup}
                  style={{ marginRight: '8px' }}
                />
              </IconContainer>
            )}
            {worker.personStatus === 'Zwolniony' && (
              <IconContainer>
                <IconLabel>{this.props.t('workerActions:Unblock')}:</IconLabel>
                <UnlockIcon
                  style={{ marginRight: '8px' }}
                  onClick={this.onClickOpenUnblockPopup}
                />
              </IconContainer>
            )}
            <DeleteButton
              style={{ marginRight: '15px' }}
              disabled={!worker.canDelete}
              onClick={this.onClickOpenDeletePopup}
            >
              {t('general:Delete')}
            </DeleteButton>
          </ButtonGroup>
        </HeaderContainer>

        <Board>
          <BackgroundSquare />
          <EmployeeInfoContainer>
            <BoardHeader>{t('worker:EmployeeInfo')}</BoardHeader>
            <EmployeeInfoInnerContainer>
              <EditButton
                onClick={() =>
                  this.props.history.push(
                    `/worker/${this.props.match.params.id}/edit`
                  )
                }
              >
                {t('workerActions:Edit')}
              </EditButton>

              <EditButton
                style={{
                  right: '30px'
                }}
                onClick={() =>
                  this.props.history.push(
                    `/worker-times/${this.props.match.params.id}`
                  )
                }
              >
                {t('worker:WorkTimes')}
              </EditButton>

              <EmployeeData>
                <InlineDataLabel>{`${t('worker:FirstName')}:`}</InlineDataLabel>
                <InlineDataValue>{worker.firstName}</InlineDataValue>

                <InlineDataLabel>{`${t('worker:LastName')}:`}</InlineDataLabel>
                <InlineDataValue>{worker.lastName}</InlineDataValue>

                <InlineDataLabel>{`${t('worker:Email')}:`}</InlineDataLabel>
                <InlineDataValue> {worker.email}</InlineDataValue>

                <InlineDataLabel>{`${t('worker:Phone')}:`}</InlineDataLabel>
                <InlineDataValue>{worker.phone}</InlineDataValue>

                <InlineDataLabel>{`${t(
                  'worker:HourlyRate'
                )}:`}</InlineDataLabel>
                <InlineDataValue>{worker.baseSalaryPerHour}</InlineDataValue>

                <InlineDataLabel>{`${t('worker:Currency')}:`}</InlineDataLabel>
                <InlineDataValue>
                  {currencyObj && currencyObj.label}
                </InlineDataValue>
              </EmployeeData>

              <SummaryContainer>
                <LineHorizontalTop>
                  <LineDotHorizontal count={42} width={620} />
                </LineHorizontalTop>
                <Summary>
                  <SummaryLabel>{`${t('worker:SummHours')}:`}</SummaryLabel>
                  <SummaryValue>{worker.sumHours}</SummaryValue>
                  <SummaryLabel>{`${t('worker:TotalIncome')}:`} </SummaryLabel>
                  <SummaryValue>{worker.sumPLNSalary} PLN</SummaryValue>
                  <SummaryValue>{worker.sumEURSalary} EUR</SummaryValue>
                  <SummaryValue>{worker.sumUSDSalary} USD</SummaryValue>
                  <SummaryValue>{worker.sumCADSalary} CAD</SummaryValue>
                </Summary>
                <AccountStatusContainer>
                  {worker.canResendRegistrationEmail ? (
                    <div>
                      <TopRow>
                        <AccountStatusLabel>{`${t(
                          'worker:AccountStatus'
                        )}:`}</AccountStatusLabel>
                        <AccountStatusInActive>{`${t(
                          'worker:NeedAct'
                        )}`}</AccountStatusInActive>
                      </TopRow>
                      <ReactivationLink
                        type="button"
                        onClick={this.resendActLink}
                      >
                        {`${t('worker:ResendActLink')}`}
                      </ReactivationLink>
                    </div>
                  ) : (
                    <div>
                      <AccountStatusLabel>{`${t(
                        'worker:AccountStatus'
                      )}:`}</AccountStatusLabel>
                      <AccountStatusActive>{`${t(
                        'worker:Active'
                      )}`}</AccountStatusActive>
                    </div>
                  )}
                </AccountStatusContainer>
              </SummaryContainer>
            </EmployeeInfoInnerContainer>
          </EmployeeInfoContainer>
          <MailContainer>
            <BoardHeader>{t('worker:EmailHistory')}</BoardHeader>
            <Table
              showPagination
              data={emailHistory}
              columns={columns}
              defaultPageSize={10}
              getTdProps={this.getTdProps}
            />
          </MailContainer>
        </Board>
        <ConfirmPopup
          isAttentionPopup
          text={`${t('workerActions:DeleteEmployee')} ${worker.email}?`}
          onClose={this.closePopups}
          onSubmit={this.deletePerson}
          cancelText={t('general:Cancel')}
          isOpen={deletePopupIsOpen}
          submitText={t('workerActions:Delete')}
          loading={this.props.isFetchingPopup}
        />
        <ConfirmPopup
          isAttentionPopup
          text={`${t('workerActions:BlockEmployee')} ${worker.email}?`}
          onClose={this.closePopups}
          onSubmit={this.blockPerson}
          cancelText={t('general:Cancel')}
          isOpen={blockPopupIsOpen}
          submitText={t('workerActions:Block')}
          loading={this.props.isFetchingPopup}
        />
        <ConfirmPopup
          isAttentionPopup
          text={`${t('workerActions:UnblockEmployee')} ${worker.email}?`}
          onClose={this.closePopups}
          onSubmit={this.unblockPerson}
          cancelText={t('general:Cancel')}
          isOpen={unblockPopupIsOpen}
          submitText={t('workerActions:Unblock')}
          loading={this.props.isFetchingPopup}
        />
      </Container>
    );
  }
}

WorkerProfile.propTypes = {
  emailHistory: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getWorkerAct: actionType.isRequired,
  addToastAction: actionType.isRequired,
  emailHistoryWorkerAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  emailHistoryWorkerRequestAction: PropTypes.func.isRequired,
  getWorkerRequestAction: PropTypes.func.isRequired,
  blockWorker: PropTypes.func.isRequired,
  unblockWorker: PropTypes.func.isRequired,
  deleteWorker: PropTypes.func.isRequired,
  resendEmail: PropTypes.func.isRequired,
  match: matchType.isRequired,
  error: errorMessageType,
  errorPopup: errorMessageType,
  history: historyType.isRequired,
  worker: workerType.isRequired,
  isFetching: PropTypes.bool,
  isFetchingPopup: PropTypes.bool
};

WorkerProfile.defaultProps = {
  isFetching: true,
  isFetchingPopup: true,
  error: null,
  errorPopup: null
};

const loadingSelector = createLoadingSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worker/EMAIL_HISTORY_WORKER',
  'worktime/worker/RESEND_EMAIL'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worker/EMAIL_HISTORY_WORKER',
  'worktime/worker/RESEND_EMAIL'
]);

const loadingPopupSelector = createLoadingSelector([
  'worktime/worker/BLOCK_WORKER',
  'worktime/worker/UNBLOCK_WORKER',
  'worktime/worker/DELETE_WORKER'
]);

const errorPopupSelector = createErrorMessageSelector([
  'worktime/worker/BLOCK_WORKER',
  'worktime/worker/UNBLOCK_WORKER',
  'worktime/worker/DELETE_WORKER'
]);

function mapStateToProps(state) {
  return {
    emailHistory: getWorkerEmailHistory(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    worker: getWorker(state),
    isFetchingPopup: loadingPopupSelector(state),
    errorPopup: errorPopupSelector(state)
  };
}

export default connect(
  mapStateToProps,
  {
    emailHistoryWorkerAction,
    getWorkerAct: getWorkerAction,
    emailHistoryWorkerRequestAction,
    blockWorker: blockWorkerAction,
    deleteWorker: deleteWorkerAction,
    unblockWorker: unblockWorkerAction,
    resendEmail: resendEmailAction,
    getWorkerRequestAction,
    addToastAction
  }
)(withNamespaces()(WorkerProfile));
