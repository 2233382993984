import { createDefaultApiAction } from 'redux/helpers';
import storage from 'redux/utis/storageFactory';
import * as endpoints from 'utils/api/settings';

import * as types from './types';

export const getSettingsAction = userId =>
  createDefaultApiAction(
    types.GET_SETTINGS,
    endpoints.getSettings,
    userId,
    data => {
      const { numberOfRows, monthWorkTime } = data;
      storage.setItem('pageSize', numberOfRows);
      storage.setItem('monthWorkTime', monthWorkTime);
      return data;
    }
  );

export const setSettingsAction = values =>
  createDefaultApiAction(
    types.SET_SETTINGS,
    endpoints.setSettings,
    values,
    data => {
      storage.setItem('pageSize', values.numberOfRows);
      return data;
    }
  );

export const changeLanguageAction = values =>
  createDefaultApiAction(
    types.CHANGE_LANGUAGE,
    endpoints.changeLanguage,
    values,
    () => {
      storage.setItem('language', values.idLanguageDict);
      return values.idLanguageDict;
    }
  );

export const changePasswordAction = () =>
  createDefaultApiAction(types.CHANGE_PASSWORD, endpoints.changePassword, null);

export const changePasswordRequestAction = () => dispatch =>
  dispatch({ type: types.CHANGE_PASSWORD.SUCCESS });
