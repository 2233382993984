import { requestActions, createRequestTypes } from 'redux/helpers';

export const CHANGE_PASSWORD = createRequestTypes(
  'worktime/account',
  'CHANGE_PASSWORD',
  requestActions
);

export const SET_PASSWORD = createRequestTypes(
  'worktime/account',
  'SET_PASSWORD',
  requestActions
);

export const GET_PERSON = createRequestTypes(
  'worktime/account',
  'GET_PERSON',
  requestActions
);

export const SEND_MAIL = createRequestTypes(
  'worktime/account',
  'SEND_MAIL',
  requestActions
);

export const CHECK_PASSWORD_GUID = createRequestTypes(
  'worktime/account',
  'CHECK_PASSWORD_GUID',
  requestActions
);
