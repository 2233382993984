import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import Input from 'shared/Fields/Input';
import {
  MainButton,
  ButtonGroup,
  CenteredContainerVertical,
  FormTitle
} from 'shared/styles';
import { requiredField, passwordRules } from 'utils/fields/validation';
import { errorMessageType, tType } from 'shared/types';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '263px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '319px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '270px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  top: '245px',
  left: 'calc(50% - 320px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  margin: '90px auto 0 auto',
  boxSizing: 'border-box',
  width: '430px',
  ':not(:last-child)': {
    marginBottom: '4rem'
  }
});

const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '40px 10px',
  boxSizing: 'border-box',
  position: 'relative'
});

const FormTitleStyled = styled(FormTitle)({
  marginTop: '50px'
});

const passwordValidation = values => {
  const errors = {};
  if (values.password !== values.confirmPassword) {
    errors.password = 'validation:DiffPasswords';
    errors.confirmPassword = 'validation:DiffPasswords';
  }
  return errors;
};

const ChangePasswordInput = ({ handleSubmit, t, email, error }) => (
  <CenteredContainerVertical>
    <BackgroundSquare />
    <FormTitleStyled>
      {email && t('register:ChangePasswordFor')} {email && email}
    </FormTitleStyled>
    <StyledForm onSubmit={handleSubmit}>
      <FormContent style={{ height: '295px' }}>
        <Field
          autoFocus
          id="password"
          component={Input}
          name="password"
          validate={[requiredField, passwordRules]}
          type="password"
          placeholder={t('passwordRecovery:Password')}
          label={t('passwordRecovery:Password')}
          t={t}
        />
        <Field
          showErrorMsg
          styleLabel={{ marginTop: '10px' }}
          id="confirmPassword"
          component={Input}
          name="confirmPassword"
          validate={[requiredField]}
          type="password"
          placeholder={t('passwordRecovery:RepeatPassword')}
          label={t('passwordRecovery:RepeatPassword')}
          t={t}
        />
        <LineHorizontalTop>
          <LineDotHorizontal count={4} width={75} />
        </LineHorizontalTop>
        <LineVerticalTop>
          <LineDotVertical count={5} />
        </LineVerticalTop>
        <LineHorizontalBottom>
          <LineDotHorizontal count={4} width={75} />
        </LineHorizontalBottom>
      </FormContent>
      <FormContent style={{ margin: '9px 0' }}>
        <ButtonGroup>
          <MainButton type="submit">{t('general:Save')}</MainButton>
        </ButtonGroup>
      </FormContent>
      <ErrorMessageForm error={error} />
    </StyledForm>
  </CenteredContainerVertical>
);

ChangePasswordInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired,
  error: errorMessageType,
  email: PropTypes.string.isRequired
};

ChangePasswordInput.defaultProps = {
  error: null
};

export default reduxForm({
  form: 'changePasswordForm',
  validate: passwordValidation
})(withNamespaces()(ChangePasswordInput));
