import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';

import { metaType, tType } from 'shared/types';
import { BaseLabel, BaseHtmlInput, InputOutline } from 'shared/styles';
import { withNamespaces } from 'react-i18next';
import FieldError from './FieldError';

const Container = styled('div')({});

const Label = styled(BaseLabel)(({ theme }) => ({
  color: theme.charcoalGrey
}));

/* const HtmlInput = styled(BaseHtmlInput)(({ theme, isInvalid, touched }) => ({
  '&:focus': {
    borderBottomColor: 'red !important'
  },
  borderBottom:
    isInvalid && touched // eslint-disable-line no-nested-ternary
      ? theme.borderInputInvalid
      : !touched
      ? theme.borderInputDefault
      : theme.borderInputValid,

}));
*/

const Input = ({
  t,
  label,
  type,
  placeholder,
  disabled,
  meta,
  id,
  showErrorMsg,
  styleInput,
  styleLabel,
  style,
  ...props
}) => (
  <Container style={style}>
    <Label style={styleLabel} isNotEmpty={props.input.value.length > 0}>
      {label}
    </Label>
    <InputOutline>
      <BaseHtmlInput
        isInvalid={meta.error}
        touched={meta.touched}
        id={id}
        {...props.input}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        style={styleInput}
        {...props}
      />
    </InputOutline>
    <FieldError meta={meta} />
  </Container>
);

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: metaType.isRequired,
  t: tType.isRequired,
  showErrorMsg: PropTypes.bool,
  styleInput: PropTypes.shape({}),
  styleLabel: PropTypes.shape({}),
  style: PropTypes.shape({})
};

Input.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  showErrorMsg: false,
  styleInput: null,
  styleLabel: null,
  style: null
};

export default withNamespaces()(Input);
