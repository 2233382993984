import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { metaType } from 'shared/types';
import styled from 'styled-jss';
import { SelectContainer } from 'shared/styles';
import SimpleSelect from 'shared/components/SimpleSelect';
import FieldError from './FieldError';

const StyledSelectContainer = styled(SelectContainer)({
  width: 'auto',
  maxWidth: '500px',
  marginRight: '20px',
  '& .select__control': {
    height: '28px',
    minHeight: '28px',
    border: '1px solid',
    borderColor: ({ theme }) => theme.select.simpleSelect.borderColor,
    color: ({ theme }) => theme.select.simpleSelect.color,
    outline: '0 !important',
    boxShadow: 'none',
    minWidth: '200px',
    width: '100%',
    maxWidth: '400px'
  }
});

const InnerSelectContainer = styled('div')({
  // display: 'table',
  // width: '100%',
  display: 'inline-block',
  maxWidth: '400px',
  minWidth: '200px',
  fontFamily: 'inherit',
  backgroundColor: 'rgba(#fff, .5)'
});

const BaseLabel = styled('div')({
  textAlign: 'left',
  fontWeight: '300'
});

const Label = styled(BaseLabel)(({ theme }) => ({
  color: theme.textDefault,
  fontSize: '16px',
  display: 'inline',
  fontWeight: '300',
  marginRight: '10px'
}));

const Select = ({
  t,
  input,
  options,
  label,
  meta,
  onChangeSelect,
  defaultOption,
  placeholder,
  display,
  style,
  ...props
}) => (
  /*   const allOptions =
    defaultOption && !options.find(({ value }) => !value)
      ? [{ label: defaultOption, value: 0 }, ...options]
      : options; */
  <div>
    <StyledSelectContainer display={display} style={style}>
      <Label isNotEmpty>{label && label}</Label>
      <InnerSelectContainer>
        <SimpleSelect
          value={options.find(elem => elem.value === input.value) || 0}
          onChange={newValue => {
            input.onChange(newValue.value);
            onChangeSelect(newValue.value);
          }}
          onBlur={() => input.onBlur(input.value)}
          tabSelectsValue={false}
          options={options}
          isMulti={false}
          placeholder={placeholder}
          t={t}
          {...props}
        />
      </InnerSelectContainer>
    </StyledSelectContainer>
    <FieldError meta={meta} style={{ textAlign: 'center' }} />
  </div>
);

Select.propTypes = {
  input: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string,
  meta: metaType.isRequired,
  onChangeSelect: PropTypes.func,
  defaultOption: PropTypes.string,
  placeholder: PropTypes.string,
  display: PropTypes.string,
  style: PropTypes.shape({}),
  t: PropTypes.func.isRequired
};

Select.defaultProps = {
  label: null,
  style: null,
  onChangeSelect: () => {},
  defaultOption: null,
  placeholder: null,
  display: ''
};

export default translate('translations')(Select);
