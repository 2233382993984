import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';

import { BaseLabel, BaseHtmlInput } from 'shared/styles';
import { withNamespaces } from 'react-i18next';
// import DownIcon from 'assets/Icon/icons/IconSTriangleFilledDown';
// import UpIcon from 'assets/Icon/icons/IconSTriangleFilledUp';
import FieldError from './FieldError';

const Container = styled('div')({});

const Label = styled(BaseLabel)({});

const InputContainer = styled('div')({
  display: 'flex'
});

/*
const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '-20px',
  position: 'relative',
  bottom: '10px'
});

const ValueButtonUp = styled('button')({
  border: 'none',
  background: 'none'
});

const ValueButtonDown = styled('button')({
  border: 'none',
  background: 'none'
});
*/

class NumberInput extends React.Component {
  timer = null;

  onChange = ({ target: { value } }) => {
    this.props.input.onChange(value.replace(/[^\d.]/g, ''));
  };

  onBlur = ({ target: { value } }) => {
    if (parseFloat(value)) {
      this.props.input.onChange(
        parseFloat(value).toFixed(this.props.precision)
      );
      this.props.input.onBlur(parseFloat(value).toFixed(this.props.precision));
      parseFloat(value).toFixed(this.props.precision);
    } else {
      this.props.input.onBlur(undefined);
    }
  };

  increment = () => {
    this.props.input.value = parseFloat(
      this.props.input.value * 1 + this.props.step
    ).toFixed(this.props.precision);
    this.props.input.onChange(this.props.input.value.toString());
    this.timer = setTimeout(this.increment, 100);
  };

  decrement = () => {
    this.props.input.value = parseFloat(
      this.props.input.value * 1 - this.props.step
    ).toFixed(this.props.precision);
    this.props.input.onChange(this.props.input.value.toString());
    this.timer = setTimeout(this.decrement, 100);
  };

  stopTimer() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      label,
      meta,
      step,
      placeholder,
      input,
      style,
      disabled,
      precision
    } = this.props;
    return (
      <Container style={style}>
        <Label style={{ color: disabled ? '#d0d0d0' : 'rgba(0, 0, 0, 0.67)' }}>
          {label}
          {disabled}
        </Label>
        <InputContainer>
          <BaseHtmlInput
            isInvalid={meta.error}
            precision={precision}
            disabled={disabled}
            touched={meta.touched}
            step={step}
            {...input}
            onBlur={this.onBlur}
            onChange={this.onChange}
            placeholder={placeholder}
          />
          {/* <ButtonContainer>
            <ValueButtonUp
              type="button"
              onMouseDown={() => {
                this.increment();
              }}
              onMouseUp={() => {
                this.stopTimer();
              }}
              onMouseLeave={() => {
                this.stopTimer();
              }}
            >
              <UpIcon />
            </ValueButtonUp>
            <ValueButtonDown
              type="button"
              onMouseDown={() => {
                this.decrement();
              }}
              onMouseUp={() => {
                this.stopTimer();
              }}
              onMouseLeave={() => {
                this.stopTimer();
              }}
            >
              <DownIcon />
            </ValueButtonDown>
            </ButtonContainer> */}
        </InputContainer>
        <FieldError meta={meta} />
      </Container>
    );
  }
}

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  precision: PropTypes.number,
  step: PropTypes.number,
  meta: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({})
};

NumberInput.defaultProps = {
  style: null,
  disabled: false,
  label: null,
  precision: 0,
  step: 0.1,
  placeholder: null
};
export default withNamespaces()(NumberInput);
