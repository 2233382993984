import { requestActions, createRequestTypes } from 'redux/helpers';

export const UPDATE_WORKTIME = createRequestTypes(
  'worktime/worktime',
  'UPDATE_WORKTIME',
  requestActions
);

export const GET_WORKER_TIMES_USER = createRequestTypes(
  'worktime/worktime',
  'GET_WORKER_TIMES_USER',
  requestActions
);

export const GET_WORKER_TIMES_ADMIN = createRequestTypes(
  'worktime/worktime',
  'GET_WORKER_TIMES_ADMIN',
  requestActions
);

export const DELETE_WORKTIME = createRequestTypes(
  'worktime/worktime',
  'DELETE_WORKTIME',
  requestActions
);

export const GET_WORKTIME = createRequestTypes(
  'worktime/worktime',
  'GET_WORKTIME',
  requestActions
);

export const ADD_WORKTIME = createRequestTypes(
  'worktime/worktime',
  'ADD_WORKTIME',
  requestActions
);

export const EDIT_WORKTIME = createRequestTypes(
  'worktime/worktime',
  'EDIT_WORKTIME',
  requestActions
);
