import { createDefaultApiAction } from 'redux/helpers';
import {
  changePassword,
  getPerson,
  setPassword,
  sendPasswordMail,
  checkPasswordGuid
} from 'utils/api/account';
import * as types from './types';

export const changePasswordAction = data =>
  createDefaultApiAction(types.CHANGE_PASSWORD, changePassword, data);

export const setPasswordAction = data =>
  createDefaultApiAction(types.SET_PASSWORD, setPassword, data);

export const getPersonAction = (id, uid) =>
  createDefaultApiAction(types.GET_PERSON, getPerson, { id, uid });

export const sendPasswordMailAction = email =>
  createDefaultApiAction(types.SEND_MAIL, sendPasswordMail, email);

export const sendPasswordMailRequestAction = () => dispatch =>
  dispatch({ type: types.SEND_MAIL.SUCCESS });

export const checkPasswordGuidAction = uid =>
  createDefaultApiAction(types.CHECK_PASSWORD_GUID, checkPasswordGuid, uid);
