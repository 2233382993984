import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-jss';
import PropTypes from 'prop-types';
import {
  PopupStyle,
  PopupOverlayStyle,
  MainButton,
  SecondaryButton,
  DeleteButton,
  ButtonGroup
} from 'shared/styles';
import Loading from 'shared/components/Loading';

const Modal = styled('div')({
  fontSize: '14px',
  width: '600px !important',
  height: '200px !important'
});

const StyledCancelButton = styled(SecondaryButton)({
  marginRight: '45px'
});

const Title = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '5px',
  color: 'black',
  boxSizing: 'border-box',
  fontSize: '22px',
  fontWeight: '500'
});

const Content = styled('div')({
  width: '100%',
  padding: '50px 5px 0 5px',
  boxSizing: 'border-box',
  color: 'black',
  fontSize: '24px',
  textAlign: 'center'
});

const ButtonsContainer = styled(ButtonGroup)({
  margin: '43px 0 56px 0 !important'
});

const SubmitWarningButton = styled(DeleteButton)({});

const SubmitMainButton = styled(MainButton)({});

const ConfirmPopup = ({
  title,
  text,
  cancelText,
  submitText,
  isAttentionPopup,
  onSubmit,
  onClose,
  isOpen,
  loading
}) => (
  <Popup
    open={isOpen}
    onClose={onClose}
    contentStyle={PopupStyle}
    overlayStyle={PopupOverlayStyle}
  >
    {loading ? (
      <Loading />
    ) : (
      <Modal>
        <Title>{title}</Title>
        <Content>{text}</Content>
        <ButtonsContainer>
          <StyledCancelButton onClick={onClose}>
            {cancelText}
          </StyledCancelButton>
          {isAttentionPopup ? (
            <SubmitWarningButton onClick={onSubmit}>
              {submitText}
            </SubmitWarningButton>
          ) : (
            <SubmitMainButton onClick={onSubmit}>{submitText}</SubmitMainButton>
          )}
        </ButtonsContainer>
      </Modal>
    )}
  </Popup>
);

ConfirmPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  isAttentionPopup: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

ConfirmPopup.defaultProps = {
  title: '',
  isAttentionPopup: false
};

export default ConfirmPopup;
