import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-jss';
import { tType } from 'shared/types';
import { SecondaryButton } from 'shared/styles';
import Input from 'shared/Fields/InputSearch';

const FormStyled = styled('form')({
  display: 'flex',
  width: '100%'
});

const SearchButton = styled(SecondaryButton)({
  height: '28px',
  boxShadow: 'none',
  borderRadius: '0',
  fontSize: '14px',
  borderLeft: '0'
});

const SearchBar = ({ handleSubmit, t }) => (
  <FormStyled onSubmit={handleSubmit}>
    <Field
      id="search"
      name="search"
      component={Input}
      type="text"
      placeholder={t('general:Search')}
    />
    <SearchButton type="submit">{t('general:Search')}</SearchButton>
  </FormStyled>
);

SearchBar.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired
};

export default reduxForm({ form: 'searchForm' })(withNamespaces()(SearchBar));
