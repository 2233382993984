import React from 'react';
import PropTypes from 'prop-types';
import Sorting from './Sorting';

const TableHead = ({ toggleSort, className, children, ...rest }) => (
  <div
    className={`rt-th ${className}`}
    onClick={e => toggleSort && toggleSort(e)}
    onKeyDown={e => toggleSort && toggleSort(e)}
    role="columnheader"
    tabIndex="-1"
    {...rest}
  >
    {children}
    <Sorting sortState={className} />
  </div>
);

TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string.isRequired,
  toggleSort: PropTypes.func
};

TableHead.defaultProps = {
  toggleSort: null,
  children: null
};

export default TableHead;
