import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';

import { metaType, tType } from 'shared/types';
import { BaseLabel } from 'shared/styles';
import { withNamespaces } from 'react-i18next';

const StyledInput = styled('input')({
  fontSize: '16px',
  fontFamily: 'inherit',
  padding: '5px 5px 3px 6px',
  height: '28px',
  backgroundColor: 'white',
  width: '100%',
  display: 'block',
  boxSizing: 'border-box',
  borderRadius: '0',
  border: '2px solid',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  outline: '0',
  '&:active': {
    borderColor: ({ theme }) => theme.charcoalGrey
  },
  '&:focus:not(:active)': {
    borderColor: ({ theme }) => theme.charcoalGrey
  },
  '&:hover:not(:focus)': {
    '-webkitBoxShadow': '0 0 0 30px #c3e8ff inset'
  }
});

const ErrorMessage = styled(BaseLabel)(({ theme }) => ({
  color: theme.error,
  opacity: 'auto',
  visibility: 'auto',
  margin: '10px 0 0 0px',
  textAlign: 'center',
  fontSize: '18px',
  width: '10rem'
}));

const Container = styled('div')({
  width: '100%'
});

const Input = ({
  t,
  label,
  type,
  placeholder,
  disabled,
  meta,
  id,
  showErrorMsg,
  styleInput,
  styleLabel,
  style,
  ...props
}) => (
  <Container style={style}>
    <StyledInput
      isInvalid={meta.error}
      touched={meta.touched}
      id={id}
      {...props.input}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      style={styleInput}
      {...props}
    />
    {showErrorMsg && meta.touched && meta.error && (
      <ErrorMessage>{t(meta.error)}</ErrorMessage>
    )}
  </Container>
);

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: metaType.isRequired,
  t: tType.isRequired,
  showErrorMsg: PropTypes.bool,
  styleInput: PropTypes.shape({}),
  styleLabel: PropTypes.shape({}),
  style: PropTypes.shape({})
};

Input.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  showErrorMsg: false,
  styleInput: null,
  styleLabel: null,
  style: null
};

export default withNamespaces()(Input);
