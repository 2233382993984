import React from 'react';

const IconMCheckboxAnnotationsFilled = props => (
  <svg width={20} height={20} {...props}>
    <path d="M3 1a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3zm0-1h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" />
    <rect x={2} y={2} width={16} height={16} rx={1} />
  </svg>
);

export default IconMCheckboxAnnotationsFilled;
