/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import { currency } from 'utils/dictionaries';
import {
  tType,
  actionType,
  errorMessageType,
  workerTimesType
} from 'shared/types';

// redux auth
import { getUserId } from 'redux/modules/auth/selectors';

// redux worker
import { getWorker } from 'redux/modules/worker/selectors';
import { getWorkerAction } from 'redux/modules/worker/actions';

// redux worktime
import { getWorkerTimesUser } from 'redux/modules/worktime/selectors';
import {
  getWorkerTimesUserAction,
  updateWorkTimeAction
} from 'redux/modules/worktime/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

// components
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import ConfirmPopup from 'shared/components/ConfirmPopup';
import {
  Container,
  InTableConfirmButton,
  InTableRejectButton
} from 'shared/styles';

class WorkTimes extends PureComponent {
  state = {
    confirmPopupIsOpen: false,
    rejectPopupIsOpen: false,
    selectedIdWorkTime: null
  };

  async componentDidMount() {
    const { userId, getWorkerTimesUserAct, getWorkerAct } = this.props;
    await getWorkerTimesUserAct(userId);
    await getWorkerAct(userId);
  }

  updateStatus = async (workTimeId, statusId) => {
    const { userId, updateWorkTimeAct, getWorkerTimesUserAct } = this.props;
    await updateWorkTimeAct({
      statusId,
      userId,
      workTimeId
    });
    if (!this.props.errorPopup) {
      await getWorkerTimesUserAct(userId);
    }
  };

  onPopupConfirm = () => {
    this.updateStatus(this.state.selectedIdWorkTime, 2);
    this.closePopups();
  };

  onPopupReject = () => {
    this.updateStatus(this.state.selectedIdWorkTime, 3);
    this.closePopups();
  };

  closePopups = () => {
    this.setState({
      confirmPopupIsOpen: false,
      rejectPopupIsOpen: false
    });
  };

  getTdProps = (state, rowInfo, column) => {
    if (rowInfo) {
      return {
        onClick: () => {
          if (column.id === 'confirm') {
            this.setState({
              confirmPopupIsOpen: true,
              selectedIdWorkTime: rowInfo.original.idWorkTime
            });
          } else if (column.id === 'reject') {
            this.setState({
              rejectPopupIsOpen: true,
              selectedIdWorkTime: rowInfo.original.idWorkTime
            });
          }
        }
      };
    }
    return {};
  };

  getTrProps = (state, row) => {
    if (row) {
      return {
        style: {
          fontWeight: row.original.isPpk && 'bold'
        }
      };
    }
    return {};
  };

  render() {
    const { t, workerTimes, isFetching } = this.props;
    const columns = [
      {
        Header: t('workTimes:Year'),
        accessor: 'year'
      },
      {
        accessor: 'idWorkTime',
        show: false
      },
      {
        Header: t('workTimes:Month'),
        accessor: 'monthName',
        Cell: row => t(`months:${row.value}`)
      },
      {
        Header: t('workTimes:ProjectName'),
        accessor: 'projectName'
      },
      {
        Header: t('workTimes:Hours'),
        accessor: 'timeValue'
      },
      {
        Header: t('workTimes:HourlyRate'),
        accessor: 'salaryPerHour'
      },

      {
        Header: t('workTimes:Salary'),
        accessor: 'salaryPerHour',
        Cell: row => (
          <div>
            <span title={row.original.isPpk && t('workTimes:reduceByPpk')}>
              {row.value * row.original.timeValue}
            </span>
          </div>
        )
      },
      {
        Header: t('workTimes:Currency'),
        accessor: 'idCurrency',
        Cell: row => {
          const cur = currency.find(c => c.value === row.value);
          return cur ? cur.label : '';
        }
      },
      {
        Header: t('workTimes:Status'),
        accessor: 'status.statusName',
        Cell: row => t(`workTimeStatuses:${row.value}`)
      },
      {
        // Header: t('workTimes:Reject'),
        accessor: 'status.idStatus',
        id: 'reject',
        Cell: row =>
          [1, 4].includes(row.value) && (
            <InTableRejectButton>{t('workTimes:Reject')}</InTableRejectButton>
          )
      },
      {
        // Header: t('workTimes:Confirm'),
        accessor: 'status.idStatus',
        id: 'confirm',
        Cell: row =>
          [1, 4].includes(row.value) && (
            <InTableConfirmButton>
              {t('workTimes:Confirm')}
            </InTableConfirmButton>
          )
      }
    ];

    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }
    const { confirmPopupIsOpen, rejectPopupIsOpen } = this.state;
    return (
      <Container style={{ marginTop: '20px' }}>
        <Table
          getTrProps={this.getTrProps}
          data={workerTimes}
          columns={columns}
          defaultPageSize={10}
          getTdProps={this.getTdProps}
        />
        <ConfirmPopup
          text={t('workTimes:CautionMsg')}
          onClose={this.closePopups}
          onSubmit={this.onPopupConfirm}
          cancelText={t('general:Cancel')}
          isOpen={confirmPopupIsOpen}
          submitText={t('workTimes:Confirm')}
          loading={false}
        />
        <ConfirmPopup
          text={t('workTimes:CautionMsg')}
          onClose={this.closePopups}
          onSubmit={this.onPopupReject}
          cancelText={t('general:Cancel')}
          isOpen={rejectPopupIsOpen}
          submitText={t('workTimes:Reject')}
          loading={false}
        />
      </Container>
    );
  }
}

WorkTimes.propTypes = {
  t: tType.isRequired,
  getWorkerTimesUserAct: actionType.isRequired,
  updateWorkTimeAct: actionType.isRequired,
  getWorkerAct: actionType.isRequired,
  errorPopup: errorMessageType,
  isFetching: PropTypes.bool,
  workerTimes: workerTimesType.isRequired
};

WorkTimes.defaultProps = {
  errorPopup: {},
  isFetching: true
};

const loadingSelector = createLoadingSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKER_TIMES_USER'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKER_TIMES_USER'
]);

const loadingPopupSelector = createLoadingSelector([
  'worktime/worktime/UPDATE_WORKTIME'
]);

const errorPopupSelector = createErrorMessageSelector([
  'worktime/worktime/UPDATE_WORKTIME'
]);

function mapStateToProps(state) {
  return {
    userId: getUserId(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    isFetchingPopup: loadingPopupSelector(state),
    errorPopup: errorPopupSelector(state),
    worker: getWorker(state),
    workerTimes: getWorkerTimesUser(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      getWorkerTimesUserAct: getWorkerTimesUserAction,
      updateWorkTimeAct: updateWorkTimeAction,
      getWorkerAct: getWorkerAction
    }
  )(WorkTimes)
);
