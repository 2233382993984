import axios from './index';

export async function getReportCsv(data) {
  return axios.post('api/reports/worktime/csv', data);
}
export async function getReportPdf(data) {
  return axios.post('api/reports/worktime/pdf', data, { responseType: 'blob' });
}

export async function getReportMonths() {
  return axios.get('api/reports/worktime/months');
}

export async function getUsers() {
  return axios.get('api/reports/worktime/persons');
}

export async function getColumns() {
  return axios.get('api/reports/worktime/columns');
}
