import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import DownIcon from 'assets/Icon/icons/ArrowDown';
import UpIcon from 'assets/Icon/icons/ArrowUp';
import { tType } from 'shared/types';
import { SelectContainer } from 'shared/styles';

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen && (
        <Fragment>
          <UpIcon />
          <UpIcon />
        </Fragment>
      )}
      {!props.selectProps.menuIsOpen && (
        <Fragment>
          <DownIcon />
          <DownIcon />
        </Fragment>
      )}
    </components.DropdownIndicator>
  );

const InnerSelectContainer = styled('div')({
  display: 'table',
  width: '100%',
  maxWidth: '400px',
  minWidth: '120px'
});

const SelectLanguage = ({
  options,
  defaultOption,
  onChange,
  style,
  t,
  ...props
}) => (
  <SelectContainer style={style}>
    <InnerSelectContainer>
      <Select
        components={{ DropdownIndicator }}
        options={options}
        defaultValue={defaultOption}
        onChange={onChange}
        isSearchable={false}
        value={optionObj => optionObj.value}
        getOptionLabel={optionObj => t(optionObj.label)}
        {...props}
        className="select"
        classNamePrefix="select"
      />
    </InnerSelectContainer>
  </SelectContainer>
);

SelectLanguage.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ).isRequired,
  defaultOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  t: tType.isRequired
};

SelectLanguage.defaultProps = {
  style: {},
  defaultOption: null
};

export default SelectLanguage;
