import * as types from './types';

export default function reducer(
  state = {
    worker: {
      idCurrency: '1',
      idLanguageDict: '1'
    },
    workers: {
      list: [{}]
    },
    emailHistory: []
  },
  action
) {
  switch (action.type) {
    case types.GET_WORKER.SUCCESS:
      return {
        ...state,
        worker: action.payload
      };
    case types.GET_WORKERS.SUCCESS:
      return {
        ...state,
        workers: action.payload
      };
    case types.EMAIL_HISTORY_WORKER.SUCCESS:
      return {
        ...state,
        emailHistory: action.payload
      };
    default:
      return state;
  }
}
