import * as types from './types';

export default function reducer(
  state = {
    message: '',
    show: false
  },
  action
) {
  switch (action.type) {
    case types.ADD_TOAST.SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case types.HIDE_TOAST.SUCCESS:
      return {
        ...state,
        message: '',
        show: false
      };
    default:
      return state;
  }
}
