export default {
  dateFrom: '2013-12-01T00:00:00',
  dateTo: '2016-01-31T23:59:00',
  generatedBy: 'Jan Kowalski',
  generatedAt: '2019-05-19T15:00:00',
  columns: [
    {
      value: 'year',
      label: 'Year'
    },
    {
      value: 'month',
      label: 'Month'
    },
    {
      value: 'hours',
      label: 'Hours'
    },
    {
      value: 'hourlyRate',
      label: 'HourlyRate'
    },
    {
      value: 'salary',
      label: 'Salary'
    },
    {
      value: 'currency',
      label: 'Currency'
    }
  ],
  data: [
    {
      name: 'Jan Kowalski',
      values: [
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        }
      ]
    },
    {
      name: 'Jan Kowalski',
      values: [
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        }
      ]
    },
    {
      name: 'Adam Nowak',
      values: [
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        }
      ]
    },
    {
      name: 'Adam Nowak',
      values: [
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        }
      ]
    },
    {
      name: 'Jan Kowalski',
      values: [
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2019',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2020',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        },
        {
          year: '2018',
          month: '06',
          hours: '165',
          hourlyRate: '30',
          salary: '3000',
          currency: 'PLN'
        }
      ]
    }
  ]
};
