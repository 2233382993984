import axios from './index';

export async function getSettings(userId) {
  return axios.get(`api/account/GetAdminSettings/${userId}/`);
}

export async function setSettings(values) {
  return axios.put('api/account/SetAdminSettings/', values);
}

export async function changeLanguage(values) {
  return axios.put(`api/persons/${values.idPerson}/changeLanguage`, values);
}

export async function changePassword() {
  return axios.post('api/account/settings/changePassword');
}
