import * as types from './types';

export default function reducer(
  state = {
    months: [],
    columns: [],
    users: [],
    report: ''
  },
  action
) {
  switch (action.type) {
    case types.GET_REPORT.SUCCESS:
      return {
        ...state,
        report: action.payload
      };
    case types.GET_REPORT_MONTHS.SUCCESS:
      return {
        ...state,
        months: action.payload
      };
    case types.GET_REPORT_USERS.SUCCESS:
      return {
        ...state,
        users: action.payload
      };
    case types.GET_REPORT_COLUMNS.SUCCESS:
      return {
        ...state,
        columns: action.payload
      };
    default:
      return state;
  }
}
