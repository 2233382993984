import React from 'react';
import styled from 'styled-jss';

const LoaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});

const StyledLoaderSpin = styled('div')({
  border: '10px solid #f3f3f3',
  borderTop: ({ theme }) => `10px solid ${theme.navbar.backgroundColor}`,
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  animation: 'spin 2s linear infinite'
});

const Loading = () => (
  <LoaderContainer>
    <StyledLoaderSpin />
  </LoaderContainer>
);

export default Loading;
