import { requestActions, createRequestTypes } from 'redux/helpers';

export const HIDE_TOAST = createRequestTypes(
  'worktime/toast',
  'HIDE_TOAST',
  requestActions
);

export const ADD_TOAST = createRequestTypes(
  'worktime/toast',
  'ADD_TOAST',
  requestActions
);
