import * as types from './types';

export const hideToastAction = () => dispatch => {
  dispatch({
    type: types.HIDE_TOAST.SUCCESS,
    payload: { message: null, show: false }
  });
};

export const addToastAction = message => dispatch => {
  dispatch({
    type: types.ADD_TOAST.SUCCESS,
    payload: { message, show: true }
  });
  setTimeout(() => hideToastAction()(dispatch), 5000);
};
