import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import {
  isAuthenticated,
  getRole,
  getUserId
} from 'redux/modules/auth/selectors';
import SelectLanguage from 'shared/components/SelectLanguage';
import { changeLanguageAction } from 'redux/modules/settings/actions';

import { WebsiteTitle, LogoContainer } from 'shared/styles';
import { historyType, tType, i18nType } from 'shared/types';
import { languages } from 'utils/dictionaries';
import Logo from 'assets/img/LogoWorktime';
import AvatarDropdown from './components/AvatarDropdown';

const LogoHolder = styled('div')({
  position: 'absolute',
  left: '10px',
  top: '10px'
});

const Nav = styled('div')({
  width: '1172px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: ({ theme }) => theme.background,
  color: 'black',
  height: '68px',
  padding: '0 58px 0 10px',
  boxSizing: 'border-box',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)'
});

const NavContainer = styled('div')({
  backgroundColor: ({ theme }) => theme.mainBackground,
  minHeight: '68px',
  height: '68px',
  width: '1420px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center'
});

const RightContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const Filler = styled('div')({
  width: '30px',
  visibility: 'hidden'
});

class Navbar extends Component {
  state = {
    lng: null
  };

  async componentDidMount() {
    const lngObj = languages.find(obj => obj.shortName === this.props.lng);
    await this.setState({ lng: lngObj });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.lng !== state.lng) {
      return {
        lng: languages.find(obj => obj.shortName === props.lng)
      };
    }
    return null;
  }

  changeLanguage = async ({ shortName, value }) => {
    this.props.i18n.changeLanguage(shortName);
    const { idPerson } = this.props;
    if (idPerson) {
      await this.props.changeLanguage({
        idLanguageDict: value,
        idPerson
      });
    }
    const lngObj = languages.find(obj => obj.shortName === shortName);
    this.setState({ lng: lngObj });
  };

  redirectToHome = () => {
    const { history, role } = this.props;
    if (role === 'Administrator') {
      history.push('/workers');
    } else if (role === 'Pracownik') {
      history.push('/work-times');
    } else {
      history.push('/');
    }
  };

  render() {
    const { t, authenticated, history } = this.props;

    if (history.location.pathname === '/workers/report/pdf') return null;

    return (
      <NavContainer>
        <LogoContainer>
          <LogoHolder>
            <Logo />
          </LogoHolder>
          <WebsiteTitle onClick={this.redirectToHome}>
            {t('navbar:Title')}
          </WebsiteTitle>
        </LogoContainer>
        <Nav>
          <RightContainer>
            <SelectLanguage
              name="language"
              options={languages.toArray()}
              value={this.state.lng}
              onChange={this.changeLanguage}
              style={{ width: 'auto', marginRight: '53px' }}
              t={t}
            />
            {authenticated ? <AvatarDropdown history={history} /> : <Filler />}
          </RightContainer>
        </Nav>
      </NavContainer>
    );
  }
}

Navbar.propTypes = {
  history: historyType.isRequired,
  t: tType.isRequired,
  i18n: i18nType.isRequired,
  role: PropTypes.string,
  lng: PropTypes.string.isRequired,
  idPerson: PropTypes.string,
  changeLanguage: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired
};

Navbar.defaultProps = {
  role: null,
  idPerson: null
};

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
    role: getRole(state),
    idPerson: getUserId(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      changeLanguage: changeLanguageAction
    }
  )(Navbar)
);
