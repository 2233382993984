import axios from 'axios';
import { serverAddress } from 'utils/dictionaries';
import store from 'redux/store';
import { signOutAction } from 'redux/modules/auth/actions';

export function setAuthorization(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.defaults.baseURL = serverAddress;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  config => config,
  error => {
    console.warn(error.response);
    return null;
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  ({ response }) => {
    if (response.status === 401) {
      signOutAction()(store.dispatch);
    }
    if (response.status === 404) {
      return {
        error: {
          status: response.status,
          message: 'Not found'
        }
      };
    }
    if (response) {
      return {
        error: {
          status: response.status,
          message: response.data.error
        }
      };
    }
    return {
      error: {
        status: 500,
        message: 'BladSerwer'
      }
    };
  }
);

export default axios;
