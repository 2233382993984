import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import storage from 'redux/utis/storageFactory';
import { languages } from 'utils/dictionaries';

i18n.use(Backend).init({
  fallbackLng: 'pl',
  debug: false,
  load: 'all',
  lng: `${
    storage.getItem('language')
      ? languages.find(lng => +lng.value === +storage.getItem('language'))
          .shortName
      : 'en-US'
  }`,
  preload: ['pl-PL', 'en-US'],
  react: {
    wait: true
  },
  ns: [
    'dictionaries',
    'login',
    'validation',
    'navbar',
    'mails',
    'general',
    'register',
    'passwordRecovery',
    'workers',
    'workerActions',
    'worker',
    'workTimes',
    'tableProps',
    'settings',
    'report',
    'months',
    'workerStatuses',
    'workTimeStatuses',
    'pdf'
  ],
  keySeparator: false,
  interpolation: {
    formatSeparator: ','
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  }
});

export default i18n;
