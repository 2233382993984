import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import { getMonths, getYears } from 'utils/dictionaries';

import { getUserId } from 'redux/modules/auth/selectors';

import { getWorker } from 'redux/modules/worker/selectors';
import { getWorkerAction } from 'redux/modules/worker/actions';

import { getWorktime } from 'redux/modules/worktime/selectors';
import {
  getWorkTimeAction,
  editWorkTimeAction,
  editWorkTimeRequestAction
} from 'redux/modules/worktime/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import { getWorktimeMonth } from 'redux/modules/settings/selectors';

import Loading from 'shared/components/Loading';
import Error from 'shared/components/Error';

import {
  actionType,
  tType,
  workerTimeType,
  errorMessageType,
  matchType,
  historyType
} from 'shared/types';
import { CenteredContainerVertical } from 'shared/styles';
import WorkTimeInput from './components/WorkTimeInput';

class WorkerTimesEdit extends Component {
  async componentDidMount() {
    const {
      editWorkTimeRequest,
      match: {
        params: { workTimeId }
      }
    } = this.props;
    editWorkTimeRequest();

    await this.props.getWorkTime(workTimeId);
  }

  handleSubmit = async values => {
    const {
      adminId,
      history,
      editWorkTime,
      match: {
        params: { id, workTimeId }
      }
    } = this.props;
    const data = {
      ...values,
      salary: +values.salary,
      timeValue: +values.timeValue,
      salaryPerHour: +values.salaryPerHour,
      idWorkTime: +workTimeId,
      workerId: +id,
      adminId: +adminId
    };
    await editWorkTime(data);

    if (!this.props.errorForm) {
      history.push(`/worker-times/${id}`);
    }
  };

  onClickBackButton = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push(`/worker-times/${id}`);
  };

  render() {
    const { t, isFetching, errorForm, worktime } = this.props;

    if (isFetching) {
      return <Loading />;
    }

    if (errorForm && errorForm.status > 500) {
      return <Error error={errorForm} />;
    }

    return (
      <CenteredContainerVertical>
        <WorkTimeInput
          title={t('workTimes:EditWorkTime')}
          enableReinitialize
          onSubmit={this.handleSubmit}
          initialValues={{
            ...worktime,
            calcType: 1,
            salary: worktime.timeValue * worktime.salaryPerHour
          }}
          years={getYears()}
          months={getMonths()}
          serverErrorMessage={errorForm && errorForm.message}
          redirect={this.onClickBackButton}
        />
      </CenteredContainerVertical>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKTIME'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKTIME'
]);

const errorFormSelector = createErrorMessageSelector([
  'worktime/worktime/EDIT_WORKTIME'
]);

WorkerTimesEdit.propTypes = {
  match: matchType.isRequired,
  getWorkTime: actionType.isRequired,
  editWorkTimeRequest: actionType.isRequired,
  t: tType.isRequired,
  worktime: workerTimeType.isRequired,
  errorForm: errorMessageType,
  isFetching: PropTypes.bool.isRequired,
  history: historyType.isRequired,
  adminId: PropTypes.string.isRequired,
  editWorkTime: PropTypes.func.isRequired
};

WorkerTimesEdit.defaultProps = {
  errorForm: null
};

function mapStateToProps(state) {
  return {
    adminId: getUserId(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    errorForm: errorFormSelector(state),
    worker: getWorker(state),
    worktime: getWorktime(state),
    worktimeMonth: getWorktimeMonth(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      getWorkTime: getWorkTimeAction,
      editWorkTime: editWorkTimeAction,
      getWorker: getWorkerAction,
      editWorkTimeRequest: editWorkTimeRequestAction
    }
  )(WorkerTimesEdit)
);
