import PropTypes from 'prop-types';

export const metaType = PropTypes.shape({
  touched: PropTypes.bool,
  error: PropTypes.string
});

export const historyType = PropTypes.shape({
  push: PropTypes.func.isRequired
});

export const tType = PropTypes.func;

export const actionType = PropTypes.func;

export const i18nType = PropTypes.shape({
  changeLanguage: PropTypes.func.isRequired
});

export const errorMessageType = PropTypes.shape({
  message: PropTypes.string,
  status: PropTypes.number.isRequired
});

export const workerType = PropTypes.shape({
  baseSalaryPerHour: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  idPerson: PropTypes.number,
  lastLogin: PropTypes.number,
  lastName: PropTypes.string,
  password: PropTypes.string,
  phone: PropTypes.string,
  status: PropTypes.number,
  workTimes: PropTypes.arrayOf(PropTypes.shape({})),
  personStatus: PropTypes.string
});

export const workerTimesType = PropTypes.arrayOf(
  PropTypes.shape({
    idCurrency: PropTypes.number.isRequired,
    idWorkTime: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    monthName: PropTypes.string.isRequired,
    projectName: PropTypes.string,
    salaryPerHour: PropTypes.number.isRequired,
    status: PropTypes.shape({
      idStatus: PropTypes.number.isRequired,
      statusName: PropTypes.string.isRequired,
      updateStatus: PropTypes.string,
      canDeleteWorkTime: PropTypes.bool.isRequired
    }),
    timeValue: PropTypes.number.isRequired,
    wasUpdated: PropTypes.bool.isRequired,
    year: PropTypes.number.isRequired
  })
);

export const workerTimeType = PropTypes.shape({
  idCurrency: PropTypes.number,
  idWorkTime: PropTypes.number,
  month: PropTypes.number,
  monthName: PropTypes.string,
  projectName: PropTypes.string,
  salaryPerHour: PropTypes.number,
  status: PropTypes.shape({
    idStatus: PropTypes.number,
    statusName: PropTypes.string,
    updateStatus: PropTypes.string
  }),
  timeValue: PropTypes.number,
  wasUpdated: PropTypes.bool,
  year: PropTypes.number
});

export const workersType = PropTypes.arrayOf(
  PropTypes.shape({
    lastStatus: PropTypes.shape({
      month: PropTypes.number.isRequired,
      monthName: PropTypes.string,
      projectName: PropTypes.string,
      salaryPerHour: PropTypes.number.isRequired,
      status: PropTypes.shape({
        idStatus: PropTypes.number.isRequired,
        statusName: PropTypes.string.isRequired,
        updateTime: PropTypes.string
      }),
      timeValue: PropTypes.number.isRequired,
      wasUpdated: PropTypes.bool.isRequired,
      year: PropTypes.number.isRequired
    }),
    person: PropTypes.shape({
      baseSalaryPerHour: PropTypes.number,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      idPerson: PropTypes.number.isRequired,
      lastLogin: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      password: PropTypes.string,
      phone: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      workTimes: PropTypes.string
    }).isRequired,
    sum: PropTypes.number.isRequired,
    wasUpdated: PropTypes.bool.isRequired
  }).isRequired
);

export const locationType = PropTypes.shape({
  search: PropTypes.string.isRequired
});

export const matchType = PropTypes.shape({
  params: PropTypes.shape({
    action: PropTypes.string
  })
});
