import styled from 'styled-jss';
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { MainButton } from 'shared/styles';

const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
});

const SessionExpired = ({ t, history }) => (
  <Container>
    <div>
      <h1>{t('SessioExpired')}</h1>
      <div>{t('SessioExpiredDetails')}</div>
      <MainButton
        style={{ padding: '', margin: '10px auto auto auto' }}
        onClick={() => history.push('/login')}
      >
        {t('login:LogIn')}
      </MainButton>
    </div>
  </Container>
);

SessionExpired.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired
};

export default withNamespaces(['general', 'login'])(SessionExpired);
