import {
  createDefaultApiAction,
  createDefaultApiRequestAction
} from 'redux/helpers';
import {
  updateWorkTime,
  getWorkerTimesUser,
  deleteWorkTime,
  getWorkerTimesAdmin,
  getWorkTime,
  addWorkTime,
  editWorkTime
} from 'utils/api/workTime';
import * as types from './types';

export const getWorkerTimesUserAction = id =>
  createDefaultApiAction(types.GET_WORKER_TIMES_USER, getWorkerTimesUser, id);

export const updateWorkTimeAction = data =>
  createDefaultApiAction(types.UPDATE_WORKTIME, updateWorkTime, data);

export const deleteWorkTimeAction = idWorkTime =>
  createDefaultApiAction(types.DELETE_WORKTIME, deleteWorkTime, idWorkTime);

export const getWorkerTimesAdminAction = data =>
  createDefaultApiAction(
    types.GET_WORKER_TIMES_ADMIN,
    getWorkerTimesAdmin,
    data
  );

export const getWorkTimeAction = data =>
  createDefaultApiAction(types.GET_WORKTIME, getWorkTime, data);

export const addWorkTimeAction = data =>
  createDefaultApiAction(types.ADD_WORKTIME, addWorkTime, data);

export const addWorkTimeRequestAction = () =>
  createDefaultApiRequestAction(types.ADD_WORKTIME);

export const editWorkTimeAction = data =>
  createDefaultApiAction(types.EDIT_WORKTIME, editWorkTime, data);

export const editWorkTimeRequestAction = () =>
  createDefaultApiRequestAction(types.EDIT_WORKTIME);
