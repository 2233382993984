import React from 'react';

const Lock = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M4 9a8 8 0 1 1 16 0h1v14H3V9h1zm2 0h12A6 6 0 1 0 6 9zm-1 2v10h14V11H5zm7 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
      fill="#434345"
    />
  </svg>
);

export default Lock;
