import { requestActions, createRequestTypes } from 'redux/helpers';

export const LOGIN = createRequestTypes(
  'worktime/auth',
  'LOGIN',
  requestActions
);

export const LOGOUT = createRequestTypes(
  'worktime/auth',
  'LOGOUT',
  requestActions
);
