const errorReducer = (state = {}, action) => {
  const { type, payload } = action;
  if(!type.startsWith('worktime')){
    return state;
  }
  const matches = /(.*)_(REQUEST|ERROR|SUCCESS)/.exec(
    type
  );
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'ERROR' ? payload : null,
  };
};

export default errorReducer;
