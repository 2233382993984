import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-jss';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { getToast } from 'redux/modules/toast/selectors';
import { hideToastAction } from 'redux/modules/toast/actions';

const StyledToast = styled('div')({
  position: 'absolute',
  top: '50px',
  margin: '0 auto',
  left: 0,
  right: 0,
  width: '500px',
  display: 'flex',
  justifyContent: 'center',
  border: '1px solid rgb(195, 232, 255)',
  backgroundColor: 'white',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  cursor: 'pointer'
});

const Toast = props => {
  if (!props.toast.show) {
    return null;
  }
  return (
    <StyledToast onClick={props.hideToastAction}>
      <h4>{props.t(`toast:${props.toast.message}`)}</h4>
    </StyledToast>
  );
};

Toast.propTypes = {
  t: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired,
  hideToastAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  toast: getToast(state)
});

export default connect(
  mapStateToProps,
  { hideToastAction }
)(withNamespaces('toast')(Toast));
