import { createDefaultApiAction } from 'redux/helpers';
import {
  getReportCsv,
  getReportMonths,
  getUsers,
  getColumns,
  getReportPdf
} from 'utils/api/report';
import * as types from './types';

export const getReportCsvAction = data =>
  createDefaultApiAction(types.GET_REPORT, getReportCsv, data, responseData => {
    const blob = new Blob(['\ufeff', responseData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'raport.csv';
    link.click();
  });

export const getReportPdfAction = data =>
  createDefaultApiAction(types.GET_REPORT, getReportPdf, data, responseData => {
    const blob = new Blob([responseData], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'raport.pdf';
    link.click();
  });

/* eslint-disable function-paren-newline */
export const getReportMonthsAction = () =>
  createDefaultApiAction(types.GET_REPORT_MONTHS, getReportMonths, null, data =>
    data.map(obj => ({
      label: new Date(obj).toLocaleDateString('en-US', {
        month: 'numeric',
        year: 'numeric'
      }),
      value: obj
    }))
  );
/* eslint-enable function-paren-newline */

export const getReportUsersAction = () =>
  createDefaultApiAction(types.GET_REPORT_USERS, getUsers);

export const getReportColumnsAction = () =>
  createDefaultApiAction(types.GET_REPORT_COLUMNS, getColumns);
