import axios from './index';

export async function updateWorkTime({ statusId, userId, workTimeId }) {
  const url = 'api/worktimes';
  // url += statusId === 2 ? 'accept-by' : 'discard-by';
  return axios.put(
    `${url}/${workTimeId}/${
      statusId === 2 ? 'accept-by' : 'discard-by'
    }/${userId}`
  );
}

export async function getWorkerTimesUser(id) {
  return axios.get(`api/worktimes/persons/${id}`);
}

export async function getWorkerTimesAdmin({ workerId, page, pageSize }) {
  return axios.get(`api/worktimes/adm/${workerId}/${page}/${pageSize}`);
}

export async function deleteWorkTime(idWorkTime) {
  return axios.delete(`api/worktimes/${idWorkTime}`);
}

export async function addWorkTime(data) {
  return axios.post('api/worktimes/', data);
}

export async function getWorkTime(workTimeId) {
  return axios.get(`api/worktimes/${workTimeId}`);
}

export async function editWorkTime(data) {
  return axios.put(`api/worktimes/${data.idWorkTime}`, data);
}
