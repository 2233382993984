import { requestActions, createRequestTypes } from 'redux/helpers';

export const GET_REPORT = createRequestTypes(
  'worktime/report',
  'GET_REPORT',
  requestActions
);

export const GET_REPORT_YEARS = createRequestTypes(
  'worktime/report',
  'GET_REPORT_YEARS',
  requestActions
);

export const GET_REPORT_MONTHS = createRequestTypes(
  'worktime/report',
  'GET_REPORT_MONTHS',
  requestActions
);

export const GET_REPORT_USERS = createRequestTypes(
  'worktime/report',
  'GET_REPORT_USERS',
  requestActions
);

export const GET_REPORT_COLUMNS = createRequestTypes(
  'worktime/report',
  'GET_REPORT_COLUMNS',
  requestActions
);
