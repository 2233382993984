import { requestActions, createRequestTypes } from 'redux/helpers';

export const GET_SETTINGS = createRequestTypes(
  'worktime/settings',
  'GET_SETTINGS',
  requestActions
);

export const SET_SETTINGS = createRequestTypes(
  'worktime/settings',
  'SET_SETTINGS',
  requestActions
);

export const CHANGE_LANGUAGE = createRequestTypes(
  'worktime/settings',
  'CHANGE_LANGUAGE',
  requestActions
);

export const CHANGE_PASSWORD = createRequestTypes(
  'worktime/settings',
  'CHANGE_PASSWORD',
  requestActions
);
