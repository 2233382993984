import * as types from './types';

export default function(state = { fullname: '' }, action) {
  switch (action.type) {
    case types.LOGIN.SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case types.LOGOUT.SUCCESS:
      return { ...state, email: null, role: null, token: null, userId: null };
    default:
      return state;
  }
}
