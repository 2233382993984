import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import DownIcon from 'assets/Icon/icons/ArrowDown';
import UpIcon from 'assets/Icon/icons/ArrowUp';

const Sort = styled('span')({
  position: 'absolute',
  top: '12px',
  right: '5px',
  '& svg': {
    fill: ({ theme }) => theme.primaryColor
  }
});

const Sorting = props => {
  switch (props.sortState) {
    case 'rt-resizable-header -sort-desc -cursor-pointer':
      return (
        <Sort>
          <DownIcon />
        </Sort>
      );
    case '-sort-desc -cursor-pointer':
      return (
        <Sort>
          <DownIcon />
        </Sort>
      );
    case 'rt-resizable-header -sort-asc -cursor-pointer':
      return (
        <Sort>
          <UpIcon />
        </Sort>
      );
    case '-sort-asc -cursor-pointer':
      return (
        <Sort>
          <UpIcon />
        </Sort>
      );
    default:
      return <div />;
  }
};

Sorting.propTypes = {
  sortState: PropTypes.string.isRequired
};

export default Sorting;
