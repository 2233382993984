import React from 'react';

const Unlock = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M20 9h1v14H3V9h15V8A6 6 0 0 0 7.523 4.005C6 4 6 4 5.054 4.028A8 8 0 0 1 20 8v1zM5 11v10h14V11H5zm7 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
      fill="#434345"
      fillRule="evenodd"
    />
  </svg>
);

export default Unlock;
