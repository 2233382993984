export function createRequestTypes(path, actionName, actions) {
  return actions.reduce(
    (result, action) => ({
      ...result,
      [action]: `${path}/${actionName}_${action}`
    }),
    {}
  );
}
export const requestActions = ['REQUEST', 'SUCCESS', 'ERROR'];

export function createDefaultApiAction(
  types,
  apiFunction,
  data,
  responseDataFunction
) {
  return async dispatch => {
    const [requestType, successType, failureType] = Object.values(types);
    dispatch({ type: requestType });
    const response = await apiFunction(data);
    if (response.error) {
      dispatch({ type: failureType, payload: response.error });
    } else {
      dispatch({
        type: successType,
        payload: responseDataFunction
          ? responseDataFunction(response.data)
          : response.data
      });
    }
  };
}

export function createDefaultApiRequestAction(types) {
  return dispatch => {
    const [requestType] = Object.values(types);
    dispatch({ type: requestType });
  };
}
