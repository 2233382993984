import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import { tType } from 'shared/types';
import Input from 'shared/Fields/Input';
import { requiredField } from 'utils/fields/validation';
import {
  MainButton,
  ButtonGroup,
  CenteredContainerVertical,
  FormTitle
} from 'shared/styles';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '263px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '319px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '270px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  top: '245px',
  left: 'calc(50% - 250px)',
  zIndex: '0'
});

const StyledLink = styled(Link)({
  textAlign: 'center',
  marginTop: 'auto',
  fontSize: '14px',
  color: ({ theme }) => theme.black75,
  '&:hover': {
    textDecoration: 'underline',
    color: '#2c5d68'
  },
  '&:active': {
    color: '#b22929'
  }
});

const StyledForm = styled('form')({
  margin: '90px auto 0 auto',
  boxSizing: 'border-box',
  width: '270px',
  ':not(:last-child)': {
    marginBottom: '4rem'
  }
});

const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative'
});

const LoginInput = ({ handleSubmit, t, errorMessage }) => (
  <CenteredContainerVertical>
    <BackgroundSquare />
    <StyledForm onSubmit={handleSubmit}>
      <FormContent style={{ height: '295px' }}>
        <FormTitle>{t('login:LogIn')}</FormTitle>
        <Field
          autoFocus
          showErrorMsg
          id="email"
          component={Input}
          name="email"
          validate={[requiredField]}
          type="text"
          label={t('login:Email')}
          /* placeholder={t('login:Email')} */
          t={t}
        />
        <Field
          styleLabel={{ marginTop: '10px' }}
          id="password"
          component={Input}
          name="password"
          validate={[requiredField]}
          type="password"
          label={t('login:Password')}
          /* placeholder={t('login:Password')} */
          t={t}
        />
        <ErrorMessageForm error={errorMessage} />
        <StyledLink to="/send-mail">{t('login:ForgotPasswordLink')}</StyledLink>
        <LineHorizontalTop>
          <LineDotHorizontal count={4} width={75} />
        </LineHorizontalTop>
        <LineVerticalTop>
          <LineDotVertical count={5} />
        </LineVerticalTop>
        <LineHorizontalBottom>
          <LineDotHorizontal count={4} width={75} />
        </LineHorizontalBottom>
      </FormContent>
      <FormContent style={{ margin: '9px 0' }}>
        <ButtonGroup>
          <MainButton type="submit">{t('login:LogInButton')}</MainButton>
        </ButtonGroup>
      </FormContent>
    </StyledForm>
  </CenteredContainerVertical>
);

LoginInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired,
  errorMessage: PropTypes.string
};

LoginInput.defaultProps = {
  errorMessage: null
};

export default reduxForm({ form: 'loginInputForm' })(
  withNamespaces()(LoginInput)
);
