import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loadingReducer from './loading';
import errorReducer from './error';

import authReducer from './auth';
import accountReducer from './account';
import workerReducer from './worker';
import worktimeReducer from './worktime';
import reportReducer from './report';
import settingsReducer from './settings';
import toastReducer from './toast';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  loading: loadingReducer,
  error: errorReducer,
  account: accountReducer,
  worktime: worktimeReducer,
  worker: workerReducer,
  report: reportReducer,
  settings: settingsReducer,
  toast: toastReducer
});

export default rootReducer;
