import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  addWorkerAction,
  addWorkerRequestAction
} from 'redux/modules/worker/actions';

import { createErrorMessageSelector } from 'redux/modules/selectors';

import Loading from 'shared/components/Loading';
import { actionType, tType, errorMessageType, historyType } from 'shared/types';
import { CenteredContainerVertical } from 'shared/styles';
import WorkerInput from './components/WorkerInput';

class Worker extends PureComponent {
  componentDidMount() {
    this.props.addWorkerRequest();
  }

  handleSubmit = async values => {
    await this.props.addWorker(values);
    if (!this.props.error) {
      this.redirect();
    }
  };

  redirect = () => this.props.history.push('/workers');

  render() {
    const { t, isFetching, error } = this.props;

    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }

    return (
      <CenteredContainerVertical>
        <WorkerInput
          title={t('workerActions:AddEmployee')}
          submitLabel={t('workerActions:Add')}
          onSubmit={this.handleSubmit}
          initialValues={{
            idCurrency: 1,
            idLanguageDict: '1'
          }}
          serverErrorMessage={error && error.message}
          redirect={this.redirect}
        />
      </CenteredContainerVertical>
    );
  }
}

Worker.propTypes = {
  t: tType.isRequired,
  history: historyType.isRequired,
  addWorkerRequest: actionType.isRequired,
  addWorker: actionType.isRequired,
  error: errorMessageType,
  isFetching: PropTypes.bool
};

Worker.defaultProps = {
  error: null,
  isFetching: false
};

const errorSelector = createErrorMessageSelector([
  'worktime/worker/ADD_WORKER'
]);

function mapStateToProps(state) {
  return {
    error: errorSelector(state)
  };
}

export default connect(
  mapStateToProps,
  {
    addWorker: addWorkerAction,
    addWorkerRequest: addWorkerRequestAction
  }
)(withNamespaces()(Worker));
