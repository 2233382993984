/* eslint-disable prettier/prettier */
import { login } from 'utils/api/account';
import jwtDecode from 'jwt-decode';
import storage from 'redux/utis/storageFactory';
import { GET_SETTINGS } from 'redux/modules/settings/types';
import * as types from './types';

export function signInAction(data) {
  return async dispatch => {
    try {
      dispatch({ type: types.LOGIN.REQUEST });
      const {
        data: { token }
      } = await login(data);
      const decodedToken = jwtDecode(token);
      console.log('decodedToken :', decodedToken);
      const email = decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
        ];
      const role = decodedToken[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ];
      const userId = decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
        ];
      const language = decodedToken.Language.toString();
      const fullname = decodedToken.FullName.toString();

      const { NumberOfRows, MonthWorkTime } = decodedToken;
      storage.setItem('numberOfRows', NumberOfRows);
      storage.setItem('monthWorkTime', MonthWorkTime);

      dispatch({
        type: GET_SETTINGS.SUCCESS,
        payload: {
          numberOfRows: NumberOfRows,
          monthWorkTime: MonthWorkTime
        }
      });

      storage.setItem('fullname', fullname);
      storage.setItem('token', token);
      storage.setItem('language', language);
      storage.setItem('email', email);
      storage.setItem('role', role);
      storage.setItem('userId', userId);

      dispatch({
        type: types.LOGIN.SUCCESS,
        payload: {
          token,
          language,
          email,
          role,
          userId,
          fullname
        }
      });
    } catch (error) {
      dispatch({
        type: types.LOGIN.ERROR,
        payload: { errorMessage: 'IncorrectLoginCredentials' }
      });
    }
  };
}

export function signOutAction() {
  return dispatch => {
    storage.removeItem('token');
    storage.removeItem('email');
    storage.removeItem('role');
    storage.removeItem('userId');
    dispatch({ type: types.LOGOUT.SUCCESS });
  };
}
