import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { CenteredContainerVertical } from 'shared/styles';

import {
  getWorkersNumberOfRows,
  getWorktimeMonth
} from 'redux/modules/settings/selectors';
import {
  getSettingsAction,
  setSettingsAction
} from 'redux/modules/settings/actions';

import { addToastAction } from 'redux/modules/toast/actions';

import { historyType } from 'shared/types';

import { getUserId } from 'redux/modules/auth/selectors';

import Loading from 'shared/components/Loading';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';
import SettingsForm from './components/SettingsForm';

class Settings extends PureComponent {
  async componentDidMount() {
    await this.props.getSettingsAction(this.props.userId);
  }

  handleSubmit = async values => {
    await this.props.setSettingsAction(values);
    if (!this.props.error) {
      this.props.addToastAction('SettingsChangedSuccesfully');
      await this.props.getSettingsAction(this.props.userId);
      this.props.history.push('/workers');
    }
  };

  setInitialValues = () => {
    const { monthWorkTime } = this.props;
    return {
      monthWorkTime: monthWorkTime || 1,
      idPerson: this.props.userId
    };
  };

  redirectBack = () => this.props.history.goBack();

  render() {
    const { t, errorForm } = this.props;

    if (this.props.isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }
    return (
      <CenteredContainerVertical>
        <SettingsForm
          onSubmit={this.handleSubmit}
          serverErrorMessage={errorForm && errorForm.message}
          initialValues={this.setInitialValues()}
          redirect={this.redirectBack}
        />
      </CenteredContainerVertical>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  getSettingsAction: PropTypes.func.isRequired,
  addToastAction: PropTypes.func.isRequired,
  setSettingsAction: PropTypes.func.isRequired,
  monthWorkTime: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.shape({}),
  userId: PropTypes.string.isRequired,
  errorForm: PropTypes.shape({}),
  history: historyType.isRequired
};

Settings.defaultProps = {
  errorForm: null,
  error: null,
  isFetching: true
};

const loadingSelector = createLoadingSelector([
  'worktime/settings/GET_SETTINGS',
  'worktime/settings/SET_SETTINGS'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/settings/GET_SETTINGS'
]);

const errorFromSelector = createErrorMessageSelector([
  'worktime/settings/SET_SETTINGS'
]);

function mapStateToProps(state) {
  return {
    numberOfRows: getWorkersNumberOfRows(state),
    monthWorkTime: getWorktimeMonth(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    userId: getUserId(state),
    errorForm: errorFromSelector(state)
  };
}

export default connect(
  mapStateToProps,
  {
    getSettingsAction,
    setSettingsAction,
    addToastAction
  }
)(withNamespaces()(Settings));
