import styled from 'styled-jss';

// -----------------------buttons---------------------------------------
export const ButtonGroup = styled('div')({
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center'
});

const BaseButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  fontSize: '16px',
  height: '28px',
  fontWeight: '300',
  padding: '0 30px',
  color: ({ theme }) => theme.charcoalGrey,
  border: 'none',
  textAlign: 'center',
  backgroundColor: ({ theme }) => theme.lightBlue30,
  cursor: 'pointer',
  outline: 'none',
  boxShadow: '2px 2px 0 0 #c3e8ff',
  '&:focus': {
    /* transform: 'translateY(.2rem)', */
    boxShadow: ({ theme }) => theme.btn.base.focus.boxShadow
  }
});

export const MainButton = styled(BaseButton)({
  border: '2px solid',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  backgroundColor: ({ theme }) => theme.lightBlue30,
  '&:hover': {
    backgroundColor: ({ theme }) => theme.paleSkyBlue
  },
  '&:active': {
    borderColor: 'black',
    border: '2px solid',
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.paleSkyBlue,
    boxShadow: 'none'
  },
  '&:focus': {
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  }
});

export const SecondaryButton = styled(BaseButton)({
  backgroundColor: ({ theme }) => theme.white,
  border: '2px solid',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  '&:hover:not(:active)': {
    backgroundColor: ({ theme }) => theme.paleSkyBlue
  },
  '&:active': {
    borderColor: 'black',
    border: '2px solid',
    outline: '2px solid',
    outlineColor: ({ theme }) => theme.paleSkyBlue,
    boxShadow: 'none'
  },
  '&:focus': {
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  }
});

export const WarningButton = styled(BaseButton)({
  backgroundColor: ({ theme }) => theme.pastelRed,
  color: ({ theme }) => theme.btn.warning.txtColor,
  '&:hover': {
    backgroundColor: ({ theme }) => theme.hoverDelete,
    color: 'white'
  }
});

// green button
export const WelcomeButton = styled(BaseButton)({
  backgroundColor: ({ theme }) => theme.btn.welcome.backgroundColor,
  color: ({ theme }) => theme.btn.welcome.txtColor
});

export const WarningEmptyButton = styled(BaseButton)({
  backgroundColor: ({ theme }) => theme.btn.warningEmpty.backgroundColor,
  color: ({ theme }) => theme.btn.warningEmpty.txtColor,
  border: ({ theme }) => theme.btn.warningEmpty.border
});

export const BackButton = styled(BaseButton)({
  backgroundColor: ({ theme }) => theme.white,
  color: ({ theme }) => theme.greenyBlue,
  border: '1px solid',
  borderColor: ({ theme }) => theme.greenyBlue,
  borderRadius: '0',
  '&:hover': {
    backgroundColor: ({ theme }) => theme.hover,
    color: ({ theme }) => theme.inputLabel
  }
});

export const DeleteButton = styled(MainButton)({
  backgroundColor: ({ theme }) => theme.purplishRed04,
  borderColor: ({ theme }) => theme.purplishRed,
  color: ({ theme }) => theme.white,
  boxShadow: '2px 2px 0 0 #b2095a',
  '&:disabled': {
    background: ({ theme }) => theme.white,
    borderColor: ({ theme }) => theme.pinkishGrey,
    cursor: 'not-allowed',
    color: ({ theme }) => theme.pinkishGrey,
    boxShadow: '2px 2px 0 0 #d0d0d0'
  },
  '&:hover:not(:disabled):not(:active)': {
    backgroundColor: ({ theme }) => theme.purplishRed
  },
  '&:active': {
    outlineColor: ({ theme }) => theme.purplishRed,
    borderColor: 'black',
    backgroundColor: ({ theme }) => theme.purplishRed04
  }
});

export const BlockButton = styled(BaseButton)({
  border: '1px solid',
  backgroundColor: 'transparent',
  borderColor: ({ theme }) => theme.pastelRed,
  color: ({ theme }) => theme.pastelRed,
  '&:hover': {
    backgroundColor: ({ theme }) => theme.hoverDelete,
    color: 'white'
  }
});

export const UnblockButton = styled(BaseButton)({
  border: '1px solid',
  borderColor: ({ theme }) => theme.mossyGreen,
  backgroundColor: ({ theme }) => theme.mossyGreen,
  color: 'white',
  '&:hover': {
    backgroundColor: ({ theme }) => theme.hoverUnblock
  }
});

export const CancelButton = styled(BackButton)({
  background: 'transparent',
  border: '1px solid',
  borderColor: ({ theme }) => theme.textDefault,
  color: ({ theme }) => theme.textDefault
});

export const TableDeleteButton = styled(BlockButton)({
  border: '0',
  padding: '0',
  height: 'auto',
  boxShadow: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const InTableConfirmButton = styled('button')({
  border: '0',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  color: '##5ca1b2',
  padding: '.5em 1em',
  borderRadius: '2px',
  outline: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  '&:active': {
    color: 'black',
    textDecoration: 'underline'
  }
});

export const InTableRejectButton = styled('button')({
  border: '0',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  color: '#CD3A47',
  padding: '.5em 1em',
  borderRadius: '2px',
  outline: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  '&:active': {
    color: 'black',
    textDecoration: 'underline'
  }
});

// -----------------------popup---------------------------------------
export const PopupStyle = {
  backgroundColor: ({ theme }) => theme.popup.backgroundColor,
  padding: '15px 5px 10px 5px',
  width: '600px',
  height: '200px',
  boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.5)',
  borderRadius: '5px'
};

export const PopupOverlayStyle = {
  backgroundColor: ({ theme }) => theme.popup.overlayBackgroundColor,
  padding: '10px'
};

// -----------------------text---------------------------------------
export const ErrorText = styled('div')({
  color: ({ theme }) => theme.error,
  marginTop: '10px',
  minHeight: '22px',
  paddingLeft: '5px',
  width: '100%',
  fontSize: '14px'
});

export const H3 = styled('h3')({
  color: ({ theme }) => theme.textDefault
});

export const H1 = styled('h1')({
  fontSize: '35px',
  margin: '34px 0 20px 0',
  color: ({ theme }) => theme.textDefault
});

export const FormTitle = styled('h1')({
  fontSize: '35px',
  padding: '0',
  margin: '0 0 20px 0',
  color: ({ theme }) => theme.charcoalGrey
});

export const InlineDataLabel = styled('span')({
  fontSize: '16px',
  display: 'inline-block',
  fontWeight: '300',
  textAlign: 'right'
});

export const InlineDataValue = styled('span')({
  fontSize: '16px',
  display: 'inline-block',
  fontWeight: '400'
});

export const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
});

export const WebsiteTitle = styled(H1)({
  color: 'black',
  fontWeight: 'bold',
  cursor: 'pointer',
  width: '248px',
  margin: '0',
  textAlign: 'right',
  marginRight: '10px'
});

// -----------------------select---------------------------------------
export const SelectContainer = styled('div')({
  minWidth: '120px',
  maxWidth: '450px',
  position: 'relative',
  fontSize: '16px',
  fill: ({ theme }) => theme.textDefault,
  '&:focus-within': {
    zIndex: '6'
  },
  '& .select__placeholder': {
    marginLeft: '2px'
  },
  '& .select--is-disabled': {
    cursor: 'not-allowed',
    '& svg': {
      color: ({ theme }) => theme.select.simpleSelect.disabled.color,
      fill: ({ theme }) => theme.select.simpleSelect.disabled.fill
    },
    '& .select__control--is-disabled': {
      cursor: 'not-allowed',
      borderColor: ({ theme }) => theme.select.simpleSelect.focus.borderColor,
      '& .select__value-container': {
        cursor: 'not-allowed'
      },
      '& .select__placeholder': {
        color: ({ theme }) =>
          theme.select.simpleSelect.disabled.placeholderColor,
        cursor: 'not-allowed',
        marginLeft: '2px'
      }
    }
  },
  '& .select__indicators': {
    height: '28px',
    marginTop: '1px',
    '& .select__dropdown-indicator': {
      height: '28px',
      padding: '0'
    }
  },
  '& .select__value-container': {
    padding: '0 4px 4px 7px',
    minHeight: '28px',
    '& .select__single-value': {
      margin: '0 5px 3px 0'
    }
  },
  '& .select__value-container.select__value-container--has-value': {
    padding: '0 4px 2px 0',
    '& .select__single-value': {
      margin: '0 5px 2px 4px',
      top: '11px'
    }
  },
  '& .select__dropdown-indicator': {
    position: 'relative'
  },
  '& .select': {
    margin: '0'
  },
  '& svg:first-of-type': {
    fill: ({ theme }) => theme.primaryColor
  },
  '& svg:last-of-type': {
    position: 'absolute',
    visibility: 'hidden',
    fill: 'black'
  },
  '& .select__control:hover svg:first-of-type': {
    visibility: 'hidden'
  },
  '& .select__control:active svg:first-of-type': {
    visibility: 'visible !important'
  },
  '& .select__control:hover svg:last-of-type': {
    visibility: 'visible'
  },
  '& .select__control:active svg:last-of-type': {
    visibility: 'hidden !important'
  },
  '& .select__control': {
    border: '2px solid',
    height: '28px',
    minHeight: '28px',
    borderRadius: '0',
    borderColor: ({ theme }) => theme.primaryColor,
    color: ({ theme }) => theme.textDefault,
    outline: '0 !important',
    boxShadow: 'none',
    minWidth: '120px',
    width: '100%',
    maxWidth: '450px'
  },
  '& .select__control:hover': {
    borderColor: ({ theme }) => theme.primaryColor,
    backgroundColor: ({ theme }) => theme.primaryColor,
    outline: '0'
  },
  '& .select__control:active': {
    borderColor: 'black',
    outline: '0',
    '& svg': {
      fill: 'black'
    }
  },
  '& .select__control:focus': {
    border: '1px solid',
    outline: '0'
  },
  '& .select__control--is-focused': {
    borderColor: 'black',
    boxShadow: 'inset 0 1px 4px 0 #c3e8ff',
    color: ({ theme }) => theme.select.simpleSelect.focus.color
  },
  '& .select__indicator-separator': {
    display: 'none'
  },
  '& .select__option': {
    color: 'black',
    cursor: 'pointer',
    padding: '0 4px',
    position: 'relative',
    zIndex: '99',
    margin: '0 4px 2px 4px',
    width: '95%',
    height: '26px',
    lineHeight: '26px',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    backgroundColor: 'transparent'
  },
  '& .select__option:hover': {
    backgroundColor: ({ theme }) => theme.hover,
    borderColor: ({ theme }) => theme.hover,
    fill: ({ theme }) => theme.select.simpleSelect.hover.fill
  },
  '& .select__option:active': {
    backgroundColor: 'transparent !important',
    outline: ({ theme }) => theme.primaryColor,
    outlineOffest: '1px',
    borderColor: 'black'
  },
  '& .select__option--is-focused': {
    borderColor: 'black'
  },
  '& .select__menu': {
    margin: '0',
    minWidth: '120px',
    maxHeight: '135px',
    width: '100%',
    zIndex: '99',
    maxWidth: '450px',
    borderRadius: '0'
  },
  '& .select__menu-list': {
    maxHeight: '135px'
  },
  display: props => props.display
});

// ------------------------global-------------------------------------

export const Title = styled('h1')({
  margin: '24px 0 0 0'
});

export const CenteredContainerVertical = styled('div')({
  width: '100%',
  maxWidth: '1420px',
  height: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative'
});

export const Container = styled('div')({
  width: '1420px',
  height: '100%',
  margin: '0 auto',
  padding: '0 10px',
  boxSizing: 'border-box'
});

export const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexGrow: '1',
  background: ({ theme }) => theme.mainBackground,
  '& .popup-content': {
    borderRadius: '0 !important'
  }
});

// -----------------------input---------------------------------------
export const BaseLabel = styled('label')({
  textAlign: 'left',
  fontSize: '18px',
  fontWeight: '300',
  height: '22px',
  display: 'block',
  transition: 'all .3s'
});

export const InputOutline = styled('div')({
  border: '1px solid transparent',
  borderBottom: '0',
  '&:focus-within': {
    borderColor: ({ theme }) => theme.lightBlue30
  }
});

export const BaseHtmlInput = styled('input')({
  fontSize: '14px',
  fontFamily: 'inherit',
  padding: '9px 5px 3px 3px',
  height: '28px',
  backgroundColor: 'white',
  minWidth: '240px',
  width: '100%',
  display: 'block',
  boxSizing: 'border-box',
  borderRadius: '0',
  border: '0',
  borderBottom: '1px solid',
  borderBottomColor: '#c3e8ff',
  outline: '0',
  '&:active:not(:disabled)': {
    borderBottomColor: '#434345'
  },
  '&:focus:not(:active):not(:disabled)': {
    borderBottomColor: '#434345'
  },
  '&:hover:not(:focus):not(:disabled)': {
    '-webkitBoxShadow': '0 0 0 30px #c3e8ff inset'
  },
  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: ({ theme }) => theme.pinkishGrey,
    color: ({ theme }) => theme.pinkishGrey
  }
});

export const BorderedHtmlInput = styled('input')({
  fontSize: '18px',
  fontFamily: 'inherit',
  padding: '10px 5px 6px 25px',
  fontWeight: '300',
  height: '41px',
  borderRadius: '5px',
  backgroundColor: 'white',
  minWidth: '240px',
  display: 'block',
  boxSizing: 'border-box',
  transition: 'all .1s',
  border: '1px solid',
  borderColor: ({ theme }) => theme.black75,
  outline: 'none'
});
