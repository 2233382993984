import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';
import PropTypes from 'prop-types';
import {
  MainButton,
  SecondaryButton,
  Container,
  ButtonGroup,
  TableDeleteButton,
  CancelButton,
  WarningButton,
  H1
} from 'shared/styles';

import { currency } from 'utils/dictionaries';

// redux auth
import { getUserId, getRole } from 'redux/modules/auth/selectors';

// redux worker
import { getWorker } from 'redux/modules/worker/selectors';
import { getWorkerAction } from 'redux/modules/worker/actions';

// redux worktime
import { getWorkerTimesAdmin } from 'redux/modules/worktime/selectors';
import {
  deleteWorkTimeAction,
  getWorkerTimesAdminAction
} from 'redux/modules/worktime/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import Loading from 'shared/components/Loading';
import {
  actionType,
  matchType,
  workerTimesType,
  workerType
} from 'shared/types';
import Table from 'shared/components/Table';

const TopContainer = styled('div')({
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  backgroundColor: 'white',
  padding: '30px 45px 20px 25px',
  marginBottom: '20px'
});

const SpreadButtonGroup = styled(ButtonGroup)({
  justifyContent: 'space-between'
});

const StyledCancelButton = styled(CancelButton)({
  fontWeight: '500',
  marginRight: '45px'
});

const SubmitWarningButton = styled(WarningButton)({
  fontWeight: '500'
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#5ca1b2',
  boxShadow: 'none',
  '&:hover': {
    color: '#2c5d68'
  },
  '&:active': {
    color: '#b22929'
  }
});

const TableLink = styled(Link)({
  textDecoration: 'none',
  color: '#5ca1b2',
  '&:hover': {
    textDecoration: 'underline',
    color: '#2c5d68'
  },
  '&:active': {
    color: '#b22929'
  }
});

const Modal = styled('div')({
  fontSize: '14px',
  width: '600px !important'
});

const Content = styled('div')({
  width: '100%',
  padding: '50px 5px 0 5px',
  boxSizing: 'border-box',
  color: 'black',
  fontSize: '24px',
  textAlign: 'center'
});

const ButtonsContainer = styled(ButtonGroup)({
  margin: '43px 0 56px 0 !important'
});

const DeleteButton = ({
  user,
  children,
  onClickAction,
  className,
  t,
  ...props
}) => (
  <Popup
    trigger={<TableDeleteButton type="button"> {children} </TableDeleteButton>}
    contentStyle={{ width: '600px' }}
    modal
    closeOnDocumentClick
  >
    {close => (
      <Modal>
        {/* <div className="header">{t('workTimes:Caution')}</div> */}
        <Content>
          {t('workTimes:DeleteWorkTimeQuestion')} {user}
        </Content>
        <ButtonsContainer>
          <StyledCancelButton
            type="button"
            onClick={() => {
              close();
            }}
          >
            {t('general:Cancel')}
          </StyledCancelButton>
          <SubmitWarningButton
            type="button"
            onClick={() => onClickAction(close)}
            {...props}
          >
            {children}
          </SubmitWarningButton>
        </ButtonsContainer>
      </Modal>
    )}
  </Popup>
);

DeleteButton.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onClickAction: PropTypes.func.isRequired,
  className: PropTypes.shape({}).isRequired,
  user: PropTypes.string.isRequired
};

class WorkerTimes extends Component {
  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    await this.props.getWorker(id);
  }

  // eslint-disable-next-line no-unused-vars
  fetchData = async (state, instance) => {
    const { page, pageSize } = state;
    await this.props.getWorkerTimesAdmin({
      workerId: this.props.worker.idPerson,
      page,
      pageSize
    });
  };

  refresh = async () => {
    await this.props.getWorkerTimesAdmin({
      workerId: this.props.worker.idPerson,
      page: 0,
      pageSize: 10
    });
  };

  deleteWorkerTime = async (idWorkTime, close, refresh) => {
    await this.props.deleteWorkTime(idWorkTime);
    if (!this.props.errorPopup) {
      close();
      await refresh();
    }
  };

  getTrProps = (state, row) => {
    if (row) {
      return {
        style: {
          fontWeight: row.original.isPpk && 'bold'
        }
      };
    }
    return {};
  };

  render() {
    const { t, worker, workerTimes, isFetching, isFetchingTable } = this.props;
    const columns = [
      {
        Header: t('workTimes:Year'),
        accessor: 'year'
      },
      {
        Header: t('workTimes:Month'),
        accessor: 'monthName',
        Cell: row => t(`months:${row.value}`)
      },
      {
        Header: t('workTimes:Hours'),
        accessor: 'timeValue'
      },
      {
        Header: t('workTimes:HourlyRate'),
        accessor: 'salaryPerHour'
      },
      {
        Header: t('workTimes:Salary'),
        accessor: 'salaryPerHour',
        Cell: row => (
          <div>
            <span title={row.original.isPpk && t('workTimes:reduceByPpk')}>
              {row.value * row.original.timeValue}
            </span>
          </div>
        )
      },
      {
        Header: t('workTimes:Currency'),
        accessor: 'idCurrency',
        Cell: row => {
          const cur = currency.find(c => c.value === row.value);
          return cur ? cur.label : '';
        }
      },
      {
        Header: t('workTimes:Status'),
        accessor: 'status.statusName',
        Cell: row => t(`workTimeStatuses:${row.value}`)
      },
      {
        Header: t('workTimes:ProjectName'),
        accessor: 'projectName'
      },
      {
        // Header: t('workTimes:Edit'),
        accessor: 'idWorkTime',
        show: this.props.role === 'Administrator',
        Cell: row =>
          row.original.status.idStatus !== 2 && (
            <TableLink
              to={{
                pathname: `/worker-times/${this.props.match.params.id}/Edit/${
                  row.value
                }`
              }}
            >
              {t('workTimes:Edit')}
            </TableLink>
          )
      },
      {
        // Header: t('workTimes:Delete'),
        sortable: false,
        multiSort: false,
        accessor: 'idWorkTime',
        show: this.props.role === 'Administrator',
        Cell: row => {
          if (!row.original.status.canDeleteWorkTime) {
            return <div />;
          }
          return (
            <DeleteButton
              onClickAction={close => {
                this.deleteWorkerTime(
                  row.original.idWorkTime,
                  close,
                  this.refresh
                );
              }}
              type="button"
              className="blockButton"
              t={t}
            >
              {t('workTimes:Delete')}
            </DeleteButton>
          );
        }
      }
    ];

    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }

    return (
      <Container>
        <H1>
          {t('workTimes:ListOfWorkingHours')} - {worker.firstName}{' '}
          {worker.lastName}
        </H1>
        <TopContainer>
          <SpreadButtonGroup>
            <StyledLink
              to={{ pathname: `/worker/${this.props.match.params.id}/profile` }}
            >
              <SecondaryButton>{t('general:Back')}</SecondaryButton>
            </StyledLink>
            {this.props.role === 'Administrator' && (
              <StyledLink
                to={{
                  pathname: `/worker-times/${this.props.match.params.id}/add`
                }}
              >
                <MainButton>{t('workTimes:AddWorkTime')}</MainButton>
              </StyledLink>
            )}
          </SpreadButtonGroup>
        </TopContainer>

        <Table
          style={{
            position: 'relative'
          }}
          manual
          getTrProps={this.getTrProps}
          data={workerTimes.workTimes}
          columns={columns}
          defaultPageSize={10}
          pages={workerTimes.pageCount}
          onFetchData={this.fetchData}
          sortable={false}
          loading={isFetchingTable}
        />
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector(['worktime/worker/GET_WORKER']);

const loadingTableSelector = createLoadingSelector([
  'worktime/worktime/GET_WORKER_TIMES_ADMIN'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKER_TIMES_ADMIN'
]);

const loadingPopupSelector = createLoadingSelector([
  'worktime/worktime/DELETE_WORKTIME'
]);

const errorPopupSelector = createErrorMessageSelector([
  'worktime/worktime/DELETE_WORKTIME'
]);

WorkerTimes.propTypes = {
  match: matchType.isRequired,
  deleteWorkTime: actionType.isRequired,
  getWorkerTimesAdmin: actionType.isRequired,
  getWorker: actionType.isRequired,
  worker: workerType.isRequired,
  errorPopup: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  workerTimes: PropTypes.shape({
    workerTimes: workerTimesType,
    pageCount: PropTypes.number
  }).isRequired,
  isFetching: PropTypes.bool,
  isFetchingTable: PropTypes.bool,
  role: PropTypes.string.isRequired
};

WorkerTimes.defaultProps = {
  errorPopup: null,
  isFetching: false,
  isFetchingTable: false
};

function mapStateToProps(state) {
  return {
    userId: getUserId(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    isFetchingPopup: loadingPopupSelector(state),
    errorPopup: errorPopupSelector(state),
    worker: getWorker(state),
    workerTimes: getWorkerTimesAdmin(state),
    isFetchingTable: loadingTableSelector(state),
    role: getRole(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      deleteWorkTime: deleteWorkTimeAction,
      getWorkerTimesAdmin: getWorkerTimesAdminAction,
      getWorker: getWorkerAction
    }
  )(WorkerTimes)
);
