import moment from 'moment-timezone';

export const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const toLocalDateTime = date =>
  moment
    .utc(date)
    .local()
    .format('DD/MM/YYYY HH:mm');

export const toWarsawTz = date =>
  moment
    .utc(date)
    .tz('Europe/Warsaw')
    .format('DD/MM/YYYY HH:mm');

export const toIsoDateTime = date =>
  moment.utc(date, 'DD/MM/YYYY HH:mm').toISOString();

export const extractMonthYear = date => moment.utc(date).format('MM/YYYY');

export const createParamsForFilterAndSort = (
  page,
  pageSize,
  filtered,
  sorted
) => {
  const params = {
    page,
    pageSize,
    sorted: '',
    search: '',
    statuses: ''
  };

  // params.search = filtered.filter((obj) => obj.id === "search").

  params.search = filtered[0].value;
  params.statuses = filtered[1].value;

  /*  if (filtered.length === 1) {
    params.filtered = filtered[0].value;
  } else if (filtered.length === 2) {
    params.filtered = filtered[0].value;
    params.IdsMainFilter = filtered[1].value || "";
  } */
  if (sorted.length > 0) {
    params.sorted = `${sorted[0].id} ${sorted[0].desc ? ' desc' : ' asc'}`;
  }
  return params;
};
