import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'config/intl';
import themes from 'config/themes';
import Provider from 'react-redux/es/components/Provider';
import { ThemeProvider } from 'react-jss';
import store from 'redux/store';
import App from 'screens/App';
import { basename } from 'utils/dictionaries';
import storage from 'redux/utis/storageFactory';

import 'react-table/react-table.css';
import './index.css';

/* eslint-disable */

const initApp = params => {
  if (params) {
    window.pdfBody = params;
    storage.setItem('token', params.token);
    storage.setItem('role', params.role);
    storage.setItem('language', +params.language);
  }
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store} key="provider">
        <ThemeProvider theme={themes.light}>
          <Router basename={basename}>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>,
    document.getElementById('app')
  );
};

window.initApp = initApp;

if (!navigator.webdriver) {
  initApp();
}
