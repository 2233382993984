import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import { LineDotHorizontal } from 'shared/components/LineDots';

const Container = styled('div')({
  positon: 'relative'
});

const Underline = styled('div')({
  position: 'absolute',
  top: '35px',
  left: '3px'
});

const TableHead = ({ toggleSort, className, children, ...rest }) => (
  <Container
    className={`rt-thead ${className}`}
    onClick={e => toggleSort && toggleSort(e)}
    onKeyDown={e => toggleSort && toggleSort(e)}
    role="columnheader"
    tabIndex="-1"
    {...rest}
  >
    {children}
    <Underline>
      <LineDotHorizontal count={45} width={650} />
    </Underline>
  </Container>
);

TableHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string.isRequired,
  toggleSort: PropTypes.func
};

TableHead.defaultProps = {
  toggleSort: null,
  children: null
};

export default TableHead;
