import { requestActions, createRequestTypes } from 'redux/helpers';

export const GET_WORKER = createRequestTypes(
  'worktime/worker',
  'GET_WORKER',
  requestActions
);

export const GET_WORKERS = createRequestTypes(
  'worktime/worker',
  'GET_WORKERS',
  requestActions
);

export const BLOCK_WORKER = createRequestTypes(
  'worktime/worker',
  'BLOCK_WORKER',
  requestActions
);

export const UNBLOCK_WORKER = createRequestTypes(
  'worktime/worker',
  'UNBLOCK_WORKER',
  requestActions
);

export const DELETE_WORKER = createRequestTypes(
  'worktime/worker',
  'DELETE_WORKER',
  requestActions
);

export const ADD_WORKER = createRequestTypes(
  'worktime/worker',
  'ADD_WORKER',
  requestActions
);

export const EDIT_WORKER = createRequestTypes(
  'worktime/worker',
  'EDIT_WORKER',
  requestActions
);

export const EMAIL_HISTORY_WORKER = createRequestTypes(
  'worktime/worker',
  'EMAIL_HISTORY_WORKER',
  requestActions
);
export const RESEND_EMAIL = createRequestTypes(
  'worktime/worker',
  'RESEND_EMAIL',
  requestActions
);
