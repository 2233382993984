import styled from 'styled-jss';
import React from 'react';
import PropTypes from 'prop-types';

const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center'
});

const Error = ({ error: { status, message } }) => (
  <Container>
    <h1>{`${message} ${status}`}</h1>
  </Container>
);

Error.propTypes = {
  error: PropTypes.shape({}).isRequired
};

export default Error;
