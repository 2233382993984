export const getWorkerTimesUser = ({ worktime }) => worktime.worktimesUser;

export const getWorkerTimesAdmin = ({ worktime }) => worktime.worktimesAdmin;

export const getWorktime = ({ worktime }) => worktime.worktime;

export const disableField = calcType => {
  switch (calcType) {
    case 1:
      return 'salary';
    case 2:
      return 'salaryPerHour';
    case 3:
      return 'timeValue';
    default:
      return 'salary';
  }
};
