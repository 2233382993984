/* eslint-disable */

import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import storage from 'redux/utis/storageFactory';
import { languages } from 'utils/dictionaries';

import mockedData from './components/mockedData';
import Header from './components/Header';

const TrHeader = styled('tr')({
  height: '50px'
});

const Td = styled('td')({
  textAlign: 'center'
});

const EmpHeader = styled('th')({
  fontWeight: '600',
  fontSize: '16px',
  borderBottom: '1px solid black',
  paddingBottom: '5px',
  height: '50px'
});

const OuterTbody = styled('tbody')({
  border: '2px solid rgba(0, 0, 0, 0.67)',
  boxDizing: ' border-box',
  height: '1160px',
  padding: '0 20px'
});

const OuterTable = styled('table')({
  width: 'calc(100% - 40px)',
  margin: '20px'
});

const Pdf = ({ t, i18n }) => {
  useEffect(() => {
    i18n.changeLanguage(
      storage.getItem('language')
        ? languages.find(lng => +lng.value === +storage.getItem('language'))
            .shortName
        : 'en-US'
    );
  }, []);

  const pdfData = window.pdfBody ? window.pdfBody : mockedData;
  return (
    <OuterTable>
      <thead>
        <tr>
          <th>
            <Header data={pdfData} />
          </th>
        </tr>
      </thead>
      <OuterTbody>
        <tr>
          <Td>
            {pdfData.data.map((obj, index) => (
              <table key={index}>
                <thead>
                  <tr>
                    <EmpHeader colSpan={pdfData.columns.length}>
                      <div>{`${t('pdf:Employee')} #${(index += 1)}`}</div>
                      <div>{obj.name}</div>
                    </EmpHeader>
                  </tr>
                  <TrHeader>
                    {pdfData.columns.map(column => (
                      <Td>{t(`pdf:${column.value}`)}</Td>
                    ))}
                  </TrHeader>
                </thead>
                <tbody>
                  {obj.values.map(values => (
                    <tr>
                      {pdfData.columns.map(column => (
                        <Td>{values[column.value]}</Td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </Td>
        </tr>
      </OuterTbody>
    </OuterTable>
  );
};

Pdf.propTypes = {
  t: PropTypes.func.isRequired
};

export default withNamespaces()(Pdf);
