import React from 'react';

const IconMDatePicker = props => (
  <svg width={20} height={20} {...props}>
    <path d="M3 6v10h14V6H3zm13-1h2v12H2V5h2V3h2v2h8V3h2v2z" />
    <path d="M10 14V8.466l-1.315 1.41-.731-.682L10 7h1v7h1.5v1h-4v-1z" />
  </svg>
);

export default IconMDatePicker;
