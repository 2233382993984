import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import { tType } from 'shared/types';
import Input from 'shared/Fields/Input';
import { requiredField, passwordRules } from 'utils/fields/validation';
import { MainButton, CenteredContainerVertical } from 'shared/styles';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import Loading from 'shared/components/Loading';

const StyledButton = styled(MainButton)({
  margin: '50px -15px 0 0'
});

const ButtonContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
});

const StyledForm = styled('form')({
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '0 1rem 2rem rgba(black, .1)',
  padding: '5rem',
  ':not(:last-child)': {
    marginBottom: '4rem'
  }
});

const passwordValidation = values => {
  const errors = {};
  if (values.password !== values.confirmPassword) {
    errors.password = 'validation:DiffPasswords';
    errors.confirmPassword = 'validation:DiffPasswords';
  }
  return errors;
};

const RegisterInput = ({ handleSubmit, t, errorMessage, submitting }) => (
  <CenteredContainerVertical>
    {submitting && <Loading text={t('general:PleaseWait')} />}
    <StyledForm onSubmit={handleSubmit}>
      <Field
        id="password"
        component={Input}
        name="password"
        validate={[requiredField, passwordRules]}
        type="password"
        label={t('register:Password')}
        t={t}
      />
      <Field
        id="confirmPassword"
        component={Input}
        name="confirmPassword"
        validate={[requiredField]}
        type="password"
        label={t('register:RepeatPassword')}
        t={t}
        styleLabel={{ marginTop: '82px' }}
      />
      <ButtonContainer>
        <StyledButton type="submit">{t('register:Save')}</StyledButton>
      </ButtonContainer>
    </StyledForm>

    <ErrorMessageForm error={errorMessage} />
  </CenteredContainerVertical>
);

RegisterInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired,
  errorMessage: PropTypes.string,
  submitting: PropTypes.bool.isRequired
};

RegisterInput.defaultProps = {
  errorMessage: null
};

export default reduxForm({
  form: 'registerInputForm',
  validate: passwordValidation
})(withNamespaces(['register'])(RegisterInput));
