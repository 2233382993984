export const getUserId = ({ auth }) => auth.userId;

export const getRole = () => window.localStorage.getItem('role');

export const isAuthenticated = () => !!window.localStorage.getItem('token');

export const getToken = ({ auth }) => auth.token;

export const getLanguage = ({ auth }) => auth.language;
export const getFullname = ({ auth }) => auth.fullname;
