import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MainContainer } from 'shared/styles';
import requireAuth from 'utils/requireAuth';
import routes from 'config/routes';

import Helmet from './Helmet';
import Toast from '../shared/components/Toast';

const App = () => (
  <Fragment>
    <Toast />
    <Helmet />

    <MainContainer>
      <Route path={routes.navbar.path} component={routes.navbar.component} />
      <Switch>
        <Route path="/" exact component={routes.login.component} />
        <Route
          path={routes.sessionExpired.path}
          exact
          component={routes.sessionExpired.component}
        />
        <Route path={routes.login.path} component={routes.login.component} />
        <Route
          path={routes.register.path}
          component={routes.register.component}
        />
        <Route
          path={routes.sendMail.path}
          component={routes.sendMail.component}
        />
        <Route
          path={routes.changePassword.path}
          component={routes.changePassword.component}
        />
        <Route
          exact
          path={routes.workers.path}
          component={requireAuth(routes.workers.component, [
            'Administrator',
            'Rachunkowosc'
          ])}
        />

        <Route
          exact
          path={routes.workers.child_route.report.path}
          component={requireAuth(routes.workers.child_route.report.component, [
            'Administrator',
            'Rachunkowosc'
          ])}
        />
        <Route
          exact
          path={routes.workers.child_route.pdf.path}
          component={requireAuth(routes.workers.child_route.pdf.component, [
            'Administrator'
          ])}
        />
        <Route
          path={routes.workerTimes.child_route.add.path}
          component={requireAuth(routes.workerTimes.child_route.add.component, [
            'Administrator'
          ])}
        />
        <Route
          path={routes.workerTimes.child_route.edit.path}
          component={requireAuth(
            routes.workerTimes.child_route.edit.component,
            ['Administrator']
          )}
        />
        <Route
          path={routes.workerTimes.path}
          component={requireAuth(routes.workerTimes.component, [
            'Administrator',
            'Rachunkowosc'
          ])}
        />
        <Route
          exact
          path={routes.worker.child_route.profile.path}
          component={requireAuth(routes.worker.child_route.profile.component, [
            'Administrator'
          ])}
        />
        <Route
          exact
          path={routes.worker.child_route.add.path}
          component={requireAuth(routes.worker.child_route.add.component, [
            'Administrator'
          ])}
        />
        <Route
          exact
          path={routes.worker.child_route.edit.path}
          component={requireAuth(routes.worker.child_route.edit.component, [
            'Administrator'
          ])}
        />

        <Route
          exact
          path={routes.workTimes.path}
          component={requireAuth(routes.workTimes.component, ['Pracownik'])}
        />
        <Route
          exact
          path={routes.settings.path}
          component={requireAuth(routes.settings.component, ['Administrator'])}
        />
        <Route
          exact
          path={routes.settings.child_route.changePassword.path}
          component={requireAuth(
            routes.settings.child_route.changePassword.component,
            ['Administrator', 'Pracownik']
          )}
        />

        <Route render={() => <h1 className="text-center">404</h1>} />
      </Switch>
    </MainContainer>
  </Fragment>
);
export default App;
