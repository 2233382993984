import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const RenderHelmet = ({ t }) => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>{t('Title')}</title>
    <link rel="manifest" href="manifest.json" />
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="48x48" href="favicon.ico" />
    <link rel="icon" type="image/png" sizes="32x32" href="favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="favicon-16x16.png" />
    <link rel="mask-icon" href="safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#ffc40d" />
    <meta name="theme-color" content="#ffffff" />
    <link
      rel="stylesheet"
      href="https://unpkg.com/purecss@1.0.0/build/pure-min.css"
      integrity="sha384-nn4HPE8lTHyVtfCBi5yW9d20FjT8BJwUXyWZT9InLYax14RDjBj46LmSztkmNP9w"
      crossOrigin="anonymous"
    />
  </Helmet>
);

RenderHelmet.propTypes = {
  t: PropTypes.func.isRequired
};

export default withNamespaces('general')(RenderHelmet);
