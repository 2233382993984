import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { metaType } from 'shared/types';
import styled from 'styled-jss';
import { SelectContainer, BaseLabel } from 'shared/styles';
import SimpleSelect from 'shared/components/SimpleSelect';
import FieldError from './FieldError';

const InnerSelectContainer = styled('div')({
  // display: 'table',
  // width: '100%',
  maxWidth: '450px',
  minWidth: '160px',
  fontFamily: 'inherit',
  paddingTop: '12px',
  backgroundColor: 'rgba(#fff, .5)',
  display: 'block'
});

const Label = styled(BaseLabel)(({ theme }) => ({
  color: theme.inputLabel
}));

const Select = ({
  t,
  input,
  options,
  label,
  meta,
  onChangeSelect,
  defaultOption,
  placeholder,
  display,
  style,
  styleLabel,
  ...props
}) => (
  // const allOptions = defaultOption && !options.find(({ value }) => !value)
  //  ? [{ label: defaultOption, value: 0 }, ...options]
  //  : options;
  <SelectContainer display={display} style={style}>
    <Label isNotEmpty style={styleLabel}>
      {label && label}
    </Label>
    <InnerSelectContainer>
      <SimpleSelect
        value={options.find(elem => elem.value === input.value) || 0}
        onChange={newValue => {
          input.onChange(newValue.value);
          onChangeSelect(newValue.value);
        }}
        onBlur={() => input.onBlur(input.value)}
        tabSelectsValue={false}
        options={options}
        isMulti={false}
        placeholder={placeholder}
        {...props}
      />
    </InnerSelectContainer>
    <FieldError meta={meta} />
  </SelectContainer>
);
Select.propTypes = {
  input: PropTypes.shape({}).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({})
  ]).isRequired,
  label: PropTypes.string,
  meta: metaType.isRequired,
  onChangeSelect: PropTypes.func,
  defaultOption: PropTypes.string,
  placeholder: PropTypes.string,
  display: PropTypes.string,
  style: PropTypes.shape({}),
  styleLabel: PropTypes.shape({}),
  t: PropTypes.func.isRequired
};

Select.defaultProps = {
  label: null,
  style: null,
  styleLabel: null,
  onChangeSelect: () => {},
  defaultOption: null,
  placeholder: null,
  display: ''
};

export default withNamespaces()(Select);
