import React, { PureComponent } from 'react';
import queryString from 'query-string';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { H1, H3 } from 'shared/styles';
import { getPerson } from 'redux/modules/account/selectors';
import {
  setPasswordAction,
  getPersonAction
} from 'redux/modules/account/actions';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import Loading from 'shared/components/Loading';
import {
  actionType,
  errorMessageType,
  locationType,
  tType,
  workerType
} from 'shared/types';
import routes from 'config/routes';
import RegisterInput from './components/RegisterInput';

class Register extends PureComponent {
  state = {
    uid: ''
  };

  async componentDidMount() {
    const { id, uid } = queryString.parse(this.props.location.search);
    await this.props.getPerson(id, uid);
    this.setState({ uid });
  }

  handleSubmit = async values => {
    const { worker, setPassword, history } = this.props;
    const { uid } = this.state;
    const data = {
      idPerson: worker.idPerson,
      password: values.password,
      uid
    };
    await setPassword(data);
    if (!this.props.error) {
      history.push(routes.login.path);
    }
  };

  render() {
    const { t, isFetching, worker, error } = this.props;

    if (isFetching) return <Loading text={t('general:PleaseWait')} />;

    return (
      <React.Fragment>
        <H1>{worker.email}</H1>
        <H3>{t('register:Activate')}</H3>
        <RegisterInput onSubmit={this.handleSubmit} errorMessage={error} />
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector(['worktime/account/GET_PERSON']);

const errorSelector = createErrorMessageSelector([
  'worktime/account/SET_PASSWORD',
  'worktime/account/GET_PERSON'
]);

Register.propTypes = {
  setPassword: actionType.isRequired,
  getPerson: actionType.isRequired,
  location: locationType.isRequired,
  error: errorMessageType,
  isFetching: PropTypes.bool.isRequired,
  t: tType.isRequired,
  worker: workerType.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

Register.defaultProps = {
  error: null
};

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    worker: getPerson(state)
  };
}

export default connect(
  mapStateToProps,
  {
    setPassword: setPasswordAction,
    getPerson: getPersonAction
  }
)(withNamespaces()(Register));
