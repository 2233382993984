import * as types from './types';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case types.GET_SETTINGS.SUCCESS:
      return {
        ...state,
        monthWorkTime: action.payload.monthWorkTime,
        pageSize: action.payload.numberOfRows
      };
    case types.CHANGE_LANGUAGE.SUCCESS:
      return {
        ...state,
        language: action.payload
      };
    default:
      return state;
  }
}
