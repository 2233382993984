import {
  createDefaultApiAction,
  createDefaultApiRequestAction
} from 'redux/helpers';
import {
  getWorker,
  blockWorker,
  unblockWorker,
  deleteWorker,
  getWorkers,
  editWorker,
  addWorker,
  emailHistoryWorker,
  resendEmail
} from 'utils/api/worker';
import * as types from './types';

export const getWorkerAction = id =>
  createDefaultApiAction(types.GET_WORKER, getWorker, id);

export const getWorkerRequestAction = () =>
  createDefaultApiRequestAction(types.GET_WORKER);

export const getWorkersAction = statuses =>
  createDefaultApiAction(types.GET_WORKERS, getWorkers, statuses);

export const blockWorkerAction = idPerson =>
  createDefaultApiAction(types.BLOCK_WORKER, blockWorker, idPerson);

export const deleteWorkerAction = idPerson =>
  createDefaultApiAction(types.DELETE_WORKER, deleteWorker, idPerson);

export const unblockWorkerAction = idPerson =>
  createDefaultApiAction(types.UNBLOCK_WORKER, unblockWorker, idPerson);

export const addWorkerAction = data =>
  createDefaultApiAction(types.ADD_WORKER, addWorker, data);

export const addWorkerRequestAction = () =>
  createDefaultApiRequestAction(types.ADD_WORKER);

export const editWorkerAction = data =>
  createDefaultApiAction(types.EDIT_WORKER, editWorker, data);

export const editWorkerRequestAction = () =>
  createDefaultApiRequestAction(types.EDIT_WORKER);

export const emailHistoryWorkerAction = data =>
  createDefaultApiAction(types.EMAIL_HISTORY_WORKER, emailHistoryWorker, data);

export const emailHistoryWorkerRequestAction = () =>
  createDefaultApiRequestAction(types.EMAIL_HISTORY_WORKER);

export const resendEmailAction = data =>
  createDefaultApiAction(types.RESEND_EMAIL, resendEmail, data);

export const resendEmailRequestAction = () =>
  createDefaultApiRequestAction(types.RESEND_EMAIL);
