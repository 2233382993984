import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import { getMonths, getYears } from 'utils/dictionaries';

import { getUserId } from 'redux/modules/auth/selectors';

import { getWorker } from 'redux/modules/worker/selectors';
import { getWorkerAction } from 'redux/modules/worker/actions';

import { getWorktime } from 'redux/modules/worktime/selectors';
import {
  getWorkTimeAction,
  addWorkTimeAction,
  addWorkTimeRequestAction
} from 'redux/modules/worktime/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import { getWorktimeMonth } from 'redux/modules/settings/selectors';

import Loading from 'shared/components/Loading';
import Error from 'shared/components/Error';
import {
  actionType,
  matchType,
  tType,
  errorMessageType,
  workerType,
  historyType
} from 'shared/types';
import { CenteredContainerVertical } from 'shared/styles';
import WorkTimeInput from './components/WorkTimeInput';

class WorkerTimesCreate extends Component {
  state = {
    workTime: null
  };

  async componentDidMount() {
    const {
      addWorkTimeRequest,
      getWorkerAct,
      match: {
        params: { id }
      }
    } = this.props;
    addWorkTimeRequest();

    await getWorkerAct(id);
    const worktime = {
      salaryPerHour: this.props.worker.baseSalaryPerHour,
      idCurrency: this.props.worker.idCurrency
    };
    const date = new Date();
    if (this.props.worktimeMonth === 1) {
      // eslint-disable-next-line operator-linebreak
      worktime.year =
        date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      worktime.month = date.getMonth() === 0 ? 12 : date.getMonth();
    } else {
      worktime.year = date.getFullYear();
      worktime.month = date.getMonth() + 1;
    }

    this.setState({
      workTime: worktime
    });
  }

  handleSubmit = async values => {
    const {
      history,
      match: {
        params: { id }
      },
      addWorkTime,
      adminId
    } = this.props;

    const data = {
      ...values,
      timeValue: +values.timeValue,
      salary: +values.salary,
      salaryPerHour: +values.salaryPerHour,
      workerId: +id,
      adminId: +adminId
    };
    await addWorkTime(data);
    if (!this.props.errorForm) {
      history.push(`/worker-times/${id}`);
    }
  };

  onClickBackButton = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push(`/worker-times/${id}`);
  };

  render() {
    const { t, isFetching, errorForm } = this.props;
    const { workTime } = this.state;

    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }
    if (errorForm && errorForm.status > 500) {
      return <Error error={errorForm} />;
    }

    return (
      <CenteredContainerVertical>
        <WorkTimeInput
          title={t('workTimes:AddWorkTime')}
          enableReinitialize
          onSubmit={this.handleSubmit}
          person={this.props.worker}
          initialValues={{
            ...workTime,
            isPpk: this.props.worker.isPpk,
            calcType: 1
          }}
          years={getYears()}
          months={getMonths()}
          serverErrorMessage={errorForm && errorForm.message}
          redirect={this.onClickBackButton}
        />
      </CenteredContainerVertical>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKTIME'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worktime/GET_WORKTIME'
]);

const errorFormSelector = createErrorMessageSelector([
  'worktime/worktime/ADD_WORKTIME'
]);

WorkerTimesCreate.propTypes = {
  match: matchType.isRequired,
  addWorkTime: actionType.isRequired,
  getWorkerAct: actionType.isRequired,
  addWorkTimeRequest: actionType.isRequired,
  t: tType.isRequired,
  worktimeMonth: PropTypes.number.isRequired,
  errorForm: errorMessageType,
  isFetching: PropTypes.bool.isRequired,
  worker: workerType.isRequired,
  history: historyType.isRequired,
  adminId: PropTypes.string.isRequired
};

WorkerTimesCreate.defaultProps = {
  errorForm: null
};

function mapStateToProps(state) {
  return {
    adminId: getUserId(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    errorForm: errorFormSelector(state),
    worker: getWorker(state),
    worktime: getWorktime(state),
    worktimeMonth: getWorktimeMonth(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      getWorkTime: getWorkTimeAction,
      addWorkTime: addWorkTimeAction,
      getWorkerAct: getWorkerAction,
      addWorkTimeRequest: addWorkTimeRequestAction
    }
  )(WorkerTimesCreate)
);
