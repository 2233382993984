import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import { requiredField } from 'utils/fields/validation';

import { historyType, tType } from 'shared/types';
import Input from 'shared/Fields/Input';
import {
  MainButton,
  ButtonGroup,
  CenteredContainerVertical,
  FormTitle,
  SecondaryButton
} from 'shared/styles';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';
import ErrorMessageForm from '../../../shared/Fields/ErrorMessageForm';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '263px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '319px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '270px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  top: '245px',
  left: 'calc(50% - 300px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  margin: '90px auto 0 auto',
  boxSizing: 'border-box',
  width: '360px',
  ':not(:last-child)': {
    marginBottom: '4rem'
  }
});

const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative'
});

const SendButton = styled(MainButton)({
  marginLeft: '15px'
});

const BackButton = styled(SecondaryButton)({});

const SendMailInput = ({ handleSubmit, t, serverErrorMessage, history }) => {
  const onClickBack = () => {
    history.push('/login');
  };
  return (
    <CenteredContainerVertical>
      <BackgroundSquare />
      <StyledForm onSubmit={handleSubmit}>
        <FormContent style={{ height: '295px' }}>
          <FormTitle>{t('mails:PasswordReminder')}</FormTitle>
          <Field
            showErrorMsg
            id="email"
            name="email"
            component={Input}
            validate={[requiredField]}
            label={t('mails:Email')}
            type="text"
            // placeholder={t('mails:Email')}
            t={t}
          />
          <ErrorMessageForm error={serverErrorMessage} />
          <LineHorizontalTop>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalTop>
          <LineVerticalTop>
            <LineDotVertical count={5} />
          </LineVerticalTop>
          <LineHorizontalBottom>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalBottom>
        </FormContent>
        <FormContent style={{ margin: '9px 0' }}>
          <ButtonGroup>
            <BackButton type="reset" onClick={onClickBack}>
              {t('general:Back')}
            </BackButton>
            <SendButton type="submit">{t('mails:SendMail')}</SendButton>
          </ButtonGroup>
        </FormContent>
      </StyledForm>
    </CenteredContainerVertical>
  );
};

SendMailInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired,
  serverErrorMessage: PropTypes.string,
  history: historyType.isRequired
};

SendMailInput.defaultProps = {
  serverErrorMessage: ''
};

export default reduxForm({ form: 'sendMailForm' })(
  withNamespaces()(SendMailInput)
);
