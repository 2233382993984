import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import ReactTable from 'react-table';
import styled from 'styled-jss';
import 'react-table/react-table.css';
import TableHead from './TableHead';
import TableHeader from './TableHeader';

const TableContainer = styled('div')({
  flexDirection: 'column',
  padding: '0 10px',
  // width: 'fit-content',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-start',
  // fontFamily: 'martelsans',
  '& .ReactTable .rt-thead.-filters': {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .rt-th:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .rt-th:focus:after': {
      position: 'absolute',
      pointerEvents: 'none',
      top: '-1px',
      bottom: '0x',
      left: '0',
      right: '0',
      content: "' '",
      border: '1px dotted',
      borderColor: ({ theme }) => theme.greyish,
      borderRadius: '5px'
    }
  },
  '& .ReactTable .rt-thead.-filters input': {
    fontSize: '18px',
    height: '34px',
    position: 'relative',
    top: '0',
    borderColor: ({ theme }) => theme.inputBorder,
    border: '1px solid',
    '&:focus': {
      boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)'
    },
    '&:hover': {
      backgroundColor: ({ theme }) => theme.hover
    }
  },
  '& .ReactTable': {
    width: '640px',
    fontSize: '16px',
    border: 'none',
    marginTop: '10px',
    borderRadius: '0 0 5px 5px'
  },
  '& .ReactTable .rt-tbody': {
    overflow: 'hidden'
  },
  '& .ReactTable .rt-thead.-header': {
    textTransform: 'lowercase',
    boxShadow: 'none',
    height: '50px'
  },
  '& .ReactTable .rt-tbody .rt-tr-group': {
    border: '0'
  },
  '& .ReactTable.-striped .rt-tr.-odd': {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: '0',
    height: '30px',
    borderBottom: '2px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue
  },
  '& .ReactTable.-striped .rt-tr.-odd:hover:not(:active)': {
    backgroundColor: ({ theme }) => theme.paleSkyBlue
  },
  '& .ReactTable.-striped .rt-tr.-odd:active': {
    borderColor: 'black',
    backgroundColor: 'white',
    '& a': {}
  },
  '& .ReactTable.-striped .rt-tr.-odd:focus': {
    borderColor: 'black'
  },
  '& .ReactTable.-striped .rt-tr.-even': {
    height: '30px',
    border: '0',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderBottom: '2px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue
  },
  '& .ReactTable.-striped .rt-tr.-even:hover:not(:active)': {
    backgroundColor: ({ theme }) => theme.paleSkyBlue
  },
  '& .ReactTable.-striped .rt-tr.-even:active': {
    borderColor: 'black',
    backgroundColor: 'white',
    '& a': {}
  },
  '& .ReactTable.-striped .rt-tr.-even:focus': {
    borderColor: ({ theme }) => theme.focus
  },
  '& .ReactTable .rt-tbody .rt-td': {
    borderRight: 'none',
    textOverflow: 'ellipsis'
  },
  '& .ReactTable .rt-thead .rt-th': {
    color: ({ theme }) => theme.tableHeader,
    background: 'transparent',
    fontWeight: '300',
    lineHeight: 'normal',
    position: 'relative',
    borderRight: '0',
    boxShadow: 'none',
    textAlign: 'left',
    outline: 'none',
    // border: '1px dotted',
    boxSizing: 'borderBox',
    height: '49px',
    borderColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: ({ theme }) => theme.hover,
      '& svg': {
        fill: 'black'
      }
    },
    '& .rt-resizable-header-content:first-letter': {
      textTransform: 'uppercase'
    }
  },
  '& .ReactTable .rt-thead .rt-th:focus:after': {
    position: 'absolute',
    pointerEvents: 'none',
    top: '2px',
    bottom: '2px',
    left: '2px',
    right: '2px',
    content: "' '",
    // border: '1px dotted',
    borderColor: ({ theme }) => theme.greyish,
    borderRadius: '5px'
  },
  '& .ReactTable .rt-thead .rt-th:first-of-type': {
    margin: '0'
  },
  '& .ReactTable .rt-thead .rt-th:active': {
    textDecoration: 'underline',
    backgroundColor: 'transparent !important'
  },
  '& .ReactTable .rt-thead .rt-td': {
    padding: '5px 5px',
    fontWeight: '300',
    color: ({ theme }) => theme.primaryColor,
    background: 'transparent',
    lineHeight: 'normal',
    position: 'relative',
    borderRight: 'none',
    boxShadow: 'none',
    textAlign: 'left',
    outline: 'none',
    alignSelf: 'center'
  },
  '& .ReactTable .rt-th, .ReactTable .rt-td': {
    alignSelf: 'center',
    padding: '0 5px 0 6px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  '& .ReactTable .-pagination': {
    borderTop: 'none',
    padding: '23px 3px 33px 3px',
    height: '86px',
    alignItems: 'center',
    backgroundColor: ({ theme }) => theme.white,
    boxShadow: 'none'
  },
  '& .ReactTable .-pagination .-previous': {
    display: 'flex',
    justifyItems: 'flex-end'
  },
  '& .ReactTable .-pagination .-previous button': {
    marginRight: '10px',
    backgroundColor: ({ theme }) => theme.white,
    border: '2px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue,
    height: '28px',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    color: ({ theme }) => theme.charcoalGrey,
    width: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    marginLeft: 'auto',
    borderRadius: '0',
    boxShadow: '2px 2px 0 0 #c3e8ff',
    '&:active': {
      borderColor: 'black',
      border: '2px solid',
      outline: '2px solid',
      outlineColor: ({ theme }) => theme.paleSkyBlue,
      boxShadow: 'none'
    },
    '&:focus': {
      outline: '1px solid',
      outlineColor: ({ theme }) => theme.lightBlue30
    },
    '&:disabled': {
      cursor: 'not-allowed',
      borderColor: ({ theme }) => theme.pinkishGrey,
      boxShadow: '2px 2px 0 0 #d0d0d0'
    },
    '&:hover:not(:disabled)': {
      backgroundColor: ({ theme }) => theme.hover,
      color: ({ theme }) => theme.charcoalGrey
    }
  },
  '& .ReactTable .-pagination .-next': {
    justifyContent: 'flex-start',
    display: 'flex'
  },
  '& .ReactTable .-pagination .-next button': {
    marginLeft: '10px',
    backgroundColor: ({ theme }) => theme.white,
    border: '2px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue,
    height: '28px',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    color: ({ theme }) => theme.charcoalGrey,
    width: '100px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '0',
    boxShadow: '2px 2px 0 0 #c3e8ff',
    '&:active': {
      borderColor: 'black',
      border: '2px solid',
      outline: '2px solid',
      outlineColor: ({ theme }) => theme.paleSkyBlue,
      boxShadow: 'none'
    },
    '&:focus': {
      outline: '1px solid',
      outlineColor: ({ theme }) => theme.lightBlue30
    },
    '&:disabled': {
      cursor: 'not-allowed',
      borderColor: ({ theme }) => theme.pinkishGrey,
      boxShadow: '2px 2px 0 0 #d0d0d0'
    },
    '&:hover:not(:disabled)': {
      backgroundColor: ({ theme }) => theme.hover,
      color: ({ theme }) => theme.charcoalGrey
    }
  },
  '& .ReactTable .pagination-bottom': {
    // borderLeft: '1px solid #9b9b9b',
    // borderRight: '1px solid #9b9b9b',
    // borderBottom: '1px solid #9b9b9b'
  },
  '& .ReactTable .-pagination .-center': {
    width: '175px',
    flex: 'none',
    fontSize: '16px',
    '& .-pageInfo': {
      fontSize: '16px',
      margin: '0'
    },
    '& .-pageJump': {
      fontSize: '16px',
      margin: '0 5px'
    },
    '& input': {
      width: '28px',
      height: '28px',
      padding: '0',
      fontSize: '16px',
      borderRadius: '0',
      border: '2px solid',
      borderColor: ({ theme }) => theme.paleSkyBlue
    }
  }
});

const Table = ({ t, style, ...props }) => (
  <TableContainer style={style}>
    <ReactTable
      showPageSizeOptions={false}
      className="-striped -highlight"
      ThComponent={TableHead}
      TheadComponent={TableHeader}
      previousText={t('tableProps:Previous')}
      nextText={t('tableProps:Next')}
      loadingText={t('tableProps:LoadingTableTxt')}
      noDataText={t('tableProps:NoRows')}
      pageText={t('tableProps:Page')}
      ofText={t('tableProps:Of')}
      rowsText={t('tableProps:Rows')}
      {...props}
    />
  </TableContainer>
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
  style: PropTypes.shape({})
};
Table.defaultProps = {
  data: [],
  style: null
};

export default withNamespaces()(Table);
