import { Seq } from 'immutable';

export const serverAddress = process.env.REACT_APP_API_URL;
export const basename = process.env.REACT_APP_BASENAME;

export const currency = [
  {
    value: 1,
    label: 'PLN'
  },
  {
    value: 2,
    label: 'EUR'
  },
  {
    value: 3,
    label: 'USD'
  },
  {
    value: 4,
    label: 'CAD'
  }
];

export function getMonths() {
  return [
    { value: 1, label: 'Styczeń' },
    { value: 2, label: 'Luty' },
    { value: 3, label: 'Marzec' },
    { value: 4, label: 'Kwiecień' },
    { value: 5, label: 'Maj' },
    { value: 6, label: 'Czerwiec' },
    { value: 7, label: 'Lipiec' },
    { value: 8, label: 'Sierpień' },
    { value: 9, label: 'Wrzesień' },
    { value: 10, label: 'Październik' },
    { value: 11, label: 'Listopad' },
    { value: 12, label: 'Grudzień' }
  ];
}

export const monthSettings = [
  {
    label: 'Previous',
    value: 1
  },
  {
    label: 'Current',
    value: 2
  }
];

export function getYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 5; i < currentYear + 10; i += 1) {
    years.push({
      label: i,
      value: i
    });
  }
  return years;
}

export const languages = Seq([
  {
    label: 'PL',
    value: '1',
    shortName: 'pl-PL'
  },
  {
    label: 'EN',
    value: '2',
    shortName: 'en-US'
  },
  {
    label: 'UA',
    value: '3',
    shortName: 'uk-UA'
  }
]);

export const workerStatuses = Seq([
  { label: 'Active', value: '1' },
  { label: 'Blocked', value: '3' },
  { label: 'NotActive', value: '4' },
  { label: 'All', value: '1,3,4' }
]);

export const emailTypes = Seq([
  { label: 'Wszystkie', value: '1,2,3,4' },
  { label: 'Rejestracja', value: '1' },
  { label: 'Aktywacja', value: '2' },
  { label: 'Czas Pracy', value: '3' },
  { label: 'Zmiana Hasła', value: '4' }
]);

export const reportTypes = Seq([
  { label: 'Months', value: '1' },
  { label: 'WorkTimeAcceptanceDate', value: '2' }
]).toArray();

export const reportMimeTypes = Seq([
  { label: 'CSV', value: '1' },
  { label: 'PDF', value: '2' }
]).toArray();

export const calcTypes = Seq([
  { label: 'Salary', value: 1 },
  { label: 'HourlyRate', value: 2 },
  { label: 'Hours', value: 3 }
]).toArray();
