import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import { withNamespaces } from 'react-i18next';
import { tType } from 'shared/types';
import MultiSelect from 'shared/Fields/Multiselect';
import Datepicker from 'shared/Fields/Datepicker';
import RadioInline from 'shared/Fields/RadioInline';
import Select from 'shared/Fields/SelectInline';
import { MainButton, FormTitle, SecondaryButton } from 'shared/styles';
import { Field, reduxForm } from 'redux-form';
import { reportTypes, reportMimeTypes } from 'utils/dictionaries';
import { requiredField } from 'utils/fields/validation';
import { LineDotHorizontal } from 'shared/components/LineDots';
import DottedBg from 'assets/Icon/icons/DottedBg';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  bottom: '-29px',
  left: '0',
  '& svg circle': {
    fill: ({ theme }) => theme.paleSkyBlue
  }
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  bottom: '4px',
  left: '0',
  '& svg circle': {
    fill: ({ theme }) => theme.paleSkyBlue
  }
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  bottom: '-70px',
  left: 'calc(50% + 180px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  width: '570px',
  margin: '75px auto 0 auto',
  alignItems: 'center',
  padding: '5px 10px',
  boxSizing: 'border-box',
  position: 'relative'
});

const FormContent = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: '1'
});

const Grid = styled('div')({
  position: 'relative'
});

const BottomContainer = styled('div')({
  width: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center'
});

const DatesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0 25px 0',
  borderRadius: '5px',
  height: '120px',
  minHeight: '120px',
  boxSizing: 'border-box',
  width: '100%'
});

const DatepickerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '11px',
  width: '100%'
});

const SelectLabel = styled('label')({
  display: 'block',
  fontSize: '16px',
  fontWeight: '300',
  paddingTop: '5px',
  marginBottom: '10px'
});

const SelectContainer = styled('div')({
  display: 'flex',
  marginTop: '11px',
  justifyContent: 'space-between',
  width: '100%'
});

const RenderDates = ({ reportType, months, t }) => {
  switch (reportType) {
    case '1':
      return (
        <SelectContainer>
          <Field
            label={`${t('report:From')}:`}
            name="from"
            options={months}
            component={Select}
            validate={[requiredField]}
            style={{ display: 'flex', alignItems: 'center' }}
          />
          <Field
            label={`${t('report:To')}:`}
            name="to"
            options={months}
            component={Select}
            validate={[requiredField]}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        </SelectContainer>
      );
    case '2':
      return (
        <DatepickerContainer>
          <Field
            label={t('report:From')}
            name="from"
            component={Datepicker}
            validate={[requiredField]}
          />
          <Field
            label={t('report:To')}
            name="to"
            component={Datepicker}
            validate={[requiredField]}
          />
        </DatepickerContainer>
      );
    default:
  }
  return <div />;
};

RenderDates.propTypes = {
  t: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const ReportInput = ({
  handleSubmit,
  reportType,
  reportTypeChanged,
  t,
  users,
  columns,
  months,
  title,
  backButton
}) => (
  <StyledForm onSubmit={handleSubmit}>
    <FormContent style={{ zIndex: '3' }}>
      <FormTitle>{title}</FormTitle>
      <Grid style={{ marginBottom: '35px' }}>
        <SelectLabel>{`${t('report:FileFormat')}:`}</SelectLabel>
        <Field
          name="reportMimeType"
          options={reportMimeTypes}
          component={RadioInline}
          styleLabel={{ margin: '0 70px 20px 0' }}
        />
        <SelectLabel htmlFor="persons">{t('report:Persons')}:</SelectLabel>
        <Field
          name="persons"
          options={users}
          component={MultiSelect}
          validate={[requiredField]}
        />

        <SelectLabel htmlFor="columns">{t('report:Columns')}:</SelectLabel>
        <Field
          name="columns"
          options={columns}
          component={MultiSelect}
          validate={[requiredField]}
        />
        <LineHorizontalTop>
          <LineDotHorizontal count={38} width={580} />
        </LineHorizontalTop>
      </Grid>

      <BottomContainer>
        <DatesContainer>
          <Field
            name="reportType"
            input={{
              value: reportType,
              onChange: reportTypeChanged,
              name: reportType,
              onBlur: () => reportType,
              onFocus: () => reportType
            }}
            options={reportTypes}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '15px'
            }}
            component={RadioInline}
          />
          <RenderDates reportType={reportType} months={months} t={t} />
        </DatesContainer>
        <LineHorizontalBottom>
          <LineDotHorizontal count={38} width={580} />
        </LineHorizontalBottom>
      </BottomContainer>
    </FormContent>

    <FormContent
      style={{ marginTop: '5px', paddingBottom: '17px', paddingTop: '11px' }}
    >
      <BottomContainer>
        <SecondaryButton onClick={backButton} style={{ marginRight: '75px' }}>
          {t('general:Back')}
        </SecondaryButton>
        <MainButton type="submit">{t('report:Generate')}</MainButton>
      </BottomContainer>
    </FormContent>
    <BackgroundSquare />
  </StyledForm>
);

ReportInput.propTypes = {
  reportType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  months: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reportTypeChanged: PropTypes.func.isRequired,
  backButton: PropTypes.func.isRequired,
  t: tType.isRequired
};

export default reduxForm({ form: 'reportForm' })(withNamespaces()(ReportInput));
