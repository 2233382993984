export const requiredField = value => (value ? undefined : 'RequiredField');

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'InvalidEmail'
    : undefined;

export const positiveNumber = value =>
  value <= 0 ? 'InvalidPositiveNumber' : undefined;

export const maxValue999 = value =>
  value > 999 ? 'MaxValue999Valid' : undefined;

export const maxValue100 = value =>
  value > 100 ? 'MaxValue100Valid' : undefined;

export const maxLength150 = value => {
  if (value && value.length > 150) {
    return 'MaxLength150Valid';
  }
  if (!value) {
    return undefined;
  }
  if (/^\S/.test(value)) {
    return undefined;
  }
  return 'WhitespaceBegValid';
};

export const passwordRules = value => {
  if (value.length < 6) {
    return 'PasswordTooShort';
  }

  if (value === value.toLowerCase()) {
    return 'PasswordUpperCase';
  }

  const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  if (!format.test(value)) {
    return 'PasswordSpecialChars';
  }

  if (!/\d/.test(value)) {
    return 'PasswordNumbers';
  }

  return undefined;
};
