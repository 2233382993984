import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CenteredContainerVertical } from 'shared/styles';

import { getWorker } from 'redux/modules/worker/selectors';
import {
  getWorkerAction,
  editWorkerAction,
  getWorkerRequestAction,
  editWorkerRequestAction
} from 'redux/modules/worker/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import Loading from 'shared/components/Loading';
import {
  actionType,
  tType,
  errorMessageType,
  workerType,
  matchType,
  historyType
} from 'shared/types';
import WorkerInput from './components/WorkerInput';

class Worker extends PureComponent {
  async componentDidMount() {
    this.props.editWorkerRequest();
    this.props.getWorkerRequest();
    await this.props.getWorkerAct(this.props.match.params.id);
  }

  handleSubmit = async values => {
    await this.props.editWorker({
      ...values,
      idPerson: this.props.match.params.id
    });
    if (!this.props.error) {
      this.onClickBackButton();
    }
  };

  onClickBackButton = () => {
    this.props.history.push(`/worker/${this.props.match.params.id}/profile`);
  };

  render() {
    const { worker, t } = this.props;

    if (this.props.isFetching) {
      return <Loading text={this.props.t('general:PleaseWait')} />;
    }

    return (
      <CenteredContainerVertical>
        <WorkerInput
          title={this.props.t('workerActions:EditEmployee')}
          submitLabel={t('general:Save')}
          onSubmit={this.handleSubmit}
          initialValues={{
            ...worker,
            idLanguageDict: `${worker.idLanguageDict}`
          }}
          serverErrorMessage={this.props.error && this.props.error.message}
          enableReinitialize
          redirect={this.onClickBackButton}
        />
      </CenteredContainerVertical>
    );
  }
}

Worker.propTypes = {
  t: tType.isRequired,
  history: historyType.isRequired,
  getWorkerAct: actionType.isRequired,
  editWorker: actionType.isRequired,
  editWorkerRequest: actionType.isRequired,
  getWorkerRequest: actionType.isRequired,
  error: errorMessageType,
  worker: workerType.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: matchType.isRequired
};

Worker.defaultProps = {
  error: null
};

const loadingSelector = createLoadingSelector(['worktime/worker/GET_WORKER']);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKER',
  'worktime/worker/EDIT_WORKER'
]);

function mapStateToProps(state) {
  return {
    worker: getWorker(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state)
  };
}

export default connect(
  mapStateToProps,
  {
    getWorkerAct: getWorkerAction,
    editWorker: editWorkerAction,
    getWorkerRequest: getWorkerRequestAction,
    editWorkerRequest: editWorkerRequestAction
  }
)(withNamespaces()(Worker));
