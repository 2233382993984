import axios from './index';

export async function getWorker(id) {
  return axios.get(`api/persons/${id}`);
}

export async function blockWorker(idPerson) {
  return axios.put(`api/persons/${idPerson}/block`);
}

export async function unblockWorker(idPerson) {
  return axios.put(`api/persons/${idPerson}/unblock`);
}

export async function deleteWorker(idPerson) {
  return axios.delete(`api/persons/${idPerson}`);
}

export async function getWorkers(params) {
  return axios.get('api/persons', { params });
}

export async function editWorker(newWorker) {
  return axios.put(`api/persons/${newWorker.idPerson}`, newWorker);
}

export async function addWorker(newWorker) {
  return axios.post('api/persons', newWorker);
}

export async function emailHistoryWorker(idPerson) {
  return axios.get(`api/persons/${idPerson}/emails`);
}

export async function resendEmail(idPerson) {
  return axios.post(`api/persons/${idPerson}/resendEmail`);
}
