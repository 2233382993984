// eslint-disable-next-line
export const createLoadingSelector = (actions) => (state) => actions.some((action) => state.loading[action]);

export const createErrorMessageSelector = actions => state => {
  const errors = actions.map(action => state.error[action]);
  if (errors.some(error => error)) {
    if (errors.some(error => error && error.status >= 500 && !error.message)) {
      return {
        status: 500,
        message: 'Err500'
      };
    }
    return errors.find(error => error);
  }

  return null;
};
