import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import { tType } from '../types';

const ErrorText = styled('h4')({
  fontWeight: '400',
  color: ({ theme }) => theme.error,
  fontSize: '16px',
  margin: '10px 0 0 0'
});

const ErrorMessageForm = ({ error, t }) => {
  if (error) {
    return <ErrorText>{t(`validation:${error}`)}</ErrorText>;
  }
  return null;
};

ErrorMessageForm.propTypes = {
  error: PropTypes.string,
  t: tType.isRequired
};

ErrorMessageForm.defaultProps = {
  error: null
};

export default withNamespaces()(ErrorMessageForm);
