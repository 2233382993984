import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import { withNamespaces } from 'react-i18next';
import { tType } from '../types';

const OuterContainer = styled('div')({
  display: 'flex'
});

const Text = styled('span')({
  position: 'relative',
  top: '5px'
});

const StyledLabel = styled('label')({
  position: 'relative',
  paddingLeft: '30px',
  textAlign: 'left',
  fontSize: '16px',
  cursor: 'pointer',
  marginRight: '15px',
  color: ({ theme }) => theme.charcoalGrey,
  '&:hover': {
    color: ({ theme }) => theme.hover,
    '& div': {
      backgroundColor: ({ theme }) => theme.paleSkyBlue
    }
  }
});

const RadioButton = styled('input')({
  cursor: 'pointer',
  visibility: 'hidden',
  height: '20px',
  width: '20px',
  position: 'absolute',
  top: '5px',
  left: '1px',
  padding: '0',
  margin: '0'
});

const Checkmark = styled('div')({
  display: 'flex',
  alignSelf: 'center',
  height: '20px',
  width: '20px',
  left: '1px',
  top: '5px',
  // visibility: ({ checked }) => (checked ? 'visible' : 'hidden'),
  boxSizing: 'border-box',
  backgroundClip: 'content-box',
  content: '""',
  position: 'absolute',
  padding: ({ checked }) => (checked ? '3px' : '0'),
  border: '1px solid',
  borderRadius: '50%',
  borderColor: ({ theme }) => theme.paleSkyBlue,
  // eslint-disable-next-line prettier/prettier
  backgroundColor: ({ theme, checked }) => (checked ? theme.textDefault : 'none'),
  pointerEvents: 'none'
});

const SelectLabel = styled('label')({
  textAlign: 'right',
  fontSize: '24px',
  fontWeight: '300'
});

const RadioInline = ({
  input,
  meta,
  style,
  styleLabel,
  options,
  t,
  ...props
}) => (
  <OuterContainer style={style}>
    {props.label && <SelectLabel>{t(props.label)}</SelectLabel>}
    {options.map(({ label, value }) => (
      <StyledLabel
        style={styleLabel}
        key={value}
        htmlFor={`${input.name}_${value}`}
      >
        <RadioButton
          {...input}
          id={`${input.name}_${value}`}
          type="radio"
          value={value}
        />
        <Checkmark checked={input.value === value} />
        <Text>{t(`dictionaries:${label}`)}</Text>
      </StyledLabel>
    ))}
    {meta.error && meta.touched && <span>{meta.error}</span>}
  </OuterContainer>
);

RadioInline.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired
    ]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired
  }).isRequired,
  t: tType.isRequired,
  style: PropTypes.shape({}),
  styleLabel: PropTypes.shape({})
};

RadioInline.defaultProps = {
  label: null,
  style: null,
  styleLabel: null
};

export default withNamespaces()(RadioInline);
