import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import reducers from 'redux/modules';
import storage from 'redux/utis/storageFactory';

import sessionHandler from 'utils/sessionHandler';
import authMiddleware from './modules/auth/middleware';

const token = storage.getItem('token');
const email = storage.getItem('email');
const role = storage.getItem('role');
const userId = storage.getItem('userId');
const language = storage.getItem('language');
const fullname = storage.getItem('fullname');

const pageSize = +storage.getItem('pageSize');
const monthWorkTime = +storage.getItem('monthWorkTime');

const createStoreWithMiddleware = applyMiddleware(
  reduxThunk,
  authMiddleware,
  logger
)(createStore);

sessionHandler.run();

const store = createStoreWithMiddleware(reducers, {
  auth: {
    email,
    role,
    token,
    userId,
    language,
    fullname
  },
  settings: {
    pageSize,
    monthWorkTime
  }
});

export default store;
