import React, { PureComponent } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  changePasswordAction,
  checkPasswordGuidAction
} from 'redux/modules/account/actions';
import { signOutAction } from 'redux/modules/auth/actions';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import {
  actionType,
  errorMessageType,
  tType,
  locationType
} from 'shared/types';
import routes from 'config/routes';

import ChangePasswordInput from './components/ChangePasswordInput';

class Register extends PureComponent {
  state = {
    isAddedSuccessfully: false
  };

  async componentDidMount() {
    await this.props.signOut();
    await this.props.checkPasswordGuid(
      queryString.parse(this.props.location.search).uid
    );
  }

  handleSubmit = async values => {
    const { email, uid } = queryString.parse(this.props.location.search);
    await this.props.changePassword({ ...values, email, code: uid });

    if (!this.props.error) {
      this.setState({ isAddedSuccessfully: true });
    }
  };

  render() {
    const { isAddedSuccessfully } = this.state;
    const { t, isFetching, error, guidError } = this.props;
    const { email } = queryString.parse(this.props.location.search);

    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }
    if (isAddedSuccessfully) {
      return <Redirect to={routes.login.path} />;
    }

    if (guidError) {
      return <Error error={guidError} />;
    }

    return (
      <ChangePasswordInput
        email={email}
        onSubmit={this.handleSubmit}
        error={error && error.message}
      />
    );
  }
}

const loadingSelector = createLoadingSelector([
  'worktime/account/CHANGE_PASSWORD',
  'worktime/account/CHECK_PASSWORD_GUID'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/account/CHANGE_PASSWORD'
]);

const guidErrorSelector = createErrorMessageSelector([
  'worktime/account/CHECK_PASSWORD_GUID'
]);

Register.propTypes = {
  error: errorMessageType,
  isFetching: PropTypes.bool.isRequired,
  t: tType.isRequired,
  changePassword: actionType.isRequired,
  signOut: actionType.isRequired,
  checkPasswordGuid: actionType.isRequired,
  location: locationType.isRequired,
  guidError: PropTypes.shape({})
};

Register.defaultProps = {
  error: null,
  guidError: null
};

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    guidError: guidErrorSelector(state)
  };
}

export default connect(
  mapStateToProps,
  {
    changePassword: changePasswordAction,
    signOut: signOutAction,
    checkPasswordGuid: checkPasswordGuidAction
  }
)(withNamespaces()(Register));
