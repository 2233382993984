import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';

import { currency, languages } from 'utils/dictionaries';
import Input from 'shared/Fields/Input';
import Select from 'shared/Fields/Select';
import NumberInput from 'shared/Fields/NumberInput';
import {
  requiredField,
  email,
  positiveNumber,
  maxLength150,
  maxValue999
} from 'utils/fields/validation';

import Loading from 'shared/components/Loading';
import { tType } from 'shared/types';
import {
  MainButton,
  SecondaryButton,
  ButtonGroup,
  FormTitle
} from 'shared/styles';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import DottedBg from 'assets/Icon/icons/DottedBg';
import Checkbox from 'shared/Fields/Checkbox';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '-47px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '9px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '-138px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  bottom: '0',
  left: 'calc(50% - 300px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  margin: '75px auto 0 auto',
  boxSizing: 'border-box',
  padding: '5px 10px',
  width: '400px',
  position: 'relative',
  ':not(:last-child)': {
    marginBottom: '4rem'
  }
});

const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: '2'
});

const WorkerInput = ({
  handleSubmit,
  serverErrorMessage,
  t,
  submitting,
  redirect,
  submitLabel,
  title
}) => {
  if (submitting) {
    return <Loading text={t('general:PleaseWait')} />;
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormContent style={{ zIndex: '3' }}>
        <FormTitle>{title}</FormTitle>
        <Field
          showErrorMsg
          id="firstName"
          component={Input}
          name="firstName"
          validate={[requiredField, maxLength150]}
          type="text"
          label={t('worker:FirstName')}
          style={{ marginTop: '13px' }}
          // placeholder={t('worker:FirstName')}
          t={t}
        />
        <Field
          showErrorMsg
          id="lastName"
          component={Input}
          name="lastName"
          validate={[requiredField, maxLength150]}
          type="text"
          label={t('worker:LastName')}
          style={{ marginTop: '15px' }}
          // placeholder={t('worker:LastName')}
          t={t}
        />
        <Field
          showErrorMsg
          id="email"
          component={Input}
          name="email"
          validate={[requiredField, email, maxLength150]}
          type="text"
          label={t('worker:Email')}
          style={{ marginTop: '15px' }}
          // placeholder={t('worker:Email')}
          t={t}
        />
        <Field
          showErrorMsg
          id="phone"
          component={Input}
          name="phone"
          type="text"
          label={t('worker:Phone')}
          style={{ marginTop: '15px' }}
          validate={[maxLength150]}
          // placeholder={t('worker:Phone')}
          t={t}
        />
        <Field
          showErrorMsg
          id="baseSalaryPerHour"
          name="baseSalaryPerHour"
          validate={[requiredField, positiveNumber, maxValue999]}
          component={NumberInput}
          precision={2}
          step={0.1}
          label={t('worker:DefaultHourlyRate')}
          style={{ marginTop: '15px' }}
          // placeholder={t('worker:HourlyRate')}
          t={t}
        />
        <Field
          id="isPpk"
          name="isPpk"
          component={Checkbox}
          label={t('workTimes:Ppk')}
          style={{ marginTop: '15px' }}
        />
        <Field
          id="idCurrency"
          name="idCurrency"
          component={Select}
          validate={[requiredField]}
          type="text"
          style={{ marginTop: '15px', width: '360px' }}
          // styleLabel={{marginLeft:'-20px'}}
          // placeholder={t('worker:Currency')}
          label={t('worker:DefaultCurrency')}
          options={currency}
        />
        <Field
          id="idLanguageDict"
          name="idLanguageDict"
          component={Select}
          validate={[requiredField]}
          type="text"
          // placeholder={t('worker:language')}
          label={t('worker:Language')}
          style={{ marginTop: '15px', width: '360px' }}
          // styleLabel={{marginLeft:'-20px'}}
          options={languages}
        />
        <ErrorMessageForm error={serverErrorMessage} />
      </FormContent>
      <FormContent style={{ marginTop: '10px' }}>
        <React.Fragment>
          <LineHorizontalTop>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalTop>
          <LineVerticalTop>
            <LineDotVertical count={20} />
          </LineVerticalTop>
          <LineHorizontalBottom>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalBottom>
          <ButtonGroup>
            <SecondaryButton
              type="button"
              style={{ marginRight: '14px' }}
              onClick={redirect}
            >
              {t('general:Cancel')}
            </SecondaryButton>
            <MainButton type="submit">{submitLabel}</MainButton>
          </ButtonGroup>
        </React.Fragment>
      </FormContent>
      <BackgroundSquare />
    </StyledForm>
  );
};

WorkerInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: tType.isRequired,
  serverErrorMessage: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

WorkerInput.defaultProps = {
  serverErrorMessage: null
};

export default reduxForm({ form: 'workerForm' })(withNamespaces()(WorkerInput));
