const themes = {
  light: {
    textDefault: 'black',
    inputLabel: 'rgba(0, 0, 0, 0.67)',
    primaryColor: '#c3e8ff',
    white: '#ffffff',
    lightBlue30: 'rgba(145, 211, 253, 0.3)',
    greenyBlue: '#46a7a1',
    paleSkyBlue: '#c3e8ff',
    pastelRed: '#e25252', // delete, block button
    hoverDelete: '#ff2626',
    purplishRed: '#b2095a',
    purplishRed04: 'rgba(178, 9, 90, 0.4)',
    mossyGreen: '#478828', // unblock button
    hoverUnblock: '#53ad27',
    hover: '#c3e8ff',
    charcoalGrey: '#434345',
    black75: 'rgba(0, 0, 0, 0.75)',
    error: 'red',
    background: 'white',
    selectHover: '#49ccc3',
    mainBackground: '#f6f7f9',
    pinkishGrey: '#d0d0d0',
    tableRowOdd: '#f6f0f0',
    tableRowEven: '#dcd9d9',
    tableShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    tablePaginationCenterBorder: 'rgba(0, 0, 0, 0.43)',
    tablePaginationSideBorder: 'black',
    borderInputDefault: '2px solid #c3e8ff',
    borderInputInvalid: '2px solid red',
    borderInputValid: '2px solid #c3e8ff',
    navbar: {
      backgroundColor: '#C3E8FF',
      iconColor: '#434345',
      avatarDropdownBackground: 'white',
      avatarDropdownShadow: 'rgba(0, 0, 0, 0.11) 0px 3px 8px',
      avatarDropdownItemBorder: '1px solid #c3e8ff'
    },
    btn: {
      base: {
        txtColor: '#fff',
        backgroundColor: '#1d2323',
        boxShadow: '0 1rem 2rem rgba(black, .1)',
        focus: {
          boxShadow: '0 0 2rem rgba(black, .1)'
        }
      },
      secondary: {
        backgroundColor: '#404747'
      },
      welcome: {
        txtColor: 'white',
        backgroundColor: '#478828'
      },
      warning: {
        txtColor: 'white',
        backgroundColor: '#ad1a1c'
      },
      warningEmpty: {
        txtColor: '#ad1a1c',
        backgroundColor: 'white',
        border: '1px solid #ad1a1c'
      },
      back: {
        backgroundColor: '#404747'
      }
    },
    input: {
      backgroundColor: 'white',
      borderBottom: {
        default: '3px solid grey',
        invalidInput: '3px solid red',
        validInput: '3px solid green'
      },
      label: {
        color: '#1d2323'
      },
      errorMsg: {
        color: 'red'
      }
    },
    select: {
      simpleSelect: {
        primaryColor: 'black',
        optionColor: 'black',
        svgFill: '#c3e8ff',
        borderColor: '#c3e8ff',
        color: 'black',
        backgroundColor: 'black',
        focus: {
          borderColor: 'grey',
          color: 'black'
        },
        active: {
          borderColor: 'grey'
        },
        hover: {
          borderColor: '#c3e8ff',
          backgroundColor: '#c3e8ff',
          fill: 'grey'
        },
        disabled: {
          placeholderColor: 'grey',
          fill: 'grey',
          color: 'grey'
        }
      }
    },
    table: {
      lightRowBackgroundColor: 'transparent !important',
      lightRowBackgroundColorFocus: '#C6C6C6 !important',
      darkRowBackgroundColor: '#D3D3D3 !important',
      darkRowBackgroundColorFocus: '#B2B2B2 !important'
    },
    popup: {
      backgroundColor: 'white',
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    text: {
      websiteTitle: {
        color: 'white'
      },
      h1: {
        color: 'black'
      },
      h3: {
        color: 'black'
      },
      basic: {
        color: 'black'
      }
    }
  }
};

export default themes;
