import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-jss';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { withNamespaces } from 'react-i18next';
import DatepickerIcon from 'assets/Icon/icons/IconMDatePicker';
import FieldError from './FieldError';

const Container = styled('div')({
  alignItems: 'center',
  position: 'relative'
});

const InnerContainer = styled('div')({
  display: 'inline-flex',
  position: 'relative',
  marginRight: '20px',
  '& input': {
    cursor: 'pointer',
    width: '200px',
    height: '28px',
    borderRadius: '0',
    border: '1px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue,
    paddingLeft: '10px',
    boxSizing: 'border-box'
  },
  '&:hover': {
    '& input': {
      backgroundColor: ({ theme }) => theme.paleSkyBlue
    },
    '& svg': {
      fill: 'black'
    }
  }
});

const IconContainer = styled(DatepickerIcon)({
  position: 'absolute',
  zIndex: '11',
  top: '5px',
  right: '10px',
  pointerEvents: 'none',
  fill: ({ theme }) => theme.primaryColor
});

const BaseLabel = styled('div')({
  textAlign: 'left',
  fontWeight: '300'
});

const Label = styled(BaseLabel)(({ theme }) => ({
  color: theme.textDefault,
  fontSize: '16px',
  display: 'inline',
  fontWeight: '300',
  marginRight: '10px'
}));

class Datetime extends React.Component {
  static propTypes = {
    meta: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
      onFocus: PropTypes.func.isRequired
    }).isRequired,
    label: PropTypes.string,
    style: PropTypes.shape({})
  };

  static defaultProps = {
    label: null,
    style: null
  };

  componentDidMount() {
    this.props.input.onChange(null);
  }

  onChange = value => {
    const formatPattern = 'D/MM/YYYY';
    const date = moment(value)
      .format(formatPattern)
      .toString();
    this.props.input.onChange(date);
  };

  render() {
    const { input, meta, label, style } = this.props;

    const formatPattern = 'dd/MM/yyyy';

    return (
      <Container style={style}>
        {label && <Label htmlFor={input.name}>{label}:</Label>}
        <InnerContainer>
          <DatePicker
            {...input}
            autoComplete="off"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat={formatPattern}
            className="form-control"
            selected={
              moment(input.value, formatPattern).isValid()
                ? moment(input.value, formatPattern).toDate()
                : null
            }
            onChange={this.onChange}
          />
          <IconContainer />
        </InnerContainer>
        <FieldError style={{ textAlign: 'center', padding: '0' }} meta={meta} />
      </Container>
    );
  }
}

export default withNamespaces()(Datetime);
