import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import { tType } from 'shared/types';
import { SelectContainer } from 'shared/styles';
import { withNamespaces } from 'react-i18next';
import DownIcon from 'assets/Icon/icons/ArrowDown';
import UpIcon from 'assets/Icon/icons/ArrowUp';

const BorderSelectContainer = styled(SelectContainer)({
  fontSize: '16px'
});

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen && (
        <Fragment>
          <UpIcon />
          <UpIcon />
        </Fragment>
      )}
      {!props.selectProps.menuIsOpen && (
        <Fragment>
          <DownIcon />
          <DownIcon />
        </Fragment>
      )}
    </components.DropdownIndicator>
  );

const InnerSelectContainer = styled('div')({
  display: 'table',
  width: '100%',
  maxWidth: '400px',
  minWidth: '120px'
});

const SimpleSelect = ({
  options,
  defaultOption,
  onChange,
  style,
  t,
  ...props
}) => (
  <BorderSelectContainer style={style}>
    <InnerSelectContainer>
      <Select
        components={{ DropdownIndicator }}
        options={options}
        defaultValue={defaultOption}
        onChange={onChange}
        isSearchable={false}
        getOptionLabel={optionObj => t(`dictionaries:${optionObj.label}`)}
        {...props}
        className="select"
        classNamePrefix="select"
      />
    </InnerSelectContainer>
  </BorderSelectContainer>
);

SimpleSelect.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({})
  ]).isRequired,
  defaultOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  t: tType.isRequired
};

SimpleSelect.defaultProps = {
  style: {},
  defaultOption: null
};

export default withNamespaces()(SimpleSelect);
