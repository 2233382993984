import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { ErrorText } from '../styles';
import { metaType, tType } from '../types';

const FieldError = ({ meta, t, style }) => {
  const { touched, error } = meta;
  if (touched && error) {
    return <ErrorText style={style}>{t(`${error}`)}</ErrorText>;
  }
  return null;
};

FieldError.propTypes = {
  meta: metaType,
  t: tType.isRequired,
  style: PropTypes.shape({})
};

FieldError.defaultProps = {
  meta: null,
  style: null
};

export default withNamespaces('validation')(FieldError);
