import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { isAuthenticated, getRole } from 'redux/modules/auth/selectors';

export default function(ComposedComponent, requiredRole) {
  const Authentication = ({ authenticated, role, ...props }) => {
    if (authenticated && requiredRole.includes(role)) {
      return <ComposedComponent {...props} />;
    }
    return <Redirect to="/login" />;
  };

  Authentication.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
  };

  function mapStateToProps(state) {
    return {
      authenticated: isAuthenticated(state),
      role: getRole(state)
    };
  }

  return connect(mapStateToProps)(Authentication);
}
