import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { monthSettings } from 'utils/dictionaries';
import styled from 'styled-jss';
import RadioInline from 'shared/Fields/RadioInline';
import Loading from 'shared/components/Loading';
import {
  MainButton,
  FormTitle,
  ButtonGroup,
  SecondaryButton
} from 'shared/styles';
import ErrorMessageForm from 'shared/Fields/ErrorMessageForm';
import DottedBg from 'assets/Icon/icons/DottedBg';
import { LineDotHorizontal, LineDotVertical } from 'shared/components/LineDots';

const LineHorizontalTop = styled('div')({
  position: 'absolute',
  top: '-47px',
  right: '-76px'
});

const LineHorizontalBottom = styled('div')({
  position: 'absolute',
  top: '9px',
  right: '-76px'
});

const LineVerticalTop = styled('div')({
  position: 'absolute',
  top: '-138px',
  right: '-77px'
});

const BackgroundSquare = styled(DottedBg)({
  position: 'absolute',
  height: '200px',
  width: '200px',
  bottom: '-80px',
  left: 'calc(50% - 300px)',
  zIndex: '0'
});

const StyledForm = styled('form')({
  margin: '120px auto 0 auto',
  boxSizing: 'border-box',
  padding: '5px 10px',
  width: '330px',
  position: 'relative'
});

const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '2px',
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  padding: '9px 10px',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: '2'
});

const Label = styled('label')({
  display: 'block',
  fontSize: '16px',
  fontWeight: '300',
  paddingTop: '5px',
  marginBottom: '10px'
});

const SettingsInput = ({
  handleSubmit,
  submitting,
  serverErrorMessage,
  t,
  redirect
}) => {
  if (submitting) {
    return <Loading text={t('general:PleaseWait')} />;
  }
  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormContent style={{ height: '330px' }}>
        <FormTitle>{t('settings:Settings')}</FormTitle>
        <Label>{t('settings:DefaultMonthWorkTimes')}:</Label>
        <Field
          name="monthWorkTime"
          component={RadioInline}
          options={monthSettings}
          parse={value => Number(value)}
        />
        <ErrorMessageForm error={serverErrorMessage} />
      </FormContent>
      <FormContent
        style={{ marginTop: '10px', paddingBottom: '17px', paddingTop: '11px' }}
      >
        <React.Fragment>
          <LineHorizontalTop>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalTop>
          <LineVerticalTop>
            <LineDotVertical count={20} />
          </LineVerticalTop>
          <LineHorizontalBottom>
            <LineDotHorizontal count={4} width={75} />
          </LineHorizontalBottom>
          <ButtonGroup>
            <SecondaryButton
              style={{ marginRight: '20px' }}
              type="button"
              onClick={redirect}
            >
              {t('general:Back')}
            </SecondaryButton>
            <MainButton type="submit">{t('general:Save')}</MainButton>
          </ButtonGroup>
        </React.Fragment>
      </FormContent>
      <BackgroundSquare />
    </StyledForm>
  );
};

SettingsInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  serverErrorMessage: PropTypes.shape({})
};

SettingsInput.defaultProps = {
  submitting: true,
  serverErrorMessage: null
};

export default reduxForm({ form: 'settingsForm' })(
  withNamespaces()(SettingsInput)
);
