import * as types from './types';

export default function reducer(
  state = {
    worktimesUser: [],
    worktimesAdmin: {},
    worktime: {}
  },
  action
) {
  switch (action.type) {
    case types.GET_WORKER_TIMES_USER.SUCCESS:
      return {
        ...state,
        worktimesUser: action.payload
      };
    case types.GET_WORKER_TIMES_ADMIN.SUCCESS:
      return {
        ...state,
        worktimesAdmin: action.payload
      };
    case types.GET_WORKTIME.SUCCESS:
      return {
        ...state,
        worktime: action.payload
      };
    default:
      return state;
  }
}
