/* eslint-disable operator-linebreak */
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-jss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { tType, actionType, historyType } from 'shared/types';

import { getRole, getUserId } from 'redux/modules/auth/selectors';
import { getWorkers } from 'redux/modules/worker/selectors';
import { getWorkersNumberOfRows } from 'redux/modules/settings/selectors';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import {
  getWorkersAction,
  blockWorkerAction,
  deleteWorkerAction,
  unblockWorkerAction
} from 'redux/modules/worker/actions';

import { setSettingsAction } from 'redux/modules/settings/actions';

import Table from 'shared/components/Table';
import routes from 'config/routes';
import SimpleSelect from 'shared/components/SimpleSelect';
import { MainButton, SecondaryButton, H1, Container } from 'shared/styles';
import { currency, workerStatuses } from 'utils/dictionaries';
import { createParamsForFilterAndSort } from 'utils/helpers';
import SearchBar from './components/SearchBar';

const TopContainer = styled('div')({
  boxShadow: '-3px 3px 10px 0 rgba(0, 0, 0, 0.15)',
  backgroundColor: 'white',
  padding: '25px 45px 20px 25px',
  marginBottom: '20px'
});

const ButtonGroup = styled('div')({
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '17px'
});

const SearchContainer = styled('div')({
  display: 'flex',
  width: '100%'
});

class Workers extends PureComponent {
  state = {
    pageSize: 10,
    filtered: [{ id: 'search', value: '' }, { id: 'statuses', value: '1' }]
  };

  async componentDidMount() {
    await this.setState({
      pageSize: this.props.pageSize ? this.props.pageSize : 10
    });
  }

  fetchData = async state => {
    const { pageSize, sorted, filtered, page } = state;
    const params = createParamsForFilterAndSort(
      page + 1,
      pageSize,
      filtered,
      sorted
    );
    await this.props.getWorkers(params);
  };

  statusChange = async ({ value }) => {
    const oldFiltered = this.state.filtered;
    const newFiltered = oldFiltered.map(obj => {
      if (obj.id === 'statuses') {
        return {
          id: 'statuses',
          value
        };
      }
      return obj;
    });
    await this.setState({ filtered: newFiltered });
  };

  search = async ({ search }) => {
    const oldFiltered = this.state.filtered;
    const newFiltered = oldFiltered.map(obj => {
      if (obj.id === 'search') {
        return {
          id: 'search',
          value: search
        };
      }
      return obj;
    });
    await this.setState({ filtered: newFiltered });
  };

  onAddPersonClick = () => {
    this.props.history.push(routes.worker.child_route.add.path);
  };

  onAddGenerateReportClick = () => {
    this.props.history.push(routes.workers.child_route.report.path);
  };

  checkisBolded = row =>
    Object.keys(row.original).length > 0 &&
    typeof Object &&
    row.original.person.isPpk &&
    'bold';

  getTdProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        onClick: () =>
          this.props.history.push(`/worker/${rowInfo.row.idPerson}/profile`)
      };
    }
    return true;
  };

  getTrProps = (state, row) => {
    if (row) {
      return {
        style: {
          fontWeight:
            Object.keys(row.original).length > 0 &&
            typeof Object &&
            row.original.person.isPpk &&
            'bold'
        }
      };
    }
    return {};
  };

  pageSizeChanged = pageSize => {
    this.props.setSettings({
      numberOfRows: pageSize,
      idPerson: this.props.userId
    });
    this.setState({ pageSize });
  };

  render() {
    const columns = [
      {
        accessor: 'person.idPerson',
        id: 'idPerson',
        show: false
      },
      {
        Header: this.props.t('workers:FirstName'),
        width: 115,
        accessor: 'person.firstName',
        id: 'firstName'
      },
      {
        Header: this.props.t('workers:LastName'),
        width: 135,
        accessor: 'person.lastName',
        id: 'lastName'
      },
      {
        Header: this.props.t('workers:Email'),
        accessor: 'person.email',
        id: 'email'
      },
      {
        Header: this.props.t('workers:Hours'),
        accessor: 'lastStatus.timeValue',
        id: 'timeValue',
        width: 80,
        Cell: row => (row.value ? row.value : '')
      },
      {
        Header: this.props.t('workers:HourlyRate'),
        accessor: 'lastStatus.salaryPerHour',
        id: 'salaryPerHour',
        width: 103
      },
      {
        Header: this.props.t('workers:Salary'),
        width: 125,
        accessor: 'lastStatus.salaryPerHour',
        id: 'salary',
        Cell: row => (
          <div>
            <span
              title={
                Object.keys(row.original).length > 0 &&
                typeof Object &&
                row.original.person.isPpk &&
                this.props.t('workTimes:reduceByPpkWorkers')
              }
            >
              {row.value && `${row.value * row.original.lastStatus.timeValue}`}
            </span>
          </div>
        )
      },
      {
        Header: this.props.t('workers:Currency'),
        accessor: 'lastStatus.idCurrency',
        id: 'idCurrency',
        width: 75,
        Cell: row => {
          const cur = currency.find(c => c.value === row.value);
          return cur ? cur.label : '';
        }
      },
      {
        Header: this.props.t('workers:Date'),
        multiSort: false,
        width: 75,
        accessor: 'lastStatus',
        id: 'lastStatus',
        Cell: row => (row.value ? `${row.value.year}/${row.value.month}` : '')
      },
      {
        Header: this.props.t('workers:Status'),
        accessor: 'lastStatus.status.statusName',
        id: 'statusName',
        width: 220,
        Cell: row =>
          row.value ? this.props.t(`workTimeStatuses:${row.value}`) : ''
      }
    ];

    const { t, isFetching } = this.props;

    return (
      <Container>
        <H1>{t('workers:ListOfEmployees')}</H1>
        <TopContainer>
          <ButtonGroup>
            {this.props.role === 'Administrator' && (
              <SecondaryButton
                style={{ padding: '0 10px' }}
                onClick={this.onAddPersonClick}
              >
                {t('workers:AddPerson')}
              </SecondaryButton>
            )}
            <MainButton
              style={{ padding: '0 10px' }}
              onClick={this.onAddGenerateReportClick}
            >
              {t('workers:GenerateReport')}
            </MainButton>
          </ButtonGroup>
          <SearchContainer>
            <SimpleSelect
              style={{ width: '160px' }}
              defaultOption={workerStatuses.get(0)}
              onChange={this.statusChange}
              options={workerStatuses.toArray()}
              t={t}
            />
            <SearchBar onSubmit={this.search} />
          </SearchContainer>
        </TopContainer>
        <Table
          manual
          showPagination
          data={this.props.workers.list}
          multiSort={false}
          onFetchData={this.fetchData}
          defaultSorted={[{ id: 'lastName', desc: false }]}
          columns={columns}
          filtered={this.state.filtered}
          loading={isFetching}
          defaultPageSize={this.state.pageSize}
          pages={this.props.workers.pages}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          onPageSizeChange={(pageSize, pageIndex) =>
            this.pageSizeChanged(pageSize, pageIndex)
          }
        />
      </Container>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'worktime/worker/GET_WORKERS',
  'worktime/settings/GET_SETTINGS'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/worker/GET_WORKERS'
]);

Workers.propTypes = {
  isFetching: PropTypes.bool,
  role: PropTypes.string.isRequired,
  getWorkers: actionType.isRequired,
  workers: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    pages: PropTypes.number
  }).isRequired,
  t: tType.isRequired,
  history: historyType.isRequired,
  userId: PropTypes.string.isRequired,
  setSettings: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired
};

Workers.defaultProps = {
  isFetching: false
};

function mapStateToProps(state) {
  return {
    workers: getWorkers(state),
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    pageSize: getWorkersNumberOfRows(state),
    role: getRole(state),
    userId: getUserId(state)
  };
}

export default connect(
  mapStateToProps,
  {
    getWorkers: getWorkersAction,
    blockWorker: blockWorkerAction,
    deleteWorker: deleteWorkerAction,
    unblockWorker: unblockWorkerAction,
    setSettings: setSettingsAction
  }
)(withNamespaces()(Workers));
