/* eslint no-plusplus: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-jss';
import Circle from 'assets/Icon/icons/Circle';

const HorizontalCircle = styled(Circle)({
  marginRight: '8px'
});

const Separator = styled('div')({
  height: '14px',
  display: 'flex',
  alignItems: 'center'
});

export const LineDotHorizontal = ({ count, width }) => {
  const circles = [];
  for (let i = 0; i < count; i++) {
    circles.push(<HorizontalCircle key={i} />);
  }
  return <div style={{ width }}>{circles}</div>;
};

LineDotHorizontal.propTypes = {
  count: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export const LineDotVertical = ({ count }) => {
  const circles = [];
  for (let i = 0; i < count; i++) {
    circles.push(
      <Separator key={i + 20}>
        <Circle key={i} />
      </Separator>
    );
  }
  return <div style={{ width: '20px' }}>{circles}</div>;
};

LineDotVertical.propTypes = {
  count: PropTypes.number.isRequired
};
