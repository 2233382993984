import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { actionType, historyType, tType, i18nType } from 'shared/types';
import { signInAction } from 'redux/modules/auth/actions';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';
import { getRole, getLanguage } from 'redux/modules/auth/selectors';
import { languages } from 'utils/dictionaries';

import Loading from 'shared/components/Loading';
import sessionHandler from 'utils/sessionHandler';
import LoginInput from './components/LoginInput';

class Login extends PureComponent {
  handleSubmit = async values => {
    await this.props.signIn(values);
    if (!this.props.error) {
      sessionHandler.run();
      const lngObj = languages.find(obj => obj.value === this.props.language);
      this.props.i18n.changeLanguage(lngObj.shortName);
      this.props.history.push(
        this.props.role === 'Administrator' ? '/workers' : '/work-times'
      );
    }
  };

  render() {
    const { error, t, isFetching } = this.props;
    if (isFetching) {
      return <Loading text={t('general:PleaseWait')} />;
    }

    return (
      <LoginInput
        onSubmit={this.handleSubmit}
        errorMessage={error && error.errorMessage}
      />
    );
  }
}

Login.propTypes = {
  signIn: actionType.isRequired,
  history: historyType.isRequired,
  t: tType.isRequired,
  i18n: i18nType.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.shape({}),
  role: PropTypes.string,
  language: PropTypes.string
};

Login.defaultProps = {
  isFetching: false,
  error: null,
  role: null,
  language: null
};

const loadingSelector = createLoadingSelector(['worktime/auth/LOGIN']);

const errorSelector = createErrorMessageSelector(['worktime/auth/LOGIN']);

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    isFetching: loadingSelector(state),
    role: getRole(state),
    error: errorSelector(state),
    language: getLanguage(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      signIn: signInAction
    }
  )(Login)
);
