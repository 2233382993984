import storage from 'redux/utis/storageFactory';
import jwtDecode from 'jwt-decode';

export default (() => {
  const sessionTime = 15 * 60 * 1000;
  let deadline;
  let intervalId;

  const setDeadline = () => {
    const token = storage.getItem('token');
    if (!token) return;
    const decodedToken = jwtDecode(token);
    deadline = decodedToken.exp * 1000 + sessionTime;
  };

  const stop = () => {
    clearInterval(intervalId);
  };

  return {
    run: () => {
      stop(intervalId);
      setDeadline();
      if (!deadline) return;
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        const distance = deadline - now;
        // console.log('seconds left :', distance / 1000);
        // console.log('minutes left :', distance / (1000 * 60));
        if (distance < 0) {
          window.location.replace(`${window.location.origin}/session-expired`);
          storage.removeItem('token');
          storage.removeItem('email');
          storage.removeItem('role');
          storage.removeItem('userId');
          clearInterval(intervalId);
        }
      }, 1000);
    }
  };
})();
