import React from 'react';
import styled from 'styled-jss';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { toWarsawTz, extractMonthYear } from 'utils/helpers';

const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  height: '50px',
  backgroundColor: '#c3e8ff',
  padding: ' 0 15px',
  border: ' 2px solid rgba(0, 0, 0, 0.67)',
  marginBottom: '5px'
});

const HeaderDate = styled('div')({
  boxSizing: 'border-box',
  display: 'inline-block',
  fontsize: '18px',
  fontWeight: '500',
  margin: '10px 0 0 0',
  verticalAlign: 'top'
});

const HeaderAuthor = styled('div')({
  display: 'inline-block',
  paddingTop: 0
});
const AuthorContainer = styled('div')({
  textAlign: 'center',
  height: '23px'
});
const AuthorLabel = styled('div')({
  display: 'inline-block',
  fontWeight: 30,
  fontSize: '12px'
});
const AuthorValue = styled('div')({
  display: 'inline-block',
  fontWeight: 300,
  fontSize: '12px'
});

const HeaderClass = ({ data, t }) => (
  <Header>
    <HeaderDate>
      {`${t('pdf:DateRange')} ${extractMonthYear(
        data.dateFrom
      )} - ${extractMonthYear(data.dateTo)}`}
    </HeaderDate>
    <HeaderAuthor>
      <AuthorContainer>
        <AuthorLabel>{t('pdf:GeneratedBy')}:</AuthorLabel>
        <AuthorValue>{data.generatedBy}</AuthorValue>
      </AuthorContainer>
      <AuthorContainer>
        <AuthorLabel>{t('pdf:GeneratedAt')}:</AuthorLabel>
        <AuthorValue>{toWarsawTz(data.generatedAt)}</AuthorValue>
      </AuthorContainer>
    </HeaderAuthor>
  </Header>
);

HeaderClass.propTypes = {
  data: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

export default withNamespaces()(HeaderClass);
