import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getReport,
  getMonths,
  getUsers,
  getColumns
} from 'redux/modules/report/selectors';
import {
  getReportCsvAction,
  getReportPdfAction,
  getReportMonthsAction,
  getReportUsersAction,
  getReportColumnsAction
} from 'redux/modules/report/actions';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';

import Loading from 'shared/components/Loading';
import { actionType, errorMessageType, historyType, tType } from 'shared/types';
import { Container, CenteredContainerVertical } from 'shared/styles';
import { toIsoDateTime } from 'utils/helpers';
import ReportInput from './ReportInput';

class Report extends PureComponent {
  state = {
    reportType: '1'
  };

  async componentDidMount() {
    await this.props.getReportMonths();
    await this.props.getReportUsers();
    await this.props.getReportColumns();
  }

  onClickBackButton = () => {
    this.props.history.push('/workers');
  };

  reportTypeChanged = ({ target: { value } }) => {
    this.setState({ reportType: value });
  };

  handleSubmit = async values => {
    if (values.reportMimeType === '1') {
      await this.props.getReportCsv({
        ...values,
        from:
          this.state.reportType === '1'
            ? values.from
            : toIsoDateTime(values.from),
        to:
          this.state.reportType === '1' ? values.to : toIsoDateTime(values.to),
        reportType: +this.state.reportType
      });
    }
    if (values.reportMimeType === '2') {
      await this.props.getReportPdf({
        ...values,
        from:
          this.state.reportType === '1'
            ? values.from
            : toIsoDateTime(values.from),
        to:
          this.state.reportType === '1' ? values.to : toIsoDateTime(values.to),
        reportType: +this.state.reportType
      });
    }
  };

  render() {
    const { t, isFetching, error, users, columns, months } = this.props;
    if (isFetching) {
      return <Loading />;
    }

    return (
      <Container>
        <CenteredContainerVertical>
          <ReportInput
            title={t('report:GenerateReport')}
            onSubmit={this.handleSubmit}
            reportType={this.state.reportType}
            reportTypeChanged={this.reportTypeChanged}
            users={users}
            initialValues={{ reportMimeType: '1' }}
            columns={columns}
            serverErrorMessage={error && error.message}
            months={months}
            enableReinitialize
            backButton={this.onClickBackButton}
          />
        </CenteredContainerVertical>
      </Container>
    );
  }
}

Report.propTypes = {
  getReportCsv: actionType.isRequired,
  getReportPdf: actionType.isRequired,
  getReportMonths: actionType.isRequired,
  getReportUsers: actionType.isRequired,
  getReportColumns: actionType.isRequired,
  history: historyType.isRequired,
  months: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  error: errorMessageType,
  t: tType.isRequired,
  isFetching: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

Report.defaultProps = {
  error: null
};

const loadingSelector = createLoadingSelector([
  'worktime/report/GET_REPORT_MONTHS',
  'worktime/report/GET_REPORT_USERS',
  'worktime/report/GET_REPORT_COLUMNS',
  'worktime/report/GET_REPORT'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/report/GET_REPORT_MONTHS',
  'worktime/report/GET_REPORT',
  'worktime/report/GET_REPORT_USERS',
  'worktime/report/GET_REPORT_COLUMNS'
]);

function mapStateToProps(state) {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    report: getReport(state),
    months: getMonths(state),
    users: getUsers(state),
    columns: getColumns(state)
  };
}

export default connect(
  mapStateToProps,
  {
    getReportCsv: getReportCsvAction,
    getReportMonths: getReportMonthsAction,
    getReportUsers: getReportUsersAction,
    getReportColumns: getReportColumnsAction,
    getReportPdf: getReportPdfAction
  }
)(translate('translations')(Report));
