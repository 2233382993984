import axios from './index';

export async function changePassword(data) {
  return axios.put('api/account/ChangePassword', data);
}

export async function setPassword(data) {
  return axios.post('api/account/register', data);
}

export async function getPerson({ id, uid }) {
  return axios.get(`api/persons/${id}/uid/${uid}`);
}

export async function sendPasswordMail(email) {
  return axios.post('api/account/SendChangePassword', email);
}

export async function login(data) {
  return axios.post('api/account/login', data);
}

export async function checkPasswordGuid(uid) {
  return axios.get(`api/account/CheckPasswordKeyExpiration?guid=${uid}`);
}
