import React, { PureComponent } from 'react';
import styled from 'styled-jss';
import PropTypes from 'prop-types';
import { Map, Seq } from 'immutable';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { signOutAction } from 'redux/modules/auth/actions';
import {
  getRole,
  isAuthenticated,
  getFullname
} from 'redux/modules/auth/selectors';

import Icon from 'assets/Icon/icons/User';
import routes from 'config/routes';
import { actionType, historyType, tType } from 'shared/types';

const List = styled('ul')({
  fontSize: '18px',
  webkitBoxSizing: 'border-box',
  boxSizing: 'border-box',
  borderRadius: '5px',
  listStyle: 'none',
  lineHeight: '1.3',
  padding: '0',
  width: '150px',
  backgroundColor: ({ theme }) => theme.background,
  position: 'absolute',
  display: 'block',
  boxShadow: ({ theme }) => theme.navbar.avatarDropdownShadow,
  color: 'black',
  top: '20px',
  right: '0',
  zIndex: '20'
});

const StyledIcon = styled(Icon)({
  fill: ({ theme }) => theme.navbar.iconColor,
  height: '24px',
  width: '24px',
  '&:hover': {
    fill: ({ theme }) => theme.hover
  }
});

const ListItem = styled('li')({
  padding: 10,
  margin: 0,
  height: '100%',
  listStyleType: 'none',
  borderRadius: '4px',
  /* borderTop: ({ theme }) => theme.navbar.avatarDropdownItemBorder, */
  '&:hover': {
    backgroundColor: ({ theme }) => theme.hover
  },
  '&:first-child': {
    border: 'none'
  },
  zIndex: '30'
});

const LogoutItem = styled(ListItem)({
  cursor: 'pointer'
});

const IconWrapper = styled('a')({
  border: '2px solid',
  borderColor: ({ theme }) => theme.primaryColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  outline: 'none',
  textDecoration: 'none',
  height: '24px',
  width: '24px',
  padding: '0',
  '&:hover:not(:active)': {
    background: ({ theme }) => theme.paleSkyBlue
  },
  '&:active': {
    borderColor: ({ theme }) => theme.textDefault,
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  },
  '&:focus': {
    outline: '1px solid',
    outlineColor: ({ theme }) => theme.lightBlue30
  }
});

const Container = styled('div')({
  position: 'relative'
});

class AvatarDropdown extends PureComponent {
  state = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  onIconClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpen: false
      });
    }
  };

  render() {
    const { role, t, signOut, fullname, history } = this.props;
    const { isOpen } = this.state;
    const dropdownMenu = Map({
      Administrator: Seq([
        {
          key: 'navbar:Settings',
          name: t('navbar:Settings'),
          action: () => {
            history.push(routes.settings.path);
            this.setState({
              isOpen: false
            });
          }
        },
        {
          key: 'navbar:Account',
          name: t('navbar:Account'),
          action: () => {
            history.push('/settings/change-password');
          }
        },
        {
          key: 'navbar:Logout',
          name: t('navbar:Logout'),
          action: signOut
        }
      ]),
      Rachunkowosc: Seq([
        {
          key: 'navbar:Logout',
          name: t('navbar:Logout'),
          action: signOut
        }
      ]),
      Pracownik: Seq([
        {
          key: 'name',
          name: `${fullname}`
        },
        {
          key: 'navbar:Account',
          name: t('navbar:Account'),
          action: () => {
            history.push('/settings/change-password');
          }
        },
        {
          key: 'navbar:Logout',
          name: t('navbar:Logout'),
          action: signOut
        }
      ])
    });

    return (
      <div ref={this.setWrapperRef}>
        <Container>
          <IconWrapper onClick={this.onIconClick}>
            <StyledIcon />
          </IconWrapper>
          {isOpen && (
            <List>
              {dropdownMenu.get(role).map(obj => (
                <LogoutItem key={obj.key} onClick={obj.action}>
                  {obj.name}
                </LogoutItem>
              ))}
            </List>
          )}
        </Container>
      </div>
    );
  }
}

AvatarDropdown.propTypes = {
  t: tType.isRequired,
  role: PropTypes.string,
  history: historyType.isRequired,
  signOut: actionType.isRequired,
  fullname: PropTypes.string.isRequired
};

AvatarDropdown.defaultProps = {
  role: null
};

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
    role: getRole(state),
    fullname: getFullname(state)
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    {
      signOut: signOutAction
    }
  )(AvatarDropdown)
);
