import * as types from './types';

export default function reducer(
  state = {
    person: {},
    info: ''
  },
  action
) {
  switch (action.type) {
    case types.GET_PERSON.SUCCESS:
      return {
        ...state,
        person: action.payload
      };
    case types.SEND_MAIL.SUCCESS:
      return {
        ...state,
        info: action.payload
      };
    default:
      return state;
  }
}
