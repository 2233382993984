import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export default function CheckboxWithLabel({ label, input }) {
  const [state, setState] = useState(false);

  const handleChange = event => {
    input.onChange(event.target.checked);
    setState(event.target.checked);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <Checkbox
        style={{
          color: 'rgb(195, 232, 255)'
        }}
        checked={input.value || state}
        onChange={handleChange}
        name="checkbox"
      />
      {label}
    </label>
  );
}

CheckboxWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.any.isRequired
};
