import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-jss';

import { Container, SecondaryButton, H1, H3, MainButton } from 'shared/styles';
import Loading from 'shared/components/Loading';
import Error from 'shared/components/Error';

import {
  changePasswordAction,
  changePasswordRequestAction
} from 'redux/modules/settings/actions';
import { getRole } from 'redux/modules/auth/selectors';
import {
  createLoadingSelector,
  createErrorMessageSelector
} from 'redux/modules/selectors';
import { addToastAction } from 'redux/modules/toast/actions';

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center'
});

const MainCotainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center'
});

class ChangePassword extends React.Component {
  componentDidMount() {
    this.props.changePasswordRequestAction();
  }

  handleSubmit = async () => {
    await this.props.changePasswordAction();
    if (!this.props.error) {
      this.props.addToastAction('MailNotification');
    }
  };

  handleRedirect = () => {
    this.props.history.push(
      this.props.role === 'Pracownik' ? '/work-times' : '/workers'
    );
  };

  render() {
    if (this.props.isFetching) {
      return <Loading />;
    }

    if (this.props.error) {
      return <Error error={this.props.error} />;
    }

    return (
      <Container>
        <HeaderContainer>
          <SecondaryButton type="button" onClick={this.handleRedirect}>
            {this.props.t('settings:Back')}
          </SecondaryButton>
          <H1> {this.props.t('settings:AccountSettings')}</H1>
          <div />
        </HeaderContainer>
        <MainCotainer>
          <H3>{`${this.props.t('settings:ClickToChangePw')}:`}</H3>
          <MainButton
            style={{ marginLeft: '10px' }}
            type="button"
            onClick={this.handleSubmit}
          >
            {this.props.t('settings:ResetPassword')}
          </MainButton>
        </MainCotainer>
      </Container>
    );
  }
}

ChangePassword.propTypes = {
  changePasswordAction: PropTypes.func.isRequired,
  changePasswordRequestAction: PropTypes.func.isRequired,
  addToastAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  error: PropTypes.shape({}),
  isFetching: PropTypes.bool.isRequired
};

ChangePassword.defaultProps = {
  error: null
};

const loadingSelector = createLoadingSelector([
  'worktime/settings/CHANGE_PASSWORD'
]);

const errorSelector = createErrorMessageSelector([
  'worktime/settings/CHANGE_PASSWORD'
]);

const mapStateToProps = state => ({
  role: getRole(state),
  error: errorSelector(state),
  isFetching: loadingSelector(state)
});

export default connect(
  mapStateToProps,
  {
    changePasswordAction,
    changePasswordRequestAction,
    addToastAction
  }
)(withNamespaces()(ChangePassword));
