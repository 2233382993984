import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled from 'styled-jss';
import { withNamespaces } from 'react-i18next';
import { SecondaryButton, MainButton } from 'shared/styles';
import DownArrow from 'assets/Icon/icons/ArrowDown';
import UpArrow from 'assets/Icon/icons/ArrowUp';
import AcceptIcon from 'assets/Icon/icons/IconMCheckboxFilled';
import EmptyIcon from 'assets/Icon/icons/IconMCheckboxEmpty';
import DeleteIcon from 'assets/Icon/icons/Delete';
import FieldError from './FieldError';

const StyledAcceptIcon = styled(AcceptIcon)({
  '& rect': {
    fill: 'black'
  }
});

const SelectAllButton = styled(SecondaryButton)({
  height: '30px',
  lineHeight: '30px',
  position: 'absolute',
  right: '-165px',
  top: '0',
  width: '150px',
  padding: '0',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center'
});

const RemoveAllButton = styled(MainButton)({
  height: '30px',
  lineHeight: '30px',
  position: 'absolute',
  right: '-165px',
  top: '0',
  width: '150px',
  padding: '0',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center'
});

const InputLabel = styled('label')({
  width: '100%',
  display: 'block',
  color: ({ theme }) => theme.textDefault,
  fontWeight: '300',
  height: '24px',
  fontSize: '24px',
  lineHeight: '24px',
  paddingLeft: '5px'
});

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <DeleteIcon />
  </components.MultiValueRemove>
);

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen && (
        <div>
          <UpArrow />
          <UpArrow />
        </div>
      )}
      {!props.selectProps.menuIsOpen && (
        <div>
          <DownArrow />
          <DownArrow />
        </div>
      )}
    </components.DropdownIndicator>
  );

const Menu = ({ children, selectProps, ...props }) => (
  <components.Menu {...props}>{children}</components.Menu>
);

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({}).isRequired
};

const Option = props => {
  if (props.isSelected) {
    return (
      <OptionContainer>
        <AcceptCheckbox checked={props.isSelected} />
        <components.Option {...props} />
      </OptionContainer>
    );
  }
  return (
    <OptionContainer>
      <AcceptCheckbox checked={props.isSelected} />
      <components.Option {...props} />
    </OptionContainer>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired
};

const AcceptCheckbox = ({ checked }) => (
  <IconContainer>
    {checked && <StyledAcceptIcon />}
    {!checked && <EmptyIcon />}
  </IconContainer>
);

const IconContainer = styled('div')({
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: '2',
  padding: '0',
  height: '15px',
  width: '15px',
  left: '6px',
  top: '2px',
  color: ({ theme }) => theme.primaryColor,
  '& g': {
    stroke: ({ theme }) => theme.primaryColor,
    '& rect': {
      fill: 'none',
      stroke: ({ theme }) => theme.primaryColor
    },
    '& path': {
      stroke: ({ theme }) => theme.primaryColor,
      fill: ({ theme }) => theme.primaryColor
    }
  }
});

const OptionContainer = styled('div')({
  position: 'relative',
  '&:hover path': {
    fill: 'black'
  }
});

const SelectContainer = styled('div')({
  fontFamily: 'roboto !important',
  maxWidth: '350px',
  minWidth: '350px',
  fontSize: '16px',
  position: 'relative',
  fill: ({ theme }) => theme.primaryColor,
  '& .select': {
    minHeight: '28px'
  },
  '& .select__multi-value__label': {
    fontSize: '16px',
    color: ({ theme }) => theme.textDefault,
    alignSelf: 'center',
    paddingLeft: '3px'
  },
  '& .select__value-container': {
    minHeight: '28px',
    boxSizing: 'border-box',
    padding: '0 3px',
    maxHeight: '150px',
    overflowY: 'auto'
  },
  '& .select__placeholder': {
    color: ({ theme }) => theme.placeholder,
    marginLeft: '2px'
  },
  '& .select--is-disabled': {
    cursor: 'not-allowed',
    '& svg': {
      color: ({ theme }) => theme.disabledTextInputBorder,
      fill: ({ theme }) => theme.disabledTextInputBorder
    },
    '& .select__control.select__control--is-disabled': {
      cursor: 'not-allowed',
      borderColor: ({ theme }) => theme.disabledTextInputBorder,
      '& .select__value-container': {
        cursor: 'not-allowed'
      },
      '& .select__placeholder': {
        color: ({ theme }) => theme.disabledTextInput,
        cursor: 'not-allowed'
      }
    }
  },
  '& .select__option.select__option--is-selected': {
    background: 'white'
  },
  '& .select__control': {
    minHeight: '28px',
    boxSizing: 'border-box',
    borderRadius: '0',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: ({ theme }) => theme.paleSkyBlue,
    color: ({ theme }) => theme.textDefault,
    outline: '0 !important',
    boxShadow: 'none',
    minWidth: '220px',
    width: '100%',
    maxWidth: '400px'
  },
  '& .select__dropdown-indicator': {
    position: 'relative',
    height: '28px',
    boxSizing: 'border-box',
    top: '-5px'
  },
  '& .select__dropdown-indicator svg:last-of-type': {
    position: 'absolute',
    left: '8px',
    visibility: 'hidden',
    fill: ({ theme }) => theme.hover
  },
  '& .select__control:hover svg:first-of-type': {
    visibility: 'hidden'
  },
  '& .select__control:active .select__dropdown-indicator svg:first-of-type': {
    visibility: 'visible !important'
  },
  '& .select__control:hover .select__dropdown-indicator svg:last-of-type': {
    visibility: 'visible',
    fill: 'black'
  },
  '& .select__control:active .select__dropdown-indicator svg:last-of-type': {
    visibility: 'hidden !important'
  },
  '& .select__control:hover': {
    borderColor: ({ theme }) => theme.hover,
    outline: '0',
    backgroundColor: ({ theme }) => theme.primaryColor
  },
  '& .select__control:hover .select__multi-value__remove svg': {
    visibility: 'visible',
    fill: ({ theme }) => theme.textDefault
  },
  '& .select__control:active': {
    borderColor: 'transparent',
    outline: '0'
  },
  '& .select__control:focus': {
    border: '1px solid',
    outline: '0'
  },
  '& .select__indicator-separator': {
    display: 'none'
  },
  '& .select__clear-indicator': {
    display: 'none'
  },
  '& .select__option': {
    color: ({ theme }) => theme.textDefault,
    marginLeft: '3px',
    height: '23px',
    lineHeight: '23px',
    padding: '0 12px 0 28px',
    cursor: 'pointer',
    margin: '0 auto 2px auto',
    width: '98%',
    boxSizing: 'border-box',
    borderRadius: '0',
    border: '1px solid transparent',
    backgroundColor: ({ theme }) => theme.calendarInnerBackground,
    zIndex: '1'
  },
  '& .select__control--is-focused': {
    borderColor: ({ theme }) => theme.primaryColor,
    borderRadius: '0',
    boxShadow: 'inset 0 1px 4px 0',
    color: ({ theme }) => theme.shadow,
    backgroundColor: ({ theme }) => theme.inputActive
  },
  '& .select__option:hover': {
    backgroundColor: ({ theme }) => theme.hover,
    border: '1px solid',
    borderColor: 'transparent'
  },
  '& .select__option:active': {
    backgroundColor: 'transparent !important',
    border: '1px solid',
    borderColor: ({ theme }) => theme.hover,
    boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.25)'
  },
  '& .select__option--is-focused': {
    border: '1px dotted',
    borderColor: ({ theme }) => theme.focus
  },
  '& .select__multi-value': {
    background: 'transparent',
    height: '25px',
    color: ({ theme }) => theme.textDefault
  },
  '& .select__multi-value__remove': {
    background: 'transparent',
    color: ({ theme }) => theme.textDefault,
    '&:hover': {
      background: 'transparent',
      '& svg path': {
        fill: ({ theme }) => theme.purplishRed
      }
    }
  },
  '& .select__group': {
    padding: '0 0 0 26px'
  },
  '& .select__input': {
    color: ({ theme }) => theme.textDefault
  },
  '& .select__group-heading': {
    color: ({ theme }) => theme.textDefault,
    textTransform: 'none',
    fontSize: '16px',
    height: '23px',
    lineHeight: '23px',
    fontFamily: 'HKGrotesk',
    fontWeight: 'normal',
    paddingLeft: '8px',
    margin: '0 0 0 -18px'
  },
  '& .select__menu': {
    margin: '0',
    zIndex: '99',
    width: '100%',
    maxWidth: '400px',
    minWidth: '220px',
    borderRadius: '0',
    maxHeight: '250px',
    backgroundColor: ({ theme }) => theme.background
  },
  '& .select__menu-list': {
    maxHeight: '250px'
  },
  '&:disabled svg': {
    fill: ({ theme }) => theme.focus
  }
});

class ReactMultiSelect extends Component {
  state = {
    options: [],
    selected: []
  };

  componentDidMount() {
    this.setState({ options: this.props.options });
  }

  onChange = value => {
    this.props.input.onChange(value);

    this.setState({
      selected: [...value]
    });
  };

  selectAll = () => {
    this.props.input.onChange(this.props.options);
    this.setState({ selected: this.props.options });
  };

  unSelectAll = () => {
    this.props.input.onChange([]);
    this.setState({ selected: [] });
  };

  render() {
    const { input, label, placeholder, meta, style, t } = this.props;
    return (
      <SelectContainer style={style}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          components={{ Menu, DropdownIndicator, Option, MultiValueRemove }}
          value={this.state.selected}
          closeMenuOnSelect={false}
          onChange={this.onChange}
          onBlur={() => input.onBlur(input.value)}
          getOptionLabel={option => t(option.label)}
          formatGroupLabel={group => <div>{group.label}</div>}
          options={this.state.options}
          tabSelectsValue={false}
          placeholder={placeholder}
          hideSelectedOptions={false}
          id={input.name}
          isClearable={false}
          isMulti
          className="select"
          classNamePrefix="select"
        />
        <RenderButtons
          state={this.state}
          selectAll={this.selectAll}
          unSelectAll={this.unSelectAll}
          t={t}
        />
        <FieldError meta={meta} />
      </SelectContainer>
    );
  }
}

const RenderButtons = ({
  state: { selected, options },
  selectAll,
  unSelectAll,
  t
}) => {
  if (selected === options) {
    return (
      <RemoveAllButton type="button" onClick={unSelectAll}>
        {t('RemoveAll')}
      </RemoveAllButton>
    );
  }
  return (
    <SelectAllButton type="button" onClick={selectAll}>
      {t('SelectAll')}
    </SelectAllButton>
  );
};

RenderButtons.propTypes = {
  unSelectAll: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  state: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

AcceptCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired
};

ReactMultiSelect.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired
  }).isRequired,
  style: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

ReactMultiSelect.defaultProps = {
  label: null,
  style: null,
  placeholder: null
};

export default withNamespaces('dictionaries')(ReactMultiSelect);
